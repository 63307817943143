export enum BuildingType {
  ATTACHED_HOUSE = 'ATTACHED_HOUSE',
  FAMILY_HOUSE = 'FAMILY_HOUSE',
  SECONDARY_RESIDENCE = 'SECONDARY_RESIDENCE',
  APPARTMENT_COMPLEX = 'APPARTMENT_COMPLEX',
  UNDERGROUND_GARAGE = 'UNDERGROUND_GARAGE',
  OTHER = 'OTHER',
}

export const getBuildingTypeLabel = (value: BuildingType): string => {
  switch (value) {
    case BuildingType.ATTACHED_HOUSE:
      return $localize`Maison mitoyenne`;
    case BuildingType.FAMILY_HOUSE:
      return $localize`Maison à une famille`;
    case BuildingType.SECONDARY_RESIDENCE:
      return $localize`Maison secondaire`;
    case BuildingType.APPARTMENT_COMPLEX:
      return $localize`Immeuble`;
    case BuildingType.UNDERGROUND_GARAGE:
      return $localize`Parking souterrain`;
    case BuildingType.OTHER:
      return $localize`Autre`;
    default:
      return '';
  }
};

export enum SecurityMeasure {
  ALARM = 'ALARM',
  NONE = 'NONE',
}

export const getSecurityMeasureLabel = (value: SecurityMeasure): string => {
  switch (value) {
    case SecurityMeasure.ALARM:
      return $localize`Avec alarme incendie`;
    case SecurityMeasure.NONE:
      return $localize`Sans alarme incendie`;
    default:
      return '';
  }
};

export enum HeatingSystem {
  FLOOR_HEATING = 'FLOOR_HEATING',
  ROOF_HEATING = 'ROOF_HEATING',
  FLOOR_ROOF_HEATING = 'FLOOR_ROOF_HEATING',
  OTHER = 'OTHER',
}

export const getHeatingSystemLabel = (value: HeatingSystem): string => {
  switch (value) {
    case HeatingSystem.FLOOR_HEATING:
      return $localize`Chauffage au sol`;
    case HeatingSystem.ROOF_HEATING:
      return $localize`Chauffage au plafond`;
    case HeatingSystem.FLOOR_ROOF_HEATING:
      return $localize`Chauffage au sol et au plafond`;
    case HeatingSystem.OTHER:
      return $localize`Autres`;
    default:
      return '';
  }
};

export enum ConstructionType {
  MASSIVE = 'MASSIVE',
  NOT_MASSIVE = 'NOT_MASSIVE',
}

export const getConstructionTypeLabel = (value: ConstructionType): string => {
  switch (value) {
    case ConstructionType.MASSIVE:
      return $localize`Massif`;
    case ConstructionType.NOT_MASSIVE:
      return $localize`Non massif`;
    default:
      return '';
  }
};

export enum RoofType {
  FLAT = 'FLAT',
  NOT_FLAT = 'NOT_FLAT',
}

export const getRoofTypeLabel = (value: RoofType): string => {
  switch (value) {
    case RoofType.FLAT:
      return $localize`Avec toit plat`;
    case RoofType.NOT_FLAT:
      return $localize`Sans toit plat`;
    default:
      return '';
  }
};

export enum CoverageType {
  FIRE_OR_DISASTERS = 'FIRE_OR_DISASTERS',
  THEFT = 'THEFT',
  WATER_DAMAGE = 'WATER_DAMAGE',
  GLASS_BREAKAGE = 'GLASS_BREAKAGE',
  EARTHQUAKE = 'EARTHQUAKE',
  TECHNICAL_SETUPS = 'TECHNICAL_SETUPS',
  EXTERIOR = 'EXTERIOR',
}

export const getCoverageTypeLabel = (type: CoverageType): string => {
  switch (type) {
    case CoverageType.FIRE_OR_DISASTERS:
      return $localize`Incendie`;
    case CoverageType.THEFT:
      return $localize`Vol`;
    case CoverageType.WATER_DAMAGE:
      return $localize`Dégâts d'eau`;
    case CoverageType.GLASS_BREAKAGE:
      return $localize`Brise de glace`;
    case CoverageType.EARTHQUAKE:
      return $localize`Tremblement de terre`;
    case CoverageType.TECHNICAL_SETUPS:
      return $localize`Installations techniques`;
    case CoverageType.EXTERIOR:
      return $localize`Extérieur`;
    default:
      return '';
  }
};
