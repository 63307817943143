import { ContractToSignInfo } from 'src/app/_services/types/contract-template.types';
import {
  ConstructionType,
  getConstructionTypeEnumLabel,
} from 'src/app/enums/construction/construction-type.enum';
import { EntityType, getEntityTypeLabel } from 'src/app/enums/construction/entity-type.enum';
import {
  getProjectOwnerTypeLabel,
  ProjectOwnerType,
} from 'src/app/enums/construction/project-owner-type.enum';
import { ContractType } from 'src/app/enums/contract-type.enum';
import { InsuranceTypeEnum } from 'src/app/enums/insurance-type.enum';

import { SelectElementMapper } from '../../_services/types/commons.type';

export const CONSTRUCTION_INSURANCE_BASE_URL = 'construction';
export const CONSTRUCTION_INSURANCE_INPUT = 'formulaire';
export const CONSTRUCTION_INSURANCE_OFFER = 'offre';
export const CONSTRUCTION_INSURANCE_END = 'fin';
export const CONSTRUCTION_INSURANCE_END_URL = `${CONSTRUCTION_INSURANCE_BASE_URL}/fin`;

export const CONSTRUCTION_INPUT_FORM_STEPS = {
  BASE: `${CONSTRUCTION_INSURANCE_BASE_URL}/${CONSTRUCTION_INSURANCE_INPUT}/`,
  ENTITY_TYPE: { step: [1, 1], url: 'type' },
  CONSTRUCTION_TYPE: { step: [2, 1], url: 'construction' },
  CONSTRUCTION_ADDRESS: { step: [2, 1], url: 'adresse' },
  CONSTRUCTION_WORK: { step: [3, 1], url: 'travaux' },
  ARCHITECT: { step: [4, 1], url: 'architecte' },
  CIVIL_ENGINEER: { step: [4, 1], url: 'ingenieur-civil' },
  PROJECT_OWNER: { step: [4, 1], url: 'maitre-ouvrage' },
  INSURANCES: { step: [5, 1], url: 'assurance' },
  RISKS: { step: [5, 1], url: 'risques' },
  OFFERS: { step: [6, 1], url: 'offres' },
};

export const CONSTRUCTION_OFFER_FORM_STEPS = {
  BASE: `${CONSTRUCTION_INSURANCE_BASE_URL}/${CONSTRUCTION_INSURANCE_OFFER}/`,
  ACCOUNT: { step: [1, 1], url: 'compte' },
  LOGIN: { step: [1, 1], url: 'connexion' },
  REGISTER: { step: [1, 1], url: 'inscription' },
  PERSONAL_INFO: { step: [2, 1], url: 'informations-personnelles' },
  CONTRACT_SIGNATURE: { step: [2, 1], url: 'signature' },
  CANCELLATION: { step: [2, 1], url: 'resiliation' },
};

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 construction',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.CONSTRUCTION,
  },
  {
    name: 'Mandat gestion construction',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.CONSTRUCTION,
  },
];

export const ENTITY_TYPES: SelectElementMapper<EntityType> = {
  [EntityType.OWNER]: {
    value: EntityType.OWNER,
    label: getEntityTypeLabel(EntityType.OWNER),
  },
  [EntityType.ARCHITECT]: {
    value: EntityType.ARCHITECT,
    label: getEntityTypeLabel(EntityType.ARCHITECT),
  },
  [EntityType.ENTERPRISE]: {
    value: EntityType.ENTERPRISE,
    label: getEntityTypeLabel(EntityType.ENTERPRISE),
  },
};

export const CONSTRUCTION_TYPES: SelectElementMapper<ConstructionType> = {
  [ConstructionType.NEW]: {
    value: ConstructionType.NEW,
    label: getConstructionTypeEnumLabel(ConstructionType.NEW),
  },
  [ConstructionType.EXTENSION]: {
    value: ConstructionType.EXTENSION,
    label: getConstructionTypeEnumLabel(ConstructionType.EXTENSION),
  },
  [ConstructionType.TRANSFORMATION]: {
    value: ConstructionType.TRANSFORMATION,
    label: getConstructionTypeEnumLabel(ConstructionType.TRANSFORMATION),
  },
  [ConstructionType.RENOVATION]: {
    value: ConstructionType.RENOVATION,
    label: getConstructionTypeEnumLabel(ConstructionType.RENOVATION),
  },
  [ConstructionType.OTHER]: {
    value: ConstructionType.OTHER,
    label: getConstructionTypeEnumLabel(ConstructionType.OTHER),
  },
};

export const PROJECT_OWNER_TYPES: SelectElementMapper<ProjectOwnerType> = {
  [ProjectOwnerType.OWNER]: {
    value: ProjectOwnerType.OWNER,
    label: getProjectOwnerTypeLabel(ProjectOwnerType.OWNER),
  },
  [ProjectOwnerType.BENEFICIARY]: {
    value: ProjectOwnerType.BENEFICIARY,
    label: getProjectOwnerTypeLabel(ProjectOwnerType.BENEFICIARY),
  },
  [ProjectOwnerType.TENANT]: {
    value: ProjectOwnerType.TENANT,
    label: getProjectOwnerTypeLabel(ProjectOwnerType.TENANT),
  },
  [ProjectOwnerType.OTHER]: {
    value: ProjectOwnerType.OTHER,
    label: getProjectOwnerTypeLabel(ProjectOwnerType.OTHER),
  },
};
