import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-cat-icon',
  template: `<svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 40 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0061329 1.51775C0.790594 8.90652 4.72968 12.8783 6.17007 14.0996L7.17715 24.37C7.17715 24.37 8.11576 32.2102 10.2041 32.2102C11.4316 32.2102 11.8296 28.0096 11.9493 24.5489C15.7051 24.5926 20.8937 24.4244 24.5515 23.4018C24.8973 27.0472 25.8797 31.5636 26.8324 32.0623C28.3156 32.8392 28.2317 23.1549 28.5175 20.1823C28.5184 20.1756 28.5175 20.1708 28.5179 20.1642C28.635 18.8554 28.4009 17.1191 28.0564 15.4446L30.3629 19.5776L35.6072 16.2264L37.321 16.4548L38.3025 16.2427L39.9743 14.0373L40.0004 13.5775L38.5468 11.2224L37.9235 9.90211L35.8634 8.87693C35.8484 8.01208 35.7724 7.03945 35.5074 6.94449C35.3312 6.88044 35.0034 7.17284 34.6585 7.56861C34.6359 6.75499 34.5529 5.9087 34.3055 5.82036C34.0039 5.7117 33.2609 6.64148 32.7432 7.3588L28.6005 9.44937L26.5652 9.91625C26.5638 9.91316 26.5612 9.90521 26.5612 9.90521C26.5612 9.90521 14.6975 7.34952 7.33086 12.1764C7.29773 12.1464 7.27079 12.1239 7.23501 12.0907C6.76637 11.6548 6.12148 10.979 5.44745 10.0514C4.09761 8.19185 2.63116 5.33448 2.20183 1.28541C2.13778 0.678956 1.59449 0.239022 0.988034 0.303069C0.38202 0.367557 -0.0579137 0.911291 0.0061329 1.51775ZM37.155 11.4755C37.5388 11.4118 37.9032 11.672 37.965 12.0576C38.0291 12.4432 37.7685 12.8067 37.3829 12.8695C36.9973 12.934 36.6337 12.672 36.5701 12.2873C36.5074 11.9021 36.7689 11.5391 37.155 11.4755Z"
      [attr.fill]="color"
    />
  </svg> `,
})
export class CatIconComponent extends BaseIconComponent {}
