export enum ValuableObjectType {
  JEWELRY_AND_WATCHES = 'JEWELRY_AND_WATCHES',
  HEARING_AIDS = 'HEARING_AIDS',
  WEAPONS = 'WEAPONS',
  PHOTOGRAPHIC_EQUIPMENT = 'PHOTOGRAPHIC_EQUIPMENT',
  FUR_AND_CLOTH = 'FUR_AND_CLOTH',
  PAINTING_AND_SCULPTURES = 'PAINTING_AND_SCULPTURES',
  MUSICAL_INSTRUMENTS = 'MUSICAL_INSTRUMENTS',
  OTHER = 'OTHER',
}

export const getValuableObjectType = (value: ValuableObjectType): string => {
  switch (value) {
    case ValuableObjectType.JEWELRY_AND_WATCHES:
      return $localize`Bijoux et montres`;
    case ValuableObjectType.HEARING_AIDS:
      return $localize`Prothèses auditives`;
    case ValuableObjectType.WEAPONS:
      return $localize`Armes`;
    case ValuableObjectType.PHOTOGRAPHIC_EQUIPMENT:
      return $localize`Équipement photo et vidéo`;
    case ValuableObjectType.FUR_AND_CLOTH:
      return $localize`Fourrures - Vêtements`;
    case ValuableObjectType.PAINTING_AND_SCULPTURES:
      return $localize`Peinture Sculptures`;
    case ValuableObjectType.MUSICAL_INSTRUMENTS:
      return $localize`Instruments de musique`;
    case ValuableObjectType.OTHER:
      return $localize`Autres`;
    default:
      return '';
  }
};

export enum ValuableObjectInsuranceType {
  NATURAL_DISASTER = 'NATURAL_DISASTER',
  THEFT = 'THEFT',
  OUTSIDE_THEFT = 'OUTSIDE_THEFT',
  EARTHQUAKE = 'EARTHQUAKE',
  WATER_DAMAGE = 'WATER_DAMAGE',
  LOSS = 'LOSS',
  ALL_RISKS = 'ALL_RISKS',
}

export const getValuableObjectInsuranceType = (value: ValuableObjectInsuranceType): string => {
  switch (value) {
    case ValuableObjectInsuranceType.NATURAL_DISASTER:
      return $localize`Incendie/événements naturels`;
    case ValuableObjectInsuranceType.THEFT:
      return $localize`Vol`;
    case ValuableObjectInsuranceType.OUTSIDE_THEFT:
      return $localize`Vol simple à l'extérieur du domicile`;
    case ValuableObjectInsuranceType.EARTHQUAKE:
      return $localize`Tremblement de terre`;
    case ValuableObjectInsuranceType.WATER_DAMAGE:
      return $localize`Dégâts d'eau`;
    case ValuableObjectInsuranceType.LOSS:
      return $localize`Perte`;
    case ValuableObjectInsuranceType.ALL_RISKS:
      return $localize`All-risk`;
    default:
      return '';
  }
};
