import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-painting-icon',
  template: `<svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 28 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.3327 0.078125H0.666016V23.4115H6.46602L2.46602 32.7615L5.53268 34.0781L7.19935 30.0948H20.716L22.3827 34.0781L25.4494 32.7615L21.5327 23.4115H27.3327V0.078125ZM19.3327 26.7448H8.66602L10.0993 23.4115H17.8993L19.3327 26.7448ZM23.9993 20.0781H3.99935V3.41146H23.9993V20.0781Z"
      [attr.fill]="color"
    />
  </svg> `,
})
export class PaintingIconComponent extends BaseIconComponent {}
