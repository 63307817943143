import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-merge-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 30 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 31.25V35H27.5C22.145 35 17.4737 32.0513 15 27.6975C12.5262 32.0513 7.855 35 2.5 35H0V31.25H2.5C8.3675 31.25 13.125 26.4925 13.125 20.625V18.75V8.75H8.3925C7.60125 8.75 7.20625 7.79375 7.765 7.235L15 0L22.235 7.235C22.7938 7.79375 22.3975 8.75 21.6075 8.75H16.875V18.75V20.625C16.875 26.4925 21.6325 31.25 27.5 31.25H30Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class MergeIconComponent extends BaseIconComponent {}
