import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgxOtpInputModule } from 'ngx-otp-input';

import { AccountCheckComponent } from './components/account-check/account-check.component';
import { AlternateCollapsiblePanelComponent } from './components/alternate-collapsible-panel/alternate-collapsible-panel.component';
import { BooleanChoiceComponent } from './components/boolean-choice/boolean-choice.component';
import { CardComponent } from './components/card/card.component';
import { CollapsibleTabComponent } from './components/collapsible-tab/collapsible-tab.component';
import { ContractSignatureComponent } from './components/contract-signature/contract-signature.component';
import { CustomDateComponent } from './components/custom-date/custom-date.component';
import { DocumentUploadFormComponent } from './components/document-upload-form/document-upload-form.component';
import { EndScreenComponent } from './components/end-screen/end-screen.component';
import { FamilyMemberSelectComponent } from './components/family-member-select/family-member-select.component';
import { FormWrapperComponent } from './components/form-wrapper/form-wrapper.component';
import { GoogleAutocompleteComponent } from './components/google-autocomplete/google-autocomplete.component';
import { HelpTextComponent } from './components/help-text/help-text.component';
import { HorizontalSeparatorComponent } from './components/horizontal-separator/horizontal-separator.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { ListAdvantagesComponent } from './components/list-advantages/list-advantages.component';
import { LoginComponent } from './components/login/login.component';
import { MultiStepperComponent } from './components/multi-stepper/multi-stepper.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { OffersSearchComponent } from './components/offers-search/offers-search.component';
import { AcceptOfferModalComponent } from './components/offers-view/accept-offer-modal/accept-offer-modal.component';
import { OffersViewComponent } from './components/offers-view/offers-view.component';
import { RejectOfferModalComponent } from './components/offers-view/reject-offer-modal/reject-offer-modal.component';
import { OtpVerifyComponent } from './components/otp-verify/otp-verify.component';
import { PasswordCriteriaComponent } from './components/password-criteria/password-criteria.component';
import { PdfViewerModalComponent } from './components/pdf-viewer-modal/pdf-viewer-modal.component';
import { PoliciesComponent } from './components/policies/policies.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RegisterComponent } from './components/register/register.component';
import { SelectComponent } from './components/select/select.component';
import { SharedCancellationComponent } from './components/shared-cancellation/shared-cancellation.component';
import { SimpleDocumentUploadComponent } from './components/simple-document-upload/simple-document-upload.component';
import { SliderComponent } from './components/slider/slider.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StackedCardsComponent } from './components/stacked-cards/stacked-cards.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { AnimalFlowSummaryComponent } from './components/summaries/animal-flow-summary/animal-flow-summary.component';
import { BuildingFlowSummaryComponent } from './components/summaries/building-flow-summary/building-flow-summary.component';
import { ConstructionFlowSummaryComponent } from './components/summaries/construction-flow-summary/construction-flow-summary.component';
import { CurrentInsurerComponent } from './components/summaries/health-flow-summary/current-insurer/current-insurer.component';
import { DoctorComponent } from './components/summaries/health-flow-summary/doctor/doctor.component';
import { HealthFlowSummaryComponent } from './components/summaries/health-flow-summary/health-flow-summary.component';
import { HealthInfoComponent } from './components/summaries/health-flow-summary/health-info/health-info.component';
import { PersonalInfoComponent } from './components/summaries/health-flow-summary/personal-info/personal-info.component';
import { HouseholdFlowSummaryComponent } from './components/summaries/household-flow-summary/household-flow-summary.component';
import { LegalProtectionFlowSummaryComponent } from './components/summaries/legal-protection-flow-summary/legal-protection-flow-summary.component';
import { OfferDisplayComponent } from './components/summaries/offer-display/offer-display.component';
import { SummarySelectionComponent } from './components/summaries/summary-selection/summary-selection.component';
import { ThirdPillarFlowSummaryComponent } from './components/summaries/third-pillar-flow-summary/third-pillar-flow-summary.component';
import { TravelFlowSummaryComponent } from './components/summaries/travel-flow-summary/travel-flow-summary.component';
import { ValuableObjectsFlowSummaryComponent } from './components/summaries/valuable-objects-flow-summary/valuable-objects-flow-summary.component';
import { VehicleFlowSummaryComponent } from './components/summaries/vehicle-flow-summary/vehicle-flow-summary.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { IconsModule } from './icons/icons.module';
import { SignIconComponent } from './icons/sign-icon.component';
import { KeysPipe } from './pipes/keys.pipe';
import { FormatCurrencyPipe } from './pipes/replace-spaces.pipe';

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    AcceptOfferModalComponent,
    AccountCheckComponent,
    AlternateCollapsiblePanelComponent,
    AnimalFlowSummaryComponent,
    BooleanChoiceComponent,
    BuildingFlowSummaryComponent,
    CardComponent,
    CollapsibleTabComponent,
    ConstructionFlowSummaryComponent,
    ContractSignatureComponent,
    CurrentInsurerComponent,
    CustomDateComponent,
    DoctorComponent,
    DocumentUploadFormComponent,
    EndScreenComponent,
    FamilyMemberSelectComponent,
    FormatCurrencyPipe,
    FormWrapperComponent,
    GoogleAutocompleteComponent,
    HealthFlowSummaryComponent,
    HealthInfoComponent,
    HelpTextComponent,
    HorizontalSeparatorComponent,
    HouseholdFlowSummaryComponent,
    KeysPipe,
    LanguageSwitcherComponent,
    LegalProtectionFlowSummaryComponent,
    ListAdvantagesComponent,
    LoginComponent,
    MultiStepperComponent,
    NumberInputComponent,
    OfferDisplayComponent,
    OffersSearchComponent,
    OffersViewComponent,
    OtpVerifyComponent,
    PasswordCriteriaComponent,
    PdfViewerModalComponent,
    PersonalInfoComponent,
    PoliciesComponent,
    ProgressBarComponent,
    RegisterComponent,
    RejectOfferModalComponent,
    SelectComponent,
    SharedCancellationComponent,
    SignIconComponent,
    SimpleDocumentUploadComponent,
    SliderComponent,
    SpinnerComponent,
    StackedCardsComponent,
    StepperComponent,
    SummarySelectionComponent,
    ThirdPillarFlowSummaryComponent,
    ToggleComponent,
    TopBarComponent,
    TravelFlowSummaryComponent,
    ValuableObjectsFlowSummaryComponent,
    VehicleFlowSummaryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    NgbTooltipModule,
    NgxIntlTelInputModule,
    NgxOtpInputModule,
    PdfViewerModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    AccountCheckComponent,
    AlternateCollapsiblePanelComponent,
    BooleanChoiceComponent,
    BuildingFlowSummaryComponent,
    CardComponent,
    CollapsibleTabComponent,
    ContractSignatureComponent,
    CurrentInsurerComponent,
    CustomDateComponent,
    DoctorComponent,
    DocumentUploadFormComponent,
    FamilyMemberSelectComponent,
    FormatCurrencyPipe,
    FormWrapperComponent,
    GoogleAutocompleteComponent,
    HealthInfoComponent,
    HelpTextComponent,
    HorizontalSeparatorComponent,
    IconsModule,
    KeysPipe,
    LanguageSwitcherComponent,
    ListAdvantagesComponent,
    LoginComponent,
    MatChipsModule,
    MultiStepperComponent,
    NumberInputComponent,
    OffersSearchComponent,
    OffersViewComponent,
    OtpVerifyComponent,
    PasswordCriteriaComponent,
    PdfViewerModalComponent,
    PersonalInfoComponent,
    PoliciesComponent,
    ProgressBarComponent,
    RegisterComponent,
    SelectComponent,
    SharedCancellationComponent,
    SignIconComponent,
    SimpleDocumentUploadComponent,
    SliderComponent,
    SpinnerComponent,
    StackedCardsComponent,
    StepperComponent,
    ToggleComponent,
    TopBarComponent,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation],
    },
  ],
})
export class UiKitModule {}
