import { ContractToSignInfo } from 'src/app/_services/types/contract-template.types';
import { ContractType } from 'src/app/enums/contract-type.enum';
import { InsuranceTypeEnum } from 'src/app/enums/insurance-type.enum';
import { AmbulanceIconComponent } from 'src/app/ui-kit/icons/ambulance-icon.component';
import { BedIconComponent } from 'src/app/ui-kit/icons/bed-icon.component';
import { BedsFourIconComponent } from 'src/app/ui-kit/icons/beds-four-icon.component';
import { BedsTwoIconComponent } from 'src/app/ui-kit/icons/beds-two-icon.component';
import { BodyMassageIconComponent } from 'src/app/ui-kit/icons/body-massage-icon.component';
import { CrossIconComponent } from 'src/app/ui-kit/icons/cross-icon.component';
import { FitnessWeightIconComponent } from 'src/app/ui-kit/icons/fitness-weight-icon.component';
import { GlassesIconComponent } from 'src/app/ui-kit/icons/glasses-icon.component';
import { HelicopterIconComponent } from 'src/app/ui-kit/icons/helicopter-icon.component';
import { LawGavelIconComponent } from 'src/app/ui-kit/icons/law-gavel-icon.component';
import { MopIconComponent } from 'src/app/ui-kit/icons/mop-icon.component';
import { SmileIconComponent } from 'src/app/ui-kit/icons/smile-icon.component';
import { StethoscopeIconComponent } from 'src/app/ui-kit/icons/stethoscope-icon.component';

import { SelectElementMapper } from '../../_services/types/commons.type';
import { HospitalInsuranceEnum } from '../../enums/health/hospital-insurance.enum';
import {
  getHealthPrestationLabel,
  HealthPrestationsEnum,
} from '../../enums/health/prestations.enum';

export const HEALTH_INSURANCE_END_URL = 'maladie/fin';

export const HEALTH_INSURANCE_BASE_URL = 'maladie';
export const HEALTH_INSURANCE_FORM_BASE_URL = 'formulaire';
export const HEALTH_INSURANCE_OFFER_BASE_URL = 'offer';

export const HEALTH_INSURANCE_STEPS = {
  BASE: `${HEALTH_INSURANCE_BASE_URL}/${HEALTH_INSURANCE_FORM_BASE_URL}/`,
  LOOP_START: { step: [1, 1], url: 'membre-suivant' },
  MEMBERS_COUNT: { step: [1, 1], url: 'nombre-personnes' },
  NPA: { step: [1, 1], url: 'npa' },
  START_DATE: { step: [1, 1], url: 'date-debut' },
  AGE_GENDER: { step: [1, 2], url: 'age' },
  DEDUCTIBLE: { step: [2, 1], url: 'franchise' },
  ACCIDENT_COVER: { step: [3, 1], url: 'couverture-accident' },
  BASE_INSURANCE: { step: [3, 1], url: 'modele-assurance' },
  HOSPITALISATION: { step: [3, 1], url: 'assurance-hospitalisation' },
  SUPPLEMENTARY_INSURANCE: { step: [3, 1], url: 'assurance-complementaire' },
  IMPORTANCE_SUPPLEMENTARY_INSURANCE: {
    step: [3, 1],
    url: 'importance-assurance-complementaire',
  },
  OFFER_SEARCH: { step: [4, 1], url: 'recherche-offres' },
  OFFERS: { step: [4, 2], url: 'recherche-offres' },
};

export const HEALTH_OFFER_FORM_STEPS = {
  BASE: `${HEALTH_INSURANCE_BASE_URL}/${HEALTH_INSURANCE_OFFER_BASE_URL}/`,
  ACCOUNT: { step: [1, 1], url: 'compte' },
  LOGIN: { step: [1, 2], url: 'connexion' },
  REGISTER: { step: [1, 2], url: 'inscription' },
  FAMILY_MEMBERS: { step: [2, 1], url: 'membre-famille' },
  PERSONAL_INFO: { step: [2, 1], url: 'informations-personnelles' },
  HELSANA: { step: [3, 1], url: 'helsana' },
  CSS: { step: [3, 1], url: 'css' },
  SWICA: { step: [3, 1], url: 'swica' },
  GM: { step: [3, 1], url: 'groupemutuel' },
  SUMMARY: { step: [4, 1], url: 'recapitulatif' },
  CONTRACT_SIGNATURE: { step: [5, 1], url: 'signature-contrat' },
  CANCELLATION: { step: [5, 1], url: 'resiliation' },
};

export const ASSURANCES_COMPLEMENTAIRES: SelectElementMapper<HealthPrestationsEnum> = {
  [HealthPrestationsEnum.FITNESS]: {
    value: HealthPrestationsEnum.FITNESS,
    label: getHealthPrestationLabel(HealthPrestationsEnum.FITNESS),
    iconComponent: FitnessWeightIconComponent,
    info: $localize`Cette option couvre généralement une partie ou la totalité des frais d'abonnement à des cours fitness reconnu par l'assureur. Elle peut également être appelée "promotion de la santé" auprès de certaines compagnies.`,
  },
  [HealthPrestationsEnum.MASSAGE]: {
    value: HealthPrestationsEnum.MASSAGE,
    label: getHealthPrestationLabel(HealthPrestationsEnum.MASSAGE),
    iconComponent: BodyMassageIconComponent,
    info: $localize`Cette option couvre les frais liés aux séances de massage thérapeutique ou de bien-être. Cela peut inclure différents types de massages tels que le massage suédois, le massage sportif, ou le massage relaxant.`,
  },
  [HealthPrestationsEnum.EYEWEAR]: {
    value: HealthPrestationsEnum.EYEWEAR,
    label: getHealthPrestationLabel(HealthPrestationsEnum.EYEWEAR),
    iconComponent: GlassesIconComponent,
    info: $localize`Cette option couvre une partie ou la totalité des frais liés à l'achat de lunettes de vue ou de lentilles de contact. Cela peut inclure les examens de la vue, les montures de lunettes, les verres correcteurs et les lentilles de contact.`,
  },
  [HealthPrestationsEnum.LEGAL_PROTECTION]: {
    value: HealthPrestationsEnum.LEGAL_PROTECTION,
    label: getHealthPrestationLabel(HealthPrestationsEnum.LEGAL_PROTECTION),
    iconComponent: LawGavelIconComponent,
    info: $localize`La protection juridique dans une assurance santé complémentaire en Suisse t'offre une assistance en cas de litige lié à des questions de santé, comme avec les prestataires de soins ou les assureurs. Elle couvre tes frais juridiques et te fournit des conseils pour résoudre les différends, te garantissant ainsi la protection de tes droits et de tes intérêts en matière de santé.`,
  },
  [HealthPrestationsEnum.REPATRIATION]: {
    value: HealthPrestationsEnum.REPATRIATION,
    label: getHealthPrestationLabel(HealthPrestationsEnum.REPATRIATION),
    iconComponent: AmbulanceIconComponent,
    info: $localize`Cette option offre une couverture pour les frais de rapatriement d'urgence en cas de blessure ou de maladie grave à l'étranger. Cela peut inclure les frais de transport médicalisé par avion ou par ambulance vers ton pays d'origine, ainsi que les frais médicaux associés au rapatriement.`,
  },
  [HealthPrestationsEnum.RESCUE]: {
    value: HealthPrestationsEnum.RESCUE,
    label: getHealthPrestationLabel(HealthPrestationsEnum.RESCUE),
    iconComponent: HelicopterIconComponent,
    info: $localize`Cette option offre une couverture pour les frais de secours en montagne ou en mer en cas d'urgence. Cela peut inclure les frais de recherche et de sauvetage, ainsi que les frais de transport vers un établissement médical approprié en cas d'accident ou de situation d'urgence en plein air.`,
  },
  [HealthPrestationsEnum.CHECK_UP]: {
    value: HealthPrestationsEnum.CHECK_UP,
    label: getHealthPrestationLabel(HealthPrestationsEnum.CHECK_UP),
    iconComponent: StethoscopeIconComponent,
    info: $localize`Cette option couvre les frais liés à des examens de santé préventifs, tels que des bilans de santé complets, des tests sanguins, des examens radiologiques et d'autres dépistages médicaux.`,
  },
  [HealthPrestationsEnum.DENTAL]: {
    value: HealthPrestationsEnum.DENTAL,
    label: getHealthPrestationLabel(HealthPrestationsEnum.DENTAL),
    iconComponent: SmileIconComponent,
    info: $localize`Cette option couvre les frais liés aux traitements orthodontiques et à la correction des problèmes dentaires tels que les appareils dentaires, les gouttières invisibles et autres traitements orthodontiques.`,
  },
  [HealthPrestationsEnum.HYGIENIST]: {
    value: HealthPrestationsEnum.HYGIENIST,
    label: getHealthPrestationLabel(HealthPrestationsEnum.HYGIENIST),
    iconComponent: MopIconComponent,
    info: $localize`Cette option couvre les frais liés aux soins dentaires préventifs, tels que les nettoyages professionnels, les détartrages, les traitements de prévention des caries et d'autres soins visant à maintenir une bonne santé bucco-dentaire.`,
  },
};

export const HOSPITALISATION: SelectElementMapper<HospitalInsuranceEnum> = {
  [HospitalInsuranceEnum.GENERAL]: {
    value: HospitalInsuranceEnum.GENERAL,
    label: $localize`Division commune`,
    iconComponent: BedsFourIconComponent,
    info: $localize`Tu as le choix de l'hôpital. Tu partages une chambre avec d'autres patients à l'hôpital. Les équipements sont basiques.C'est l'option la moins chère.`,
  },
  [HospitalInsuranceEnum.SEMI_PRIVATE]: {
    value: HospitalInsuranceEnum.SEMI_PRIVATE,
    label: $localize`Demi-privée`,
    iconComponent: BedsTwoIconComponent,
    info: $localize`Tu as le choix de l'hôpital. partages la chambre avec un seul autre patient maximum. La chambre est un peu plus confortable. Tu bénéficies du libre choix du médecin. Un peu plus cher que la division commune.`,
  },
  [HospitalInsuranceEnum.PRIVATE]: {
    value: HospitalInsuranceEnum.PRIVATE,
    label: $localize`Privé`,
    iconComponent: BedIconComponent,
    info: $localize`Tu as le choix de l'hôpital. Tu as également ta propre chambre avec des équipements luxueux comme une télévision et un accès Internet. C'est plus confortable et privé. Tu bénéficies du libre choix du médecin. C'est l'option la plus chère.`,
  },
  [HospitalInsuranceEnum.NONE]: {
    value: HospitalInsuranceEnum.NONE,
    label: $localize`Sans assurance hospitalisation`,
    iconComponent: CrossIconComponent,
    info: $localize`Sans assurance hospitalisation : Tu n'as ni le choix de l'hôpital, ni du médecin. Tu dois assumer personnellement les coûts supplémentaires d'une hospitalisation.`,
  },
};

export const INSURERS_LIST = [
  'Agrisano',
  'AMB Assurance SA',
  'Aquilana',
  'Assura-Basis SA',
  'Atupri Gesundheistversicherung AG',
  'Avenir Krakenversicherung AG',
  'Bildende Künstler/Innen',
  'Birchmeier',
  'CONCORDIA',
  'CSS',
  'Easy Sana Krankenversicherung AG',
  'EGK',
  'Einsielder Krankenkasse',
  'GALENOS AG',
  'Gewerbliche',
  'Glamer',
  'Helsana',
  'Hotela',
  'KLuG',
  'KPT',
  'Lumneziana',
  'Luzerner Hinterland',
  'Metallbaufirmen',
  'Mutuel Krankenversicherung AG',
  'Mutuelle Neuchâteloise',
  'ÖKK',
  'Philos Krankenversicherung AG',
  'rhenusana',
  'sana24',
  'sanavals',
  'Sanitas',
  'SLKK',
  'sodalis',
  'Steffisburg',
  'Sumiswalder',
  'SUPRA-1846 SA',
  'SWICA',
  "Vallée d'Entremont",
  'Visana',
  'Visperterminen',
  'vita surselva',
  'Vivacare',
  'Vivao Sympany',
  'Wädenswil',
];

export const NO_INSURER = 'Aucun';

export const DEDUCTIBLE_CHILD_VALUES = [0, 100, 200, 300, 400, 500, 600];
export const DEDUCTIBLE_VALUES = [300, 500, 1000, 1500, 2000, 2500];
export const DEDUCTIBLE_OPTIONS = [
  'CHF 300',
  'CHF 500',
  'CHF 1000',
  'CHF 1500',
  'CHF 2000',
  'CHF 2500',
];
export const DEDUCTIBLE_CHILD_OPTIONS = [
  'CHF 0',
  'CHF 100',
  'CHF 200',
  'CHF 300',
  'CHF 400',
  'CHF 500',
  'CHF 600',
];
export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 maladie',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.HEALTH,
  },
  {
    name: 'Mandat gestion maladie',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.HEALTH,
  },
];

export const HOSPTAL_OFFERS = [
  'demi-privée',
  'privée',
  'eco',
  'global smart',
  'hospita',
  "assurance d'hospitalisation",
];

export const HEALTH_SUMMARY_LEGAL_TEXT = $localize`Selon la Loi fédérale sur la protection des données (LPD), les données personnelles doivent être conservées uniquement aussi longtemps que nécessaire pour atteindre les objectifs pour lesquels elles ont été collectées. C'est pourquoi LTY s'engage a détruire les données relatives au questionnaire médical dès que le processus est achevé et que ces informations ne sont plus nécessaires.`;
