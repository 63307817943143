export enum ClaimLegalCaseAnnouncement {
  DOCUMENTS_NOT_READY = 'DOCUMENTS_NOT_READY',
  DOCUMENTS_READY = 'DOCUMENTS_READY',
}

export const getClaimLegalCaseAnnouncementLabel = (
  announcement: ClaimLegalCaseAnnouncement,
): string => {
  switch (announcement) {
    case ClaimLegalCaseAnnouncement.DOCUMENTS_READY:
      return $localize`J'ai tous les documents prêts pour l'enregistement du cas`;
    case ClaimLegalCaseAnnouncement.DOCUMENTS_NOT_READY:
      return $localize`Je n'ai pas de documents et j'ai besoin d'un conseil juridique pour le moment`;
    default:
      return '';
  }
};

export enum ClaimLegalDomain {
  WORK = 'WORK',
  HOME = 'HOME',
  CONTRACT = 'CONTRACT',
  INSURANCE = 'INSURANCE',
  TRAFFIC = 'TRAFFIC',
  INTERNET = 'INTERNET',
  OTHER = 'OTHER',
}

export const getClaimLegalDomainLabel = (domain: ClaimLegalDomain): string => {
  switch (domain) {
    case ClaimLegalDomain.WORK:
      return $localize`Travail`;
    case ClaimLegalDomain.HOME:
      return $localize`Habitation & bâtiment`;
    case ClaimLegalDomain.CONTRACT:
      return $localize`Ordres & contrats`;
    case ClaimLegalDomain.INSURANCE:
      return $localize`Assurances & prévoyances`;
    case ClaimLegalDomain.TRAFFIC:
      return $localize`Auto & circulation`;
    case ClaimLegalDomain.INTERNET:
      return $localize`Internet & consommation`;
    case ClaimLegalDomain.OTHER:
      return $localize`Autres`;
    default:
      return '';
  }
};

export enum LegalDelay {
  YES = 'YES',
  NO = 'NO',
  DO_NOT_KNOW = 'DO_NOT_KNOW',
}

export const getLegalDelayLabel = (delay: LegalDelay): string => {
  switch (delay) {
    case LegalDelay.YES:
      return $localize`Oui`;
    case LegalDelay.NO:
      return $localize`Non`;
    case LegalDelay.DO_NOT_KNOW:
      return $localize`Je ne sais pas`;
    default:
      return '';
  }
};

export enum LegalClaimCostInterval {
  UP_TO_500 = 'UP_TO_500',
  BETWEEN_501_1500 = 'BETWEEN_501_1500',
  BETWEEN_1501_10000 = 'BETWEEN_1501_10000',
  MORE_THAN_10000 = 'MORE_THAN_10000',
  UNKNOWN = 'UNKNOWN',
}

export const getLegalClaimCostIntervalLabel = (interval: LegalClaimCostInterval): string => {
  switch (interval) {
    case LegalClaimCostInterval.UP_TO_500:
      return $localize`Jusqu'à 500 CHF`;
    case LegalClaimCostInterval.BETWEEN_501_1500:
      return $localize`CHF 501 - 1500`;
    case LegalClaimCostInterval.BETWEEN_1501_10000:
      return $localize`CHF 1501 - 10000`;
    case LegalClaimCostInterval.MORE_THAN_10000:
      return $localize`Plus de 10000 CHF`;
    case LegalClaimCostInterval.UNKNOWN:
      return $localize`Montant inconnu`;
    default:
      return '';
  }
};
