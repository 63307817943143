export enum LegalProtectionCoversEnum {
  PRIVATE = 'PRIVATE',
  CIRCULATION = 'CIRCULATION',
  BUILDING = 'BUILDING',
}

export const getLegalProtectionCoverLabel = (value: LegalProtectionCoversEnum): string => {
  switch (value) {
    case LegalProtectionCoversEnum.PRIVATE:
      return $localize`Privé`;
    case LegalProtectionCoversEnum.CIRCULATION:
      return $localize`Circulation`;
    case LegalProtectionCoversEnum.BUILDING:
      return $localize`Bâtiment`;
    default:
      return '';
  }
};
