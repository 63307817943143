export enum HouseholdAdditionalCovers {
  VEHICLE = 'VEHICLE',
  GROSS_NEGLIGENCE = 'GROSS_NEGLIGENCE',
  HORSE = 'HORSE',
  HUNTER = 'HUNTER',
}

export const getHouseholdAdditionalCoverLabel = (value: HouseholdAdditionalCovers): string => {
  switch (value) {
    case HouseholdAdditionalCovers.VEHICLE:
      return $localize`Véhicule de tiers`;
    case HouseholdAdditionalCovers.GROSS_NEGLIGENCE:
      return $localize`Renonciation à la faute grave`;
    case HouseholdAdditionalCovers.HORSE:
      return $localize`Location de chevaux`;
    case HouseholdAdditionalCovers.HUNTER:
      return $localize`Chasseur`;
    default:
      return '';
  }
};
