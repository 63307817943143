import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-mop-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1627_16240)">
        <path
          d="M10.1802 10.4161L11.3052 16.0411H15.8052L16.9302 10.4161H10.1802Z"
          [attr.fill]="color"
        />
        <path
          d="M0.617676 13.7911H1.74268V16.0411H7.36768V13.7911H8.49268V12.6661H0.617676V13.7911Z"
          [attr.fill]="color"
        />
        <path
          d="M3.99292 11.5413H5.11792V3.10376H6.24292V1.97876H5.11792H3.99292H2.86792V3.10376H3.99292V11.5413Z"
          [attr.fill]="color"
        />
        <path
          d="M15.1751 9.29113L16.2439 7.95238L15.3664 7.25488L13.7351 9.29113H15.1751Z"
          [attr.fill]="color"
        />
        <path d="M15.2427 1.41626H14.1177V2.54126H15.2427V1.41626Z" [attr.fill]="color" />
        <path d="M14.1179 2.54102H12.9929V3.66602H14.1179V2.54102Z" [attr.fill]="color" />
        <path d="M16.3677 2.54102H15.2427V3.66602H16.3677V2.54102Z" [attr.fill]="color" />
        <path d="M15.2427 3.66589H14.1177V4.79089H15.2427V3.66589Z" [attr.fill]="color" />
        <path d="M9.05518 4.7915H7.93018V5.9165H9.05518V4.7915Z" [attr.fill]="color" />
        <path d="M7.93042 5.91614H6.80542V7.04114H7.93042V5.91614Z" [attr.fill]="color" />
        <path d="M10.1804 5.91614H9.05542V7.04114H10.1804V5.91614Z" [attr.fill]="color" />
        <path d="M9.05518 7.04114H7.93018V8.16614H9.05518V7.04114Z" [attr.fill]="color" />
      </g>
      <defs>
        <clipPath id="clip0_1627_16240">
          <rect width="18" height="18" fill="white" transform="translate(0.0551758 0.29126)" />
        </clipPath>
      </defs>
    </svg>
  `,
})
export class MopIconComponent extends BaseIconComponent {}
