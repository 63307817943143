import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-bed-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4883 9.30823V7.05823C11.4883 6.98364 11.4586 6.9121 11.4059 6.85935C11.3532 6.80661 11.2816 6.77698 11.207 6.77698C11.1324 6.77698 11.0609 6.80661 11.0082 6.85935C10.9554 6.9121 10.9258 6.98364 10.9258 7.05823V7.62073H7.55078V7.05823C7.55078 6.98364 7.52115 6.9121 7.46841 6.85935C7.41566 6.80661 7.34412 6.77698 7.26953 6.77698C7.19494 6.77698 7.1234 6.80661 7.07066 6.85935C7.01791 6.9121 6.98828 6.98364 6.98828 7.05823V9.30823C6.8391 9.30823 6.69602 9.36749 6.59053 9.47298C6.48504 9.57847 6.42578 9.72154 6.42578 9.87073V11.5582C6.42578 11.6328 6.45541 11.7044 6.50816 11.7571C6.5609 11.8098 6.63244 11.8395 6.70703 11.8395C6.78162 11.8395 6.85316 11.8098 6.90591 11.7571C6.95865 11.7044 6.98828 11.6328 6.98828 11.5582V10.9957H11.4883V11.5582C11.4883 11.6328 11.5179 11.7044 11.5707 11.7571C11.6234 11.8098 11.6949 11.8395 11.7695 11.8395C11.8441 11.8395 11.9157 11.8098 11.9684 11.7571C12.0211 11.7044 12.0508 11.6328 12.0508 11.5582V9.87073C12.0508 9.72154 11.9915 9.57847 11.886 9.47298C11.7805 9.36749 11.6375 9.30823 11.4883 9.30823ZM8.95703 9.30823H7.55078V9.02698C7.55078 8.95239 7.58041 8.88085 7.63316 8.8281C7.6859 8.77536 7.75744 8.74573 7.83203 8.74573H8.67578C8.75037 8.74573 8.82191 8.77536 8.87466 8.8281C8.9274 8.88085 8.95703 8.95239 8.95703 9.02698V9.30823ZM10.9258 9.30823H9.51953V9.02698C9.51953 8.95239 9.54916 8.88085 9.60191 8.8281C9.65465 8.77536 9.72619 8.74573 9.80078 8.74573H10.6445C10.7191 8.74573 10.7907 8.77536 10.8434 8.8281C10.8961 8.88085 10.9258 8.95239 10.9258 9.02698V9.30823Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class BedIconComponent extends BaseIconComponent {}
