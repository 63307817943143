import { NgModule } from '@angular/core';

import { AdultIconComponent } from './adult-icon.component';
import { AmbulanceIconComponent } from './ambulance-icon.component';
import { AntennaIconComponent } from './antenna-icon.component';
import { AppartmentComplexIconComponent } from './appartment-complex-icon.component';
import { AppartmentComplex2IconComponent } from './appatment-complex-2-icon.component';
import { BabyIconComponent } from './baby-icon.component';
import { BackIconComponent } from './back-icon.component';
import { BanditIconComponent } from './bandit-icon.component';
import { BedIconComponent } from './bed-icon.component';
import { BedsFourIconComponent } from './beds-four-icon.component';
import { BedsTwoIconComponent } from './beds-two-icon.component';
import { BodyMassageIconComponent } from './body-massage-icon.component';
import { BrokenGlassIconComponent } from './broken-glass-icon.component';
import { BuildingIconComponent } from './building-icon.component';
import { CameraIconComponent } from './camera-icon.component';
import { CarDrivingIconComponent } from './car-driving-icon.component';
import { CarIconComponent } from './car-icon.component';
import { CarWheelIconComponent } from './car-wheel-icon.component';
import { CartwheelIconComponent } from './cartwheel-icon.component';
import { CatIconComponent } from './cat-icon.component';
import { CheckIconComponent } from './check-icon.component';
import { ChevronRightIconComponent } from './chevron-right-icon.component';
import { ChildIconComponent } from './child-icon.component';
import { ConstructionIconComponent } from './construction-icon.component';
import { CrossCircledIconComponent } from './cross-circled-icon.component';
import { CrossIconComponent } from './cross-icon.component';
import { DoctorIconComponent } from './doctor-icon.component';
import { DoctorsIconComponent } from './doctors-icon.component';
import { DocumentIconComponent } from './document-icon.component';
import { DogIconComponent } from './dog-icon.component';
import { DoorIconComponent } from './door-icon.component';
import { DownloadIconComponent } from './download-icon.component';
import { EarIconComponent } from './ear-icon.component';
import { EarthquakeIconComponent } from './earthquake-icon.component';
import { EnvelopeIconComponent } from './envelope-icon.component';
import { FamilyIconComponent } from './family-icon.component';
import { FemaleIconComponent } from './female-icon.component';
import { FilledHouseComponent } from './filled-house-icon.component';
import { FireAlarmIconComponent } from './fire-alarm-icon.component';
import { FireIconComponent } from './fire-icon.component';
import { FitnessWeightIconComponent } from './fitness-weight-icon.component';
import { ForbiddenIconComponent } from './forbidden-icon.component';
import { FurcoatIconComponent } from './furcoat-icon.component';
import { GarageIconComponent } from './garage-icon.component';
import { GiftIconComponent } from './gift-icon.component';
import { GlassesIconComponent } from './glasses-icon.component';
import { GlobeIconComponent } from './globe-icon.component';
import { GunIconComponent } from './gun-icon.component';
import { HeadLightIconComponent } from './headlight-icon.component';
import { HealthIconComponent } from './health-icon.component';
import { HelicopterIconComponent } from './helicopter-icon.component';
import { HospitalIconComponent } from './hospital-icon.component';
import { HourglassIconComponent } from './hourglass-icon.component';
import { FlatHouseIconComponent } from './house-flat-icon.component';
import { MinimalistHouseIconComponent } from './house-minimalist-icon.component';
import { SimpleHouseIconComponent } from './house-simple-icon.component';
import { HouseWithBuildingIconComponent } from './house-with-building-icon.component';
import { HouseWithTreeIconComponent } from './house-with-tree-icon.component';
import { ImageIconComponent } from './image-icon.component';
import { ImmatriculationIconComponent } from './immatriculation-icon.component';
import { IndividualIconComponent } from './individual-icon.component';
import { InfoIconComponent } from './info-icon.component';
import { InvoiceIconComponent } from './invoice-icon.component';
import { JewelryIconComponent } from './jewelry-icon.component';
import { KeyIconComponent } from './key-icon.component';
import { LawBalanceIconComponent } from './law-balance-icon.component';
import { LawGavelIconComponent } from './law-gavel-icon.component';
import { DoubleLayerComponent } from './layer-double-icon.component';
import { SingleLayerComponent } from './layer-single-icon.component';
import { LightBulbComponent } from './light-bulb-icon.component';
import { LightningIconComponent } from './lightning-icon.component';
import { LinkExternalIconComponent } from './link-external-icon.component';
import { MaleIconComponent } from './male-icon.component';
import { MapPinIconComponent } from './map-pin-icon.component';
import { MenuIconComponent } from './menu-icon.component';
import { MergeIconComponent } from './merge-icon.component';
import { MinusIconComponent } from './minus-icon.component';
import { MoneyBillIconComponent } from './money-bill-icon.component';
import { MopIconComponent } from './mop-icon.component';
import { NumbersIconComponent } from './numbers-icon.component';
import { PaintingIconComponent } from './painting-icon.component';
import { ParkingIconComponent } from './parking-icon.component';
import { PhoneIconComponent } from './phone-icon.component';
import { PianoIconComponent } from './piano-icon.component';
import { PiggyBankIconComponent } from './piggy-bank-icon.component';
import { PipeIconComponent } from './pipe-icon.component';
import { PlaneIconComponent } from './plane-icon.component';
import { PlusIconComponent } from './plus-icon.component';
import { RefreshIconComponent } from './refresh-icon.component';
import { RepairIconComponent } from './repair-icon.component';
import { RoadIconComponent } from './road-icon.component';
import { SendIconComponent } from './send-icon.component';
import { ShieldIconComponent } from './shield-icon.component';
import { SmileIconComponent } from './smile-icon.component';
import { SmileyIconComponent } from './smiley-icon.component';
import { StarIconComponent } from './star-icon.component';
import { StarIconFilledComponent } from './star-icon-filled.component';
import { StethoscopeIconComponent } from './stethoscope-icon.component';
import { SuitcaseIconComponent } from './suitcase-icon.component';
import { ThiefIconComponent } from './thief-icon.component';
import { TireIconComponent } from './tire-icon.component';
import { TriangleIconComponent } from './triangle-icon.component';
import { TromboneIconComponent } from './trombone-icon.component';
import { TwoHousesIconComponent } from './two-houses.component';
import { UserCircleIconComponent } from './user-circle-icon.component';
import { VideoIconComponent } from './video-icon.component';
import { VillaIconComponent } from './villa-icon.component';
import { WalletIconComponent } from './wallet-icon.component';

@NgModule({
  declarations: [
    AdultIconComponent,
    AmbulanceIconComponent,
    AntennaIconComponent,
    AppartmentComplex2IconComponent,
    AppartmentComplexIconComponent,
    AppartmentComplexIconComponent,
    BabyIconComponent,
    BackIconComponent,
    BanditIconComponent,
    BedIconComponent,
    BedsFourIconComponent,
    BedsTwoIconComponent,
    BodyMassageIconComponent,
    BrokenGlassIconComponent,
    BuildingIconComponent,
    CameraIconComponent,
    CarDrivingIconComponent,
    CarIconComponent,
    CartwheelIconComponent,
    CarWheelIconComponent,
    CatIconComponent,
    CheckIconComponent,
    ChevronRightIconComponent,
    ChildIconComponent,
    ConstructionIconComponent,
    CrossCircledIconComponent,
    CrossIconComponent,
    DoctorIconComponent,
    DoctorsIconComponent,
    DocumentIconComponent,
    DogIconComponent,
    DoorIconComponent,
    DoubleLayerComponent,
    DownloadIconComponent,
    EarIconComponent,
    EarthquakeIconComponent,
    EnvelopeIconComponent,
    FamilyIconComponent,
    FemaleIconComponent,
    FilledHouseComponent,
    FireAlarmIconComponent,
    FireIconComponent,
    FitnessWeightIconComponent,
    FlatHouseIconComponent,
    ForbiddenIconComponent,
    FurcoatIconComponent,
    GarageIconComponent,
    GiftIconComponent,
    GlassesIconComponent,
    GlobeIconComponent,
    GunIconComponent,
    HeadLightIconComponent,
    HealthIconComponent,
    HelicopterIconComponent,
    HospitalIconComponent,
    HourglassIconComponent,
    HouseWithBuildingIconComponent,
    HouseWithTreeIconComponent,
    ImageIconComponent,
    ImmatriculationIconComponent,
    IndividualIconComponent,
    InfoIconComponent,
    InvoiceIconComponent,
    JewelryIconComponent,
    KeyIconComponent,
    LawBalanceIconComponent,
    LawGavelIconComponent,
    LightBulbComponent,
    LightningIconComponent,
    LinkExternalIconComponent,
    MaleIconComponent,
    MapPinIconComponent,
    MenuIconComponent,
    MergeIconComponent,
    MinimalistHouseIconComponent,
    MinusIconComponent,
    MoneyBillIconComponent,
    MopIconComponent,
    NumbersIconComponent,
    PaintingIconComponent,
    ParkingIconComponent,
    PhoneIconComponent,
    PianoIconComponent,
    PiggyBankIconComponent,
    PipeIconComponent,
    PlaneIconComponent,
    PlusIconComponent,
    RefreshIconComponent,
    RepairIconComponent,
    RoadIconComponent,
    SendIconComponent,
    ShieldIconComponent,
    SimpleHouseIconComponent,
    SingleLayerComponent,
    SmileIconComponent,
    SmileyIconComponent,
    StarIconComponent,
    StarIconFilledComponent,
    StethoscopeIconComponent,
    SuitcaseIconComponent,
    ThiefIconComponent,
    TireIconComponent,
    TireIconComponent,
    TriangleIconComponent,
    TromboneIconComponent,
    TwoHousesIconComponent,
    UserCircleIconComponent,
    VideoIconComponent,
    VillaIconComponent,
    WalletIconComponent,
  ],
  exports: [
    AdultIconComponent,
    AmbulanceIconComponent,
    AntennaIconComponent,
    AppartmentComplex2IconComponent,
    AppartmentComplexIconComponent,
    AppartmentComplexIconComponent,
    BabyIconComponent,
    BackIconComponent,
    BanditIconComponent,
    BedIconComponent,
    BedsFourIconComponent,
    BedsTwoIconComponent,
    BodyMassageIconComponent,
    BrokenGlassIconComponent,
    BuildingIconComponent,
    CameraIconComponent,
    CarDrivingIconComponent,
    CarIconComponent,
    CarWheelIconComponent,
    CatIconComponent,
    CheckIconComponent,
    ChevronRightIconComponent,
    ChildIconComponent,
    ConstructionIconComponent,
    CrossCircledIconComponent,
    CrossIconComponent,
    DoctorIconComponent,
    DoctorsIconComponent,
    DocumentIconComponent,
    DogIconComponent,
    DoorIconComponent,
    DoubleLayerComponent,
    DownloadIconComponent,
    EarIconComponent,
    EarthquakeIconComponent,
    EnvelopeIconComponent,
    FamilyIconComponent,
    FemaleIconComponent,
    FilledHouseComponent,
    FireAlarmIconComponent,
    FireIconComponent,
    FitnessWeightIconComponent,
    FlatHouseIconComponent,
    ForbiddenIconComponent,
    FurcoatIconComponent,
    GarageIconComponent,
    GiftIconComponent,
    GlassesIconComponent,
    GlobeIconComponent,
    GunIconComponent,
    HeadLightIconComponent,
    HealthIconComponent,
    HelicopterIconComponent,
    HospitalIconComponent,
    HourglassIconComponent,
    HouseWithBuildingIconComponent,
    HouseWithTreeIconComponent,
    ImageIconComponent,
    ImmatriculationIconComponent,
    IndividualIconComponent,
    InfoIconComponent,
    InvoiceIconComponent,
    JewelryIconComponent,
    KeyIconComponent,
    LawBalanceIconComponent,
    LawGavelIconComponent,
    LightBulbComponent,
    LightningIconComponent,
    LinkExternalIconComponent,
    MaleIconComponent,
    MapPinIconComponent,
    MenuIconComponent,
    MergeIconComponent,
    MinimalistHouseIconComponent,
    MinusIconComponent,
    MoneyBillIconComponent,
    MopIconComponent,
    NumbersIconComponent,
    PaintingIconComponent,
    ParkingIconComponent,
    PhoneIconComponent,
    PianoIconComponent,
    PiggyBankIconComponent,
    PipeIconComponent,
    PlaneIconComponent,
    PlusIconComponent,
    RefreshIconComponent,
    RepairIconComponent,
    RoadIconComponent,
    SendIconComponent,
    ShieldIconComponent,
    SimpleHouseIconComponent,
    SingleLayerComponent,
    SmileIconComponent,
    SmileyIconComponent,
    StarIconComponent,
    StarIconFilledComponent,
    StethoscopeIconComponent,
    SuitcaseIconComponent,
    ThiefIconComponent,
    TireIconComponent,
    TireIconComponent,
    TriangleIconComponent,
    TromboneIconComponent,
    TwoHousesIconComponent,
    UserCircleIconComponent,
    VideoIconComponent,
    VillaIconComponent,
    WalletIconComponent,
  ],
})
export class IconsModule {}
