import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-lightning-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1667 3.83203L5.5 23.832H20.5L18.8333 37.1654L35.5 17.1654H20.5L22.1667 3.83203Z"
        [attr.stroke]="color"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
})
export class LightningIconComponent extends BaseIconComponent {}
