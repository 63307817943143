import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-female-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 20 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1627_13800)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0136 15.8895C9.14583 15.892 8.2862 15.7226 7.48428 15.3911C6.68236 15.0596 5.95403 14.5726 5.34131 13.9582C1.18099 9.80315 4.16663 2.69423 10.0136 2.69423C15.8565 2.69423 18.8501 9.79918 14.6871 13.9582C14.0743 14.5728 13.3457 15.0599 12.5435 15.3914C11.7414 15.7229 10.8815 15.8922 10.0136 15.8895ZM16.5365 15.8234C22.3834 9.9857 18.1715 0.0551758 10.0096 0.0551758C1.86622 0.0551758 -2.39595 9.9857 3.45362 15.8234C4.92197 17.2917 7.3335 18.1516 8.65501 18.4241V21.2338H4.69047V23.8728H8.65501V26.5119H11.298V23.8728H15.2626V21.2338H11.298V18.4241C13.9411 18.1502 15.0681 17.2891 16.5365 15.8234Z"
          [attr.fill]="color"
        />
      </g>
      <defs>
        <clipPath id="clip0_1627_13800">
          <rect
            width="18.5197"
            height="26.4567"
            fill="white"
            transform="translate(0.740173 0.0551758)"
          />
        </clipPath>
      </defs>
    </svg>
  `,
})
export class FemaleIconComponent extends BaseIconComponent {}
