import { ComponentType } from '@angular/cdk/overlay';
import { BaseIconComponent } from 'src/app/ui-kit/icons/base-icon.component';
import { CarIconComponent } from 'src/app/ui-kit/icons/car-icon.component';
import { CartwheelIconComponent } from 'src/app/ui-kit/icons/cartwheel-icon.component';
import { DogIconComponent } from 'src/app/ui-kit/icons/dog-icon.component';
import { MinimalistHouseIconComponent } from 'src/app/ui-kit/icons/house-minimalist-icon.component';
import { SimpleHouseIconComponent } from 'src/app/ui-kit/icons/house-simple-icon.component';
import { JewelryIconComponent } from 'src/app/ui-kit/icons/jewelry-icon.component';
import { KeyIconComponent } from 'src/app/ui-kit/icons/key-icon.component';
import { PlaneIconComponent } from 'src/app/ui-kit/icons/plane-icon.component';

import { InsuranceTypeEnum } from '../insurance-type.enum';

export enum ClaimType {
  TRAVEL = InsuranceTypeEnum.TRAVEL,
  BUILDING = InsuranceTypeEnum.BUILDING,
  RC_HOUSEHOLD = InsuranceTypeEnum.RC_HOUSEHOLD,
  LEGAL_PROTECTION = InsuranceTypeEnum.LEGAL_PROTECTION,
  MOTOR_VEHICLE = InsuranceTypeEnum.MOTOR_VEHICLE,
  ANIMAL = InsuranceTypeEnum.ANIMAL,
  VALUABLE_OBJECTS = InsuranceTypeEnum.VALUABLE_OBJECTS,
  CONSTRUCTION = InsuranceTypeEnum.CONSTRUCTION,
}

export const getClaimTypeLabel = (type: ClaimType): string => {
  switch (type) {
    case ClaimType.TRAVEL:
      return $localize`Voyage`;
    case ClaimType.BUILDING:
      return $localize`Bâtiment`;
    case ClaimType.RC_HOUSEHOLD:
      return $localize`RC ménage`;
    case ClaimType.LEGAL_PROTECTION:
      return $localize`Protection juridique`;
    case ClaimType.MOTOR_VEHICLE:
      return $localize`Véhicule à moteur`;
    case ClaimType.ANIMAL:
      return $localize`Animal`;
    case ClaimType.VALUABLE_OBJECTS:
      return $localize`Objet de valeur`;
    case ClaimType.CONSTRUCTION:
      return $localize`Construction`;
    default:
      return '';
  }
};

export const getClaimTypeIcon = (type: ClaimType): ComponentType<BaseIconComponent> => {
  switch (type) {
    case ClaimType.TRAVEL:
      return PlaneIconComponent;
    case ClaimType.BUILDING:
      return SimpleHouseIconComponent;
    case ClaimType.RC_HOUSEHOLD:
      return CartwheelIconComponent;
    case ClaimType.LEGAL_PROTECTION:
      return KeyIconComponent;
    case ClaimType.MOTOR_VEHICLE:
      return CarIconComponent;
    case ClaimType.ANIMAL:
      return DogIconComponent;
    case ClaimType.VALUABLE_OBJECTS:
      return JewelryIconComponent;
    case ClaimType.CONSTRUCTION:
      return MinimalistHouseIconComponent;
    default:
      return null;
  }
};
