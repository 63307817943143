import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-camera-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1627_15242)">
        <path
          d="M8.44482 15.2834H4.69482V16.7834H8.44482V18.2834L10.6948 16.0334L8.44482 13.7834V15.2834ZM11.4448 15.2834V16.7834H15.1948V15.2834H11.4448ZM9.94482 6.28345C10.7736 6.28345 11.4448 5.6122 11.4448 4.78345C11.4448 3.9547 10.7736 3.28345 9.94482 3.28345C9.11607 3.28345 8.44857 3.9547 8.44857 4.78345C8.45232 5.6122 9.11607 6.28345 9.94482 6.28345ZM13.6948 0.283447H6.19482C5.36607 0.283447 4.69482 0.954697 4.69482 1.78345V12.2834C4.69482 13.1122 5.36607 13.7834 6.19482 13.7834H13.6948C14.5236 13.7834 15.1948 13.1122 15.1948 12.2834V1.78345C15.1948 0.954697 14.5236 0.283447 13.6948 0.283447ZM6.19482 1.78345H13.6948V9.65845C13.6948 8.4097 11.1936 7.78345 9.94482 7.78345C8.69607 7.78345 6.19482 8.4097 6.19482 9.65845V1.78345Z"
          [attr.fill]="color"
        />
      </g>
      <defs>
        <clipPath id="clip0_1627_15242">
          <rect width="18" height="18" fill="white" transform="translate(0.944824 0.283447)" />
        </clipPath>
      </defs>
    </svg>
  `,
})
export class CameraIconComponent extends BaseIconComponent {}
