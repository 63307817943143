import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-loss-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 25 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.2085 2.61276C4.57482 2.61276 2.4398 4.74778 2.4398 7.38146C2.4398 10.0151 4.57482 12.1502 7.2085 12.1502C7.87824 12.1502 8.38099 12.0298 8.78696 11.8319C9.19366 11.6338 9.54912 11.3356 9.89359 10.9153C10.4995 10.1763 10.9968 9.16832 11.652 7.84038C11.783 7.5749 11.9203 7.29663 12.0661 7.00513C12.1966 6.74414 12.3238 6.48528 12.4492 6.23035C13.1008 4.90589 13.7021 3.68336 14.4423 2.78052C14.9027 2.21891 15.4405 1.74574 16.1133 1.41795C16.7867 1.0898 17.5495 0.929688 18.429 0.929688C21.9921 0.929688 24.8807 3.81824 24.8807 7.38146C24.8807 10.9446 21.9921 13.8332 18.429 13.8332C16.9773 13.8332 15.6356 13.3525 14.5573 12.5425C14.1858 12.2634 14.1109 11.7358 14.3899 11.3642C14.6691 10.9927 15.1967 10.9177 15.5683 11.1968C16.3653 11.7956 17.3545 12.1502 18.429 12.1502C21.0626 12.1502 23.1977 10.0151 23.1977 7.38146C23.1977 4.74778 21.0626 2.61276 18.429 2.61276C17.7592 2.61276 17.2564 2.73315 16.8505 2.93097C16.4438 3.12914 16.0884 3.42738 15.7439 3.84756C15.1379 4.58671 14.6407 5.5946 13.9854 6.92254C13.8545 7.18802 13.7172 7.46629 13.5714 7.75779C13.4409 8.01878 13.3137 8.27764 13.1882 8.53257C12.5367 9.85703 11.9353 11.0796 11.1951 11.9824C10.7348 12.544 10.1969 13.0172 9.52419 13.3449C8.85073 13.6731 8.08798 13.8332 7.2085 13.8332C3.64528 13.8332 0.756729 10.9446 0.756729 7.38146C0.756729 3.81824 3.64528 0.929688 7.2085 0.929688C8.66025 0.929688 10.0022 1.41018 11.0807 2.22047C11.4523 2.49964 11.5271 3.02719 11.248 3.39876C10.9688 3.77034 10.4413 3.84525 10.0697 3.56607C9.2727 2.96727 8.28321 2.61276 7.2085 2.61276Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class InfinityIconComponent extends BaseIconComponent {}
