import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-furcoat-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4180_32059)">
        <path
          d="M11.25 8.25C10.9044 8.25 10.625 7.97062 10.625 7.625C10.625 3.83437 13.7094 0.75 17.5 0.75C17.8456 0.75 18.125 1.02938 18.125 1.375C18.125 1.72062 17.8456 2 17.5 2C14.3981 2 11.875 4.52313 11.875 7.625C11.875 7.97062 11.5956 8.25 11.25 8.25ZM28.7475 8.25C28.4019 8.25 28.1225 7.97062 28.1225 7.625C28.1225 4.52313 25.5994 2 22.4975 2C22.1519 2 21.8725 1.72062 21.8725 1.375C21.8725 1.02938 22.1519 0.75 22.4975 0.75C26.2881 0.75 29.3725 3.83437 29.3725 7.625C29.3725 7.97062 29.0931 8.25 28.7475 8.25Z"
          [attr.fill]="color"
        />
        <path
          d="M22.4975 2H17.5C17.1544 2 16.875 1.72062 16.875 1.375C16.875 1.02938 17.1544 0.75 17.5 0.75H22.4975C22.8431 0.75 23.1225 1.02938 23.1225 1.375C23.1225 1.72062 22.8431 2 22.4975 2ZM19.9981 20.75C19.8644 20.75 19.7288 20.7075 19.615 20.6181C19.3425 20.4062 19.2931 20.0138 19.5056 19.7412L28.2544 8.4925C28.4669 8.22125 28.8588 8.17 29.1313 8.38313C29.4038 8.595 29.4531 8.9875 29.2406 9.26L20.4919 20.5088C20.3687 20.6669 20.1844 20.75 19.9981 20.75Z"
          [attr.fill]="color"
        />
        <path
          d="M19.9995 20.75C19.8133 20.75 19.6289 20.6669 19.5058 20.5088L10.757 9.26C10.5445 8.98688 10.5939 8.595 10.8664 8.38313C11.1402 8.17 11.5314 8.22125 11.7433 8.4925L20.492 19.7413C20.7045 20.0144 20.6552 20.4062 20.3827 20.6181C20.2689 20.7075 20.1333 20.75 19.9995 20.75ZM16.2489 8.25C15.9033 8.25 15.6239 7.97062 15.6239 7.625C15.6239 5.55687 17.3058 3.875 19.3739 3.875C19.7195 3.875 19.9989 4.15438 19.9989 4.5C19.9989 4.84562 19.7195 5.125 19.3739 5.125C17.9952 5.125 16.8739 6.24625 16.8739 7.625C16.8739 7.97062 16.5945 8.25 16.2489 8.25Z"
          [attr.fill]="color"
        />
        <path
          d="M23.7492 8.25C23.4036 8.25 23.1242 7.97062 23.1242 7.625C23.1242 6.24625 22.003 5.125 20.6242 5.125C20.2786 5.125 19.9992 4.84562 19.9992 4.5C19.9992 4.15438 20.2786 3.875 20.6242 3.875C22.6923 3.875 24.3742 5.55687 24.3742 7.625C24.3742 7.97062 24.0948 8.25 23.7492 8.25ZM19.9998 13.8775C19.798 13.8775 19.5992 13.78 19.4792 13.5994L15.7292 7.97188C15.5373 7.68438 15.6155 7.29625 15.9023 7.105C16.1905 6.91313 16.578 6.99062 16.7692 7.27812L20.5192 12.9056C20.7111 13.1931 20.633 13.5813 20.3461 13.7725C20.2392 13.8431 20.1186 13.8775 19.9998 13.8775Z"
          [attr.fill]="color"
        />
        <path
          d="M19.9984 13.8775C19.8796 13.8775 19.759 13.8431 19.6521 13.7725C19.3646 13.5806 19.2871 13.1931 19.479 12.9056L23.2303 7.27812C23.4215 6.99062 23.8084 6.91313 24.0971 7.105C24.3846 7.29688 24.4621 7.68438 24.2703 7.97188L20.519 13.5994C20.399 13.78 20.2003 13.8775 19.9984 13.8775ZM20.624 5.125H19.374C19.0284 5.125 18.749 4.84562 18.749 4.5C18.749 4.15438 19.0284 3.875 19.374 3.875H20.624C20.9696 3.875 21.249 4.15438 21.249 4.5C21.249 4.84562 20.9696 5.125 20.624 5.125Z"
          [attr.fill]="color"
        />
        <path
          d="M19.999 20.7487C19.6534 20.7487 19.374 20.4693 19.374 20.1237V13.2512C19.374 12.9056 19.6534 12.6262 19.999 12.6262C20.3446 12.6262 20.624 12.9056 20.624 13.2512V20.1237C20.624 20.4693 20.3446 20.7487 19.999 20.7487ZM33.1209 29.4974C32.8334 29.4974 32.5746 29.2987 32.5109 29.0062L28.1378 9.00868C28.064 8.67118 28.2778 8.33806 28.6146 8.26493C28.954 8.18993 29.2853 8.40493 29.3584 8.74181L33.7321 28.7393C33.8059 29.0768 33.5921 29.4099 33.2553 29.4831C33.2103 29.4924 33.1646 29.4974 33.1209 29.4974Z"
          [attr.fill]="color"
        />
        <path
          d="M26.874 18.2489C26.8308 18.2489 26.7865 18.2445 26.7427 18.2352C26.4052 18.1633 26.1896 17.8314 26.2621 17.4933L28.1358 8.74578C28.2077 8.40828 28.5396 8.1889 28.8777 8.26515C29.2152 8.33703 29.4308 8.66891 29.3583 9.00703L27.4846 17.7545C27.4215 18.0483 27.1627 18.2489 26.874 18.2489Z"
          [attr.fill]="color"
        />
        <path
          d="M29.9965 30.7507C29.7165 30.7507 29.4621 30.5613 29.3909 30.277L26.2671 17.777C26.1827 17.4426 26.3865 17.1032 26.7221 17.0195C27.0565 16.9338 27.3958 17.1388 27.4796 17.4745L30.6034 29.9745C30.6877 30.3088 30.484 30.6482 30.1483 30.732C30.0977 30.7445 30.0465 30.7507 29.9965 30.7507Z"
          [attr.fill]="color"
        />
        <path
          d="M29.9975 30.7505C29.75 30.7505 29.515 30.6024 29.4168 30.3574C29.2887 30.0367 29.445 29.673 29.7656 29.5449L32.8893 28.2949C33.2075 28.1667 33.5737 28.3224 33.7018 28.6436C33.83 28.9642 33.6737 29.328 33.3531 29.4561L30.2293 30.7061C30.1537 30.7367 30.075 30.7505 29.9975 30.7505Z"
          [attr.fill]="color"
        />
        <path
          d="M29.998 36.3762C29.6524 36.3762 29.373 36.0969 29.373 35.7512V30.125C29.373 29.7794 29.6524 29.5 29.998 29.5C30.3437 29.5 30.623 29.7794 30.623 30.125V35.7512C30.623 36.0969 30.3437 36.3762 29.998 36.3762Z"
          [attr.fill]="color"
        />
        <path
          d="M25.6228 40.7485C25.2772 40.7485 24.9978 40.4691 24.9978 40.1235C24.9978 39.7779 25.2772 39.4985 25.6228 39.4985C27.691 39.4985 29.3728 37.8166 29.3728 35.7485C29.3728 35.4029 29.6522 35.1235 29.9978 35.1235C30.3435 35.1235 30.6228 35.4029 30.6228 35.7485C30.6228 38.5054 28.3797 40.7485 25.6228 40.7485ZM6.87971 29.4972C6.83596 29.4972 6.79034 29.4922 6.74534 29.4829C6.40846 29.4097 6.19471 29.0766 6.26846 28.7391L10.6422 8.74162C10.7153 8.40474 11.046 8.19037 11.386 8.26474C11.7228 8.33787 11.9366 8.67099 11.8628 9.00849L7.48909 29.006C7.42596 29.2985 7.16721 29.4972 6.87971 29.4972Z"
          [attr.fill]="color"
        />
        <path
          d="M13.1254 18.2491C12.8366 18.2491 12.5779 18.0485 12.5147 17.7547L10.641 9.00722C10.5685 8.66972 10.7841 8.33784 11.1216 8.26534C11.4566 8.18847 11.7904 8.40847 11.8635 8.74597L13.7372 17.4935C13.8097 17.831 13.5941 18.1628 13.2566 18.2353C13.2129 18.2447 13.1685 18.2491 13.1254 18.2491Z"
          [attr.fill]="color"
        />
        <path
          d="M10.0026 30.7507C9.95264 30.7507 9.90139 30.7445 9.85076 30.732C9.51576 30.6482 9.31201 30.3088 9.39576 29.9745L12.5195 17.4745C12.6033 17.1388 12.942 16.9338 13.277 17.0195C13.612 17.1032 13.8158 17.4426 13.732 17.777L10.6083 30.277C10.537 30.5613 10.2826 30.7507 10.0026 30.7507Z"
          [attr.fill]="color"
        />
        <path
          d="M10.0031 30.7505C9.92556 30.7505 9.84681 30.7367 9.77118 30.7061L6.64743 29.4561C6.32681 29.328 6.17056 28.9642 6.29868 28.6436C6.42681 28.3224 6.79243 28.1667 7.11118 28.2949L10.2349 29.5449C10.5556 29.673 10.7118 30.0367 10.5837 30.3574C10.4856 30.6024 10.2506 30.7505 10.0031 30.7505Z"
          [attr.fill]="color"
        />
        <path
          d="M10.002 36.3762C9.65633 36.3762 9.37695 36.0969 9.37695 35.7512V30.125C9.37695 29.7794 9.65633 29.5 10.002 29.5C10.3476 29.5 10.627 29.7794 10.627 30.125V35.7512C10.627 36.0969 10.3476 36.3762 10.002 36.3762Z"
          [attr.fill]="color"
        />
        <path
          d="M14.377 40.75C11.6201 40.75 9.37695 38.5069 9.37695 35.75C9.37695 35.4044 9.65633 35.125 10.002 35.125C10.3476 35.125 10.627 35.4044 10.627 35.75C10.627 37.8181 12.3088 39.5 14.377 39.5C14.7226 39.5 15.002 39.7794 15.002 40.125C15.002 40.4706 14.7226 40.75 14.377 40.75Z"
          [attr.fill]="color"
        />
        <path
          d="M18.124 40.75H14.3777C14.0321 40.75 13.7527 40.4706 13.7527 40.125C13.7527 39.7794 14.0321 39.5 14.3777 39.5H18.124C18.4696 39.5 18.749 39.7794 18.749 40.125C18.749 40.4706 18.4696 40.75 18.124 40.75ZM27.4427 20.75H12.5577C12.2121 20.75 11.9327 20.4706 11.9327 20.125C11.9327 19.7794 12.2121 19.5 12.5577 19.5H27.4427C27.7884 19.5 28.0677 19.7794 28.0677 20.125C28.0677 20.4706 27.7884 20.75 27.4427 20.75ZM28.0102 23.2512L11.9902 23.25C11.6446 23.25 11.3652 22.97 11.3652 22.625C11.3652 22.2794 11.6452 22 11.9902 22L28.0109 22.0012C28.3565 22.0012 28.6359 22.2812 28.6359 22.6262C28.6352 22.9719 28.3552 23.2512 28.0102 23.2512Z"
          [attr.fill]="color"
        />
        <path
          d="M20 38.8713C19.6544 38.8713 19.375 38.5919 19.375 38.2463V22.625C19.375 22.2794 19.6544 22 20 22C20.3456 22 20.625 22.2794 20.625 22.625V38.2463C20.625 38.5919 20.3456 38.8713 20 38.8713Z"
          [attr.fill]="color"
        />
        <path
          d="M18.124 40.7513C17.7784 40.7513 17.499 40.4719 17.499 40.1263C17.499 39.7806 17.7784 39.5013 18.124 39.5013C18.814 39.5013 19.3753 38.94 19.3753 38.25C19.3753 37.9044 19.6546 37.625 20.0003 37.625C20.3459 37.625 20.6253 37.9044 20.6253 38.25C20.6253 39.6294 19.5034 40.7513 18.124 40.7513ZM25.6228 40.7525H21.8765C21.5309 40.7525 21.2515 40.4731 21.2515 40.1275C21.2515 39.7819 21.5309 39.5025 21.8765 39.5025H25.6228C25.9684 39.5025 26.2478 39.7819 26.2478 40.1275C26.2478 40.4731 25.9684 40.7525 25.6228 40.7525Z"
          [attr.fill]="color"
        />
        <path
          d="M21.8762 40.7487C20.4969 40.7487 19.375 39.6269 19.375 38.2475C19.375 37.9019 19.6544 37.6225 20 37.6225C20.3456 37.6225 20.625 37.9019 20.625 38.2475C20.625 38.9375 21.1862 39.4987 21.8762 39.4987C22.2219 39.4987 22.5012 39.7781 22.5012 40.1237C22.5012 40.4694 22.2219 40.7487 21.8762 40.7487ZM11.25 9.50125C10.9044 9.50125 10.625 9.22187 10.625 8.87625V7.625C10.625 7.27937 10.9044 7 11.25 7C11.5956 7 11.875 7.27937 11.875 7.625V8.87625C11.875 9.22187 11.5956 9.50125 11.25 9.50125ZM28.7475 9.50125C28.4019 9.50125 28.1225 9.22187 28.1225 8.87625V7.625C28.1225 7.27937 28.4019 7 28.7475 7C29.0931 7 29.3725 7.27937 29.3725 7.625V8.87625C29.3725 9.22187 29.0931 9.50125 28.7475 9.50125ZM20.6238 25.1237H20C19.6544 25.1237 19.375 24.8444 19.375 24.4987C19.375 24.1531 19.6544 23.8737 20 23.8737H20.6238C20.9694 23.8737 21.2488 24.1531 21.2488 24.4987C21.2488 24.8444 20.9694 25.1237 20.6238 25.1237ZM20.6238 28.875H20C19.6544 28.875 19.375 28.5956 19.375 28.25C19.375 27.9044 19.6544 27.625 20 27.625H20.6238C20.9694 27.625 21.2488 27.9044 21.2488 28.25C21.2488 28.5956 20.9694 28.875 20.6238 28.875ZM20.6238 32.625H20C19.6544 32.625 19.375 32.3456 19.375 32C19.375 31.6544 19.6544 31.375 20 31.375H20.6238C20.9694 31.375 21.2488 31.6544 21.2488 32C21.2488 32.3456 20.9694 32.625 20.6238 32.625ZM20.6238 36.3762H20C19.6544 36.3762 19.375 36.0969 19.375 35.7512C19.375 35.4056 19.6544 35.1262 20 35.1262H20.6238C20.9694 35.1262 21.2488 35.4056 21.2488 35.7512C21.2488 36.0969 20.9694 36.3762 20.6238 36.3762Z"
          [attr.fill]="color"
        />
      </g>
    </svg>
  `,
})
export class FurcoatIconComponent extends BaseIconComponent {}
