import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-jewelry-icon',
  template: `<svg
    [attr.height]="height"
    [attr.width]="width"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.6806 17.4575L37.6702 17.3767C37.6512 17.2756 37.617 17.178 37.5686 17.0871L32.4998 8.92793C32.4998 8.92793 31.8534 8.12793 31.6998 8.12793H10.0998L3.60384 16.8103L3.52704 16.8903L3.51664 16.9279C3.46176 17.0161 3.41669 17.11 3.38224 17.2079L3.35584 17.2855C3.28099 17.5339 3.28462 17.7993 3.36624 18.0455L3.39664 18.1327C3.43744 18.2359 3.48784 18.3303 3.54704 18.4143L19.2518 38.3079L19.2838 38.3535C19.3646 38.4575 19.463 38.5495 19.583 38.6335L19.6742 38.6903C19.7638 38.7463 19.8574 38.7911 19.9934 38.8407L20.0822 38.8703C20.2358 38.9095 20.3694 38.9279 20.4998 38.9279C20.6318 38.9279 20.7606 38.9103 20.9342 38.8655L21.0406 38.8279C21.1446 38.7911 21.2382 38.7455 21.351 38.6759L21.4214 38.6319C21.5422 38.5463 21.639 38.4535 21.6926 38.3807L37.4174 18.4663C37.5565 18.2808 37.6472 18.0636 37.6814 17.8343L37.6934 17.7311L37.6998 17.6495L37.6806 17.4575ZM29.2486 16.9279L31.487 12.8775L33.7238 16.9279H29.2486ZM27.9062 19.3279H33.2166L24.3654 30.5399L27.9062 19.3279ZM26.5926 15.7135L23.3598 11.3279H29.0158L26.5926 15.7135ZM16.2374 19.3279H24.7646L20.4998 32.8295L16.2374 19.3279ZM17.083 16.9279L20.4998 12.2911L23.9166 16.9279H17.083ZM14.6766 15.3503L13.195 11.3279H17.6406L14.6766 15.3503ZM16.6358 30.5407L7.78384 19.3279H13.095L16.6358 30.5407ZM10.6318 12.9055L12.1142 16.9279H7.66784L10.6318 12.9055Z"
      [attr.fill]="color"
    />
  </svg>`,
})
export class JewelryIconComponent extends BaseIconComponent {}
