export enum HealthPrestationsEnum {
  FITNESS = 'FITNESS',
  MASSAGE = 'MASSAGE',
  EYEWEAR = 'EYEWEAR',
  LEGAL_PROTECTION = 'LEGAL_PROTECTION',
  REPATRIATION = 'REPATRIATION',
  RESCUE = 'RESCUE',
  CHECK_UP = 'CHECK_UP',
  DENTAL = 'DENTAL',
  HYGIENIST = 'HYGIENIST',
}

export const getHealthPrestationLabel = (value: HealthPrestationsEnum): string => {
  switch (value) {
    case HealthPrestationsEnum.FITNESS:
      return $localize`Fitness`;
    case HealthPrestationsEnum.MASSAGE:
      return $localize`Massage`;
    case HealthPrestationsEnum.EYEWEAR:
      return $localize`Lunettes`;
    case HealthPrestationsEnum.LEGAL_PROTECTION:
      return $localize`Protection juridique`;
    case HealthPrestationsEnum.REPATRIATION:
      return $localize`Rapatriement`;
    case HealthPrestationsEnum.RESCUE:
      return $localize`Sauvetage`;
    case HealthPrestationsEnum.CHECK_UP:
      return $localize`Check up`;
    case HealthPrestationsEnum.DENTAL:
      return $localize`Correction des dents`;
    case HealthPrestationsEnum.HYGIENIST:
      return $localize`Hygiéniste`;
    default:
      return '';
  }
};
