import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-pipe-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.7766 36.2684C36.7766 34.6732 33.8662 30.2148 33.8662 30.2148C33.8662 30.2148 30.9118 34.6732 30.9118 36.2684C30.9118 37.8604 32.2254 39.1516 33.8446 39.1516C35.463 39.1516 36.7766 37.8604 36.7766 36.2684ZM15.7326 11.2884H9.8678V5.50676H11.467V2.35156H0.799805V5.50676H2.3998V14.4428C2.3998 16.766 4.3102 18.6484 6.6662 18.6484H15.7326V20.226H18.9334V9.71236H15.7326V11.2884ZM37.5982 24.4308V15.4948C37.5982 13.1724 35.6886 11.2892 33.335 11.2892H24.267V9.71316H21.0662V20.2276H24.267V18.6492H30.1326V24.4308H28.535V27.586H39.1998V24.4308H37.5982Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class PipeIconComponent extends BaseIconComponent {}
