export enum AnimalCovers {
  VETERINARY_FEE = 'VETERINARY_FEE',
  EXAM = 'EXAM',
  MEDECINE = 'MEDECINE',
  SURGERY = 'SURGERY',
  ACCOMODATION = 'ACCOMODATION',
  EMERGENCY_SUPPLEMENTS = 'EMERGENCY_SUPPLEMENTS',
  TRANSPORTATION = 'TRANSPORTATION',
  REHABILITATION = 'REHABILITATION',
  ALTERNATIVE_MEDICINE = 'ALTERNATIVE_MEDICINE',
  PREVENTION = 'PREVENTION',
  DENTAL = 'DENTAL',
  ADDITIONAL_SERVICES = 'ADDITIONAL_SERVICES',
  HEREDITARY_ILLNESS = 'HEREDITARY_ILLNESS',
  CHEMO_RADIO = 'CHEMO_RADIO',
}
