export enum VehiclePrestationsEnum {
  FREE_GARAGE_CHOICE = 'FREE_GARAGE_CHOICE',
  REPLACEMENT_VEHICLE = 'REPLACEMENT_VEHICLE',
  PERSONAL_BELONGINGS = 'PERSONAL_BELONGINGS',
  PASSENGERS_PROTECTION = 'PASSENGERS_PROTECTION',
  PARKING_DAMAGE = 'PARKING_DAMAGE',
  GROSS_NEGLIGENCE = 'GROSS_NEGLIGENCE',
  TROUBESHOOTING = 'TROUBESHOOTING',
  GLASS_PROTECTION = 'GLASS_PROTECTION',
  TIRES = 'TIRES',
}

export const getVehiclePrestationLabel = (value: VehiclePrestationsEnum): string => {
  switch (value) {
    case VehiclePrestationsEnum.FREE_GARAGE_CHOICE:
      return $localize`Libre choix du garage`;
    case VehiclePrestationsEnum.REPLACEMENT_VEHICLE:
      return $localize`Véhicule de remplacement en cas de réparation`;
    case VehiclePrestationsEnum.PERSONAL_BELONGINGS:
      return $localize`Effets personnels`;
    case VehiclePrestationsEnum.PASSENGERS_PROTECTION:
      return $localize`Protection des occupants`;
    case VehiclePrestationsEnum.GROSS_NEGLIGENCE:
      return $localize`Renonciation de recours en cas de faute grave`;
    case VehiclePrestationsEnum.TROUBESHOOTING:
      return $localize`Dépannage`;
    case VehiclePrestationsEnum.GLASS_PROTECTION:
      return $localize`Couverture pour les phares`;
    case VehiclePrestationsEnum.TIRES:
      return $localize`Pneus & jantes`;
    case VehiclePrestationsEnum.PARKING_DAMAGE:
      return $localize`Dommage Parking`;
    default:
      return '';
  }
};
