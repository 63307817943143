import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-car-icon',
  template: ` <svg
    [attr.height]="height"
    [attr.width]="width"
    viewBox="0 0 31 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.0333 1.75755C26.8625 1.26594 26.5428 0.839716 26.1187 0.53806C25.6946 0.236405 25.1871 0.0742882 24.6667 0.0742188H6.33333C5.24167 0.0742188 4.30833 0.774219 3.96667 1.75755L0.5 11.7409V25.0742C0.5 25.9909 1.25 26.7409 2.16667 26.7409H3.83333C4.27536 26.7409 4.69928 26.5653 5.01184 26.2527C5.3244 25.9402 5.5 25.5162 5.5 25.0742V23.4075H25.5V25.0742C25.5 25.9909 26.25 26.7409 27.1667 26.7409H28.8333C29.2754 26.7409 29.6993 26.5653 30.0118 26.2527C30.3244 25.9402 30.5 25.5162 30.5 25.0742V11.7409L27.0333 1.75755ZM6.33333 18.4075C4.95 18.4075 3.83333 17.2909 3.83333 15.9075C3.83333 14.5242 4.95 13.4075 6.33333 13.4075C7.71667 13.4075 8.83333 14.5242 8.83333 15.9075C8.83333 17.2909 7.71667 18.4075 6.33333 18.4075ZM24.6667 18.4075C23.2833 18.4075 22.1667 17.2909 22.1667 15.9075C22.1667 14.5242 23.2833 13.4075 24.6667 13.4075C26.05 13.4075 27.1667 14.5242 27.1667 15.9075C27.1667 17.2909 26.05 18.4075 24.6667 18.4075ZM3.83333 10.0742L6.33333 2.57422H24.6667L27.1667 10.0742H3.83333Z"
      [attr.fill]="color"
    />
  </svg>`,
})
export class CarIconComponent extends BaseIconComponent {}
