import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-beds-two-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.93726 9.9397V7.6897C6.93726 7.61511 6.90762 7.54357 6.85488 7.49082C6.80214 7.43808 6.7306 7.40845 6.65601 7.40845C6.58141 7.40845 6.50988 7.43808 6.45713 7.49082C6.40439 7.54357 6.37476 7.61511 6.37476 7.6897V8.2522H2.99976V7.6897C2.99976 7.61511 2.97012 7.54357 2.91738 7.49082C2.86463 7.43808 2.7931 7.40845 2.71851 7.40845C2.64391 7.40845 2.57238 7.43808 2.51963 7.49082C2.46689 7.54357 2.43726 7.61511 2.43726 7.6897V9.9397C2.28807 9.9397 2.145 9.99896 2.03951 10.1044C1.93402 10.2099 1.87476 10.353 1.87476 10.5022V12.1897C1.87476 12.2643 1.90439 12.3358 1.95713 12.3886C2.00988 12.4413 2.08141 12.4709 2.15601 12.4709C2.2306 12.4709 2.30214 12.4413 2.35488 12.3886C2.40762 12.3358 2.43726 12.2643 2.43726 12.1897V11.6272H6.93726V12.1897C6.93726 12.2643 6.96689 12.3358 7.01963 12.3886C7.07238 12.4413 7.14391 12.4709 7.21851 12.4709C7.2931 12.4709 7.36464 12.4413 7.41738 12.3886C7.47012 12.3358 7.49976 12.2643 7.49976 12.1897V10.5022C7.49976 10.353 7.44049 10.2099 7.335 10.1044C7.22951 9.99896 7.08644 9.9397 6.93726 9.9397ZM4.40601 9.9397H2.99976V9.65845C2.99976 9.58386 3.02939 9.51232 3.08213 9.45957C3.13488 9.40683 3.20641 9.3772 3.28101 9.3772H4.12476C4.19935 9.3772 4.27088 9.40683 4.32363 9.45957C4.37637 9.51232 4.40601 9.58386 4.40601 9.65845V9.9397ZM6.37476 9.9397H4.96851V9.65845C4.96851 9.58386 4.99814 9.51232 5.05088 9.45957C5.10363 9.40683 5.17516 9.3772 5.24976 9.3772H6.09351C6.1681 9.3772 6.23964 9.40683 6.29238 9.45957C6.34512 9.51232 6.37476 9.58386 6.37476 9.65845V9.9397Z"
        [attr.fill]="color"
      />
      <path
        d="M15.1875 9.9397V7.6897C15.1875 7.61511 15.1579 7.54357 15.1051 7.49082C15.0524 7.43808 14.9808 7.40845 14.9062 7.40845C14.8317 7.40845 14.7601 7.43808 14.7074 7.49082C14.6546 7.54357 14.625 7.61511 14.625 7.6897V8.2522H11.25V7.6897C11.25 7.61511 11.2204 7.54357 11.1676 7.49082C11.1149 7.43808 11.0433 7.40845 10.9688 7.40845C10.8942 7.40845 10.8226 7.43808 10.7699 7.49082C10.7171 7.54357 10.6875 7.61511 10.6875 7.6897V9.9397C10.5383 9.9397 10.3952 9.99896 10.2898 10.1044C10.1843 10.2099 10.125 10.353 10.125 10.5022V12.1897C10.125 12.2643 10.1546 12.3358 10.2074 12.3886C10.2601 12.4413 10.3317 12.4709 10.4062 12.4709C10.4808 12.4709 10.5524 12.4413 10.6051 12.3886C10.6579 12.3358 10.6875 12.2643 10.6875 12.1897V11.6272H15.1875V12.1897C15.1875 12.2643 15.2171 12.3358 15.2699 12.3886C15.3226 12.4413 15.3942 12.4709 15.4688 12.4709C15.5433 12.4709 15.6149 12.4413 15.6676 12.3886C15.7204 12.3358 15.75 12.2643 15.75 12.1897V10.5022C15.75 10.353 15.6907 10.2099 15.5852 10.1044C15.4798 9.99896 15.3367 9.9397 15.1875 9.9397ZM12.6562 9.9397H11.25V9.65845C11.25 9.58386 11.2796 9.51232 11.3324 9.45957C11.3851 9.40683 11.4567 9.3772 11.5312 9.3772H12.375C12.4496 9.3772 12.5211 9.40683 12.5739 9.45957C12.6266 9.51232 12.6562 9.58386 12.6562 9.65845V9.9397ZM14.625 9.9397H13.2188V9.65845C13.2188 9.58386 13.2484 9.51232 13.3011 9.45957C13.3539 9.40683 13.4254 9.3772 13.5 9.3772H14.3438C14.4183 9.3772 14.4899 9.40683 14.5426 9.45957C14.5954 9.51232 14.625 9.58386 14.625 9.65845V9.9397Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class BedsTwoIconComponent extends BaseIconComponent {}
