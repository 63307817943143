import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-glasses-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1627_16223)">
        <path
          d="M3.80542 16.0336C1.70542 16.0336 0.0554199 14.3836 0.0554199 12.2836C0.0554199 10.1836 1.70542 8.53357 3.80542 8.53357C5.90542 8.53357 7.55542 10.1836 7.55542 12.2836C7.55542 14.3836 5.90542 16.0336 3.80542 16.0336ZM3.80542 10.0336C2.53042 10.0336 1.55542 11.0086 1.55542 12.2836C1.55542 13.5586 2.53042 14.5336 3.80542 14.5336C5.08042 14.5336 6.05542 13.5586 6.05542 12.2836C6.05542 11.0086 5.08042 10.0336 3.80542 10.0336ZM14.3054 16.0336C12.2054 16.0336 10.5554 14.3836 10.5554 12.2836C10.5554 10.1836 12.2054 8.53357 14.3054 8.53357C16.4054 8.53357 18.0554 10.1836 18.0554 12.2836C18.0554 14.3836 16.4054 16.0336 14.3054 16.0336ZM14.3054 10.0336C13.0304 10.0336 12.0554 11.0086 12.0554 12.2836C12.0554 13.5586 13.0304 14.5336 14.3054 14.5336C15.5804 14.5336 16.5554 13.5586 16.5554 12.2836C16.5554 11.0086 15.5804 10.0336 14.3054 10.0336Z"
          [attr.fill]="color"
        />
        <path
          d="M11.3804 12.5836C11.1554 12.5836 10.9304 12.5086 10.7804 12.2836C10.3304 11.8336 9.73042 11.5336 9.05542 11.5336C8.38042 11.5336 7.78042 11.8336 7.33042 12.3586C7.03042 12.6586 6.58042 12.7336 6.28042 12.4336C5.98042 12.1336 5.90542 11.6836 6.20542 11.3836C6.95542 10.5586 7.93042 10.0336 9.05542 10.0336C10.1804 10.0336 11.2304 10.4836 11.9054 11.3836C12.2054 11.6836 12.1304 12.2086 11.8304 12.4336C11.6804 12.5086 11.5304 12.5836 11.3804 12.5836ZM0.80542 13.0336C0.35542 13.0336 0.0554199 12.7336 0.0554199 12.2836C0.0554199 9.35857 0.35542 2.53357 3.05542 2.53357C4.70542 2.53357 5.30542 3.88357 5.30542 4.78357C5.30542 5.23357 5.00542 5.53357 4.55542 5.53357C4.10542 5.53357 3.80542 5.23357 3.80542 4.78357C3.80542 4.40857 3.65542 4.03357 3.05542 4.03357C2.38042 4.03357 1.55542 7.85857 1.55542 12.2836C1.55542 12.7336 1.25542 13.0336 0.80542 13.0336ZM17.3054 13.0336C16.8554 13.0336 16.5554 12.7336 16.5554 12.2836C16.5554 6.58357 15.5054 4.03357 15.0554 4.03357C14.4554 4.03357 14.3054 4.48357 14.3054 4.78357C14.3054 5.15857 13.9304 5.53357 13.5554 5.53357C13.1804 5.53357 12.8054 5.23357 12.8054 4.78357C12.8054 3.88357 13.4054 2.53357 15.0554 2.53357C17.7554 2.53357 18.0554 9.35857 18.0554 12.2836C18.0554 12.7336 17.7554 13.0336 17.3054 13.0336Z"
          [attr.fill]="color"
        />
      </g>
      <defs>
        <clipPath id="clip0_1627_16223">
          <rect width="18" height="18" fill="white" transform="translate(0.0551758 0.283447)" />
        </clipPath>
      </defs>
    </svg>
  `,
})
export class GlassesIconComponent extends BaseIconComponent {}
