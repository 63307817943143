import { SelectElementMapper } from '../../_services/types/commons.type';
import { ContractToSignInfo } from '../../_services/types/contract-template.types';
import { ContractType } from '../../enums/contract-type.enum';
import { InsuranceTypeEnum } from '../../enums/insurance-type.enum';
import {
  getVehiclePrestationLabel,
  VehiclePrestationsEnum,
} from '../../enums/vehicle/vehicle-prestations.enum';
import { CarDrivingIconComponent } from '../../ui-kit/icons/car-driving-icon.component';
import { ForbiddenIconComponent } from '../../ui-kit/icons/forbidden-icon.component';
import { GarageIconComponent } from '../../ui-kit/icons/garage-icon.component';
import { HeadLightIconComponent } from '../../ui-kit/icons/headlight-icon.component';
import { ParkingIconComponent } from '../../ui-kit/icons/parking-icon.component';
import { RepairIconComponent } from '../../ui-kit/icons/repair-icon.component';
import { ShieldIconComponent } from '../../ui-kit/icons/shield-icon.component';
import { TireIconComponent } from '../../ui-kit/icons/tire-icon.component';
import { WalletIconComponent } from '../../ui-kit/icons/wallet-icon.component';

export const VEHICLE_BASE_URL = 'vehicule';

export const VEHICLE_FORM_BASE_URL = 'formulaire';

export const VEHICLE_OFFER_BASE_URL = 'offre';

export const VEHICLE_STEPS = {
  BASE: `${VEHICLE_BASE_URL}/${VEHICLE_FORM_BASE_URL}`,
  OBJECT: { step: [1, 1], url: 'objet' },
  INPUT_TYPE: { step: [1, 2], url: 'type-selection' },
  FIRST_REGISTRATION_DATE: { step: [1, 3], url: 'date-immatriculation' },
  BRAND: { step: [1, 4], url: 'marque' },
  MODEL: { step: [1, 5], url: 'model' },
  VERSION: { step: [1, 6], url: 'version' },
  DISTANCE: { step: [1, 7], url: 'distance' },
  MAIN_USE: { step: [1, 8], url: 'utilisation-principale' },
  PARKING_AREA: { step: [1, 9], url: 'parking' },
  POLICY_HOLDER: { step: [2, 1], url: 'prenneur-assurance' },
  LEASING: { step: [3, 1], url: 'leasing' },
  START_DATE: { step: [4, 1], url: 'date-debut' },
  PRESTATIONS: { step: [4, 2], url: 'couvertures' },
  OFFER_SEARCH: { step: [5, 1], url: 'recherche-offres' },
  OFFERS: { step: [5, 2], url: 'recherche-offres' },
};

export const VEHICLE_OFFER_FORM_STEPS = {
  BASE: `${VEHICLE_BASE_URL}/${VEHICLE_OFFER_BASE_URL}/`,
  ACCOUNT: { step: [1, 1], url: 'compte' },
  LOGIN: { step: [1, 2], url: 'connexion' },
  REGISTER: { step: [1, 2], url: 'inscription' },
  PERSONAL_INFO: { step: [2, 1], url: 'informations-personnelles' },
  FORM: { step: [3, 1], url: 'formulaire' },
  SUMMARY: { step: [4, 1], url: 'recapitulatif' },
  CANCELLATION: { step: [4, 1], url: 'resiliation' },
  CONTRACT_SIGNATURE: { step: [5, 1], url: 'signature-contrat' },
};

export const VEHICLE_END_URL = `${VEHICLE_BASE_URL}/fin`;

export const COLLISION_DEDUCTIBLE = [
  { value: 500, label: 'CHF 500' },
  { value: 1000, label: "CHF 1'000" },
  { value: 2000, label: "CHF 2'000" },
];

export const PARTIAL_COVER_DEDUCTIBLE = [
  { value: 0, label: 'CHF 0' },
  { value: 100, label: 'CHF 100' },
  { value: 200, label: 'CHF 200' },
  { value: 300, label: 'CHF 300' },
  { value: 500, label: 'CHF 500' },
];

export const VEHICLE_COVERS: SelectElementMapper<VehiclePrestationsEnum> = {
  [VehiclePrestationsEnum.FREE_GARAGE_CHOICE]: {
    value: VehiclePrestationsEnum.FREE_GARAGE_CHOICE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.FREE_GARAGE_CHOICE),
    iconComponent: GarageIconComponent,
  },
  [VehiclePrestationsEnum.REPLACEMENT_VEHICLE]: {
    value: VehiclePrestationsEnum.REPLACEMENT_VEHICLE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.REPLACEMENT_VEHICLE),
    iconComponent: CarDrivingIconComponent,
  },
  [VehiclePrestationsEnum.PERSONAL_BELONGINGS]: {
    value: VehiclePrestationsEnum.PERSONAL_BELONGINGS,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.PERSONAL_BELONGINGS),
    iconComponent: WalletIconComponent,
  },
  [VehiclePrestationsEnum.PASSENGERS_PROTECTION]: {
    value: VehiclePrestationsEnum.PASSENGERS_PROTECTION,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.PASSENGERS_PROTECTION),
    iconComponent: ShieldIconComponent,
  },
  [VehiclePrestationsEnum.GROSS_NEGLIGENCE]: {
    value: VehiclePrestationsEnum.GROSS_NEGLIGENCE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.GROSS_NEGLIGENCE),
    iconComponent: ForbiddenIconComponent,
  },
  [VehiclePrestationsEnum.TROUBESHOOTING]: {
    value: VehiclePrestationsEnum.TROUBESHOOTING,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.TROUBESHOOTING),
    iconComponent: RepairIconComponent,
  },
  [VehiclePrestationsEnum.GLASS_PROTECTION]: {
    value: VehiclePrestationsEnum.GLASS_PROTECTION,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.GLASS_PROTECTION),
    iconComponent: HeadLightIconComponent,
  },
  [VehiclePrestationsEnum.TIRES]: {
    value: VehiclePrestationsEnum.TIRES,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.TIRES),
    iconComponent: TireIconComponent,
  },
  [VehiclePrestationsEnum.PARKING_DAMAGE]: {
    value: VehiclePrestationsEnum.PARKING_DAMAGE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.PARKING_DAMAGE),
    iconComponent: ParkingIconComponent,
  },
};

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 véhicule',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.MOTOR_VEHICLE,
  },
  {
    name: 'Mandat gestion véhicule',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.MOTOR_VEHICLE,
  },
];
