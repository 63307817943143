import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-forbidden-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 0C8.45001 0 5.52494 1.2116 3.36827 3.36827C1.2116 5.52494 0 8.45001 0 11.5C0 14.55 1.2116 17.4751 3.36827 19.6317C5.52494 21.7884 8.45001 23 11.5 23C14.55 23 17.4751 21.7884 19.6317 19.6317C21.7884 17.4751 23 14.55 23 11.5C23 8.45001 21.7884 5.52494 19.6317 3.36827C17.4751 1.2116 14.55 0 11.5 0ZM11.5 4.3125C12.6617 4.27689 13.8147 4.52352 14.8602 5.03125L5.03125 14.8602C4.52352 13.8147 4.27689 12.6617 4.3125 11.5C4.3125 9.59376 5.06975 7.76559 6.41767 6.41767C7.76559 5.06975 9.59376 4.3125 11.5 4.3125ZM18.6875 11.5C18.6875 13.4062 17.9302 15.2344 16.5823 16.5823C15.2344 17.9302 13.4062 18.6875 11.5 18.6875C10.3383 18.7231 9.18531 18.4765 8.13984 17.9688L17.9688 8.13984C18.4765 9.18531 18.7231 10.3383 18.6875 11.5Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class ForbiddenIconComponent extends BaseIconComponent {}
