import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-villa-icon',
  template: `<svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.6915 16.9257L29.8828 7.99819C29.6413 7.74103 29.3496 7.53608 29.0259 7.39597C28.7021 7.25587 28.3531 7.18359 28.0003 7.18359C27.6475 7.18359 27.2984 7.25587 26.9747 7.39597C26.6509 7.53608 26.3593 7.74103 26.1178 7.99819L18.309 16.9232C18.1503 17.1039 18.047 17.3266 18.0117 17.5645C17.9763 17.8024 18.0104 18.0455 18.1097 18.2646C18.2091 18.4836 18.3696 18.6694 18.5719 18.7995C18.7742 18.9296 19.0097 18.9986 19.2503 18.9982H36.7503C36.9903 18.9982 37.2253 18.929 37.4272 18.799C37.629 18.669 37.7891 18.4837 37.8884 18.2651C37.9876 18.0465 38.0219 17.8039 37.987 17.5664C37.9521 17.3289 37.8495 17.1065 37.6915 16.9257ZM15.7128 18.9982C15.4921 18.3717 15.4418 17.6979 15.567 17.0456C15.6922 16.3933 15.9884 15.786 16.4253 15.2857L20.8253 10.2482H9.41277C8.76584 10.2518 8.13068 10.4216 7.56817 10.7411C7.00566 11.0607 6.53465 11.5194 6.20027 12.0732L3.17527 17.1107C3.06275 17.3005 3.00246 17.5166 3.00054 17.7373C2.99861 17.9579 3.05511 18.1751 3.1643 18.3668C3.27349 18.5585 3.43148 18.7179 3.62221 18.8288C3.81295 18.9397 4.02964 18.9981 4.25027 18.9982H15.7128Z"
      [attr.fill]="color"
    />
    <path d="M29.2502 27.748H26.7502V33.998H29.2502V27.748Z" [attr.fill]="color" />
    <path
      d="M20.5002 33.998H24.2502V27.748C24.2522 27.0856 24.5162 26.4509 24.9847 25.9825C25.4531 25.5141 26.0878 25.25 26.7502 25.248H29.2502C29.9127 25.25 30.5474 25.5141 31.0158 25.9825C31.4842 26.4509 31.7483 27.0856 31.7502 27.748V33.998H34.2502C34.9127 33.9961 35.5474 33.732 36.0158 33.2636C36.4842 32.7952 36.7483 32.1605 36.7502 31.498V21.498H20.5002V33.998Z"
      [attr.fill]="color"
    />
    <path d="M13.0015 26.498H10.5002V28.998H13.0015V26.498Z" [attr.fill]="color" />
    <path
      d="M4.25024 31.498C4.25222 32.1605 4.51625 32.7952 4.98466 33.2636C5.45308 33.732 6.08781 33.9961 6.75024 33.998H18.0002V21.498H4.25024V31.498ZM8.00024 26.498C8.00222 25.8356 8.26625 25.2009 8.73466 24.7325C9.20308 24.2641 9.83781 24 10.5002 23.998H13.0002C13.6627 24 14.2974 24.2641 14.7658 24.7325C15.2342 25.2009 15.4983 25.8356 15.5002 26.498V28.998C15.4983 29.6605 15.2342 30.2952 14.7658 30.7636C14.2974 31.232 13.6627 31.4961 13.0002 31.498H10.5002C9.83781 31.4961 9.20308 31.232 8.73466 30.7636C8.26625 30.2952 8.00222 29.6605 8.00024 28.998V26.498Z"
      [attr.fill]="color"
    />
  </svg> `,
})
export class VillaIconComponent extends BaseIconComponent {}
