import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-headlight-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2575 0C1.57 0 0 6.1625 0 7.8125C0 9.855 2.35062 15.8013 9.53125 15.8013C12.9019 15.8013 14.1206 13.8431 14.1206 12.5387V3.02813C14.1206 2.99938 14.0794 0.108751 9.55062 0.0206256L9.2575 0ZM17.4219 2.63687C17.02 2.63687 16.6987 2.95812 16.6987 3.35938C16.6987 3.76125 17.02 4.0825 17.4219 4.0825H23.4175C23.8194 4.0825 24.1406 3.76125 24.1406 3.35938C24.1406 2.95812 23.8194 2.63687 23.4175 2.63687H17.4219ZM17.4219 7.16812C17.02 7.16812 16.6987 7.50812 16.6987 7.91062C16.6987 8.31187 17.02 8.63313 17.4219 8.63313H23.4175C23.8194 8.63313 24.1406 8.31187 24.1406 7.91062C24.1406 7.50812 23.8194 7.16812 23.4175 7.16812H17.4219ZM17.4219 11.7188C17.02 11.7188 16.6987 12.04 16.6987 12.4419C16.6987 12.8437 17.02 13.1644 17.4219 13.1644H23.4175C23.8194 13.1644 24.1406 12.8437 24.1406 12.4419C24.1406 12.04 23.8194 11.7188 23.4175 11.7188H17.4219Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class HeadLightIconComponent extends BaseIconComponent {}
