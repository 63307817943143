import { APP_BASE_HREF } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'lty';

  tokenValidityTimeMinutes = environment.tokenValidityTimeMinutes;

  constructor(
    router: Router,
    private cookieService: CookieService,
    @Inject(APP_BASE_HREF) private baseHref: string,
  ) {
    const languageCode = this.cookieService.get('selectedCountryCode');
    if (!languageCode) {
      this.cookieService.set('selectedCountryCode', 'fr');
    }

    if (this.baseHref === '/') {
      this.cookieService.set('selectedCountryCode', 'fr');
    } else {
      const baseHrefReplaced = this.baseHref.replace(/^\/|\/$/g, '');
      this.cookieService.set('selectedCountryCode', baseHrefReplaced);
    }

    this.clearTokenIfExpired();
  }

  clearTokenIfExpired() {
    const now = Date.now();
    const loginTime = localStorage.getItem('loginTime');
    if (loginTime) {
      const loginTimeInt = loginTime ? parseInt(loginTime, 10) : 0;
      const timeLeft = loginTimeInt + this.tokenValidityTimeMinutes * 60 * 1000;

      const diff = timeLeft - now;
      const isTimeout = diff < 0;
      if (isTimeout) {
        localStorage.removeItem('user');
        localStorage.removeItem('loginTime');
      }
    }
  }
}
