import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-male-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2268 4.59679C23.1149 4.32743 22.9009 4.11338 22.6315 4.00152C22.499 3.94503 22.3566 3.91507 22.2126 3.91333H17.8031C17.5108 3.91333 17.2304 4.02947 17.0237 4.2362C16.8169 4.44294 16.7008 4.72333 16.7008 5.01569C16.7008 5.30806 16.8169 5.58845 17.0237 5.79518C17.2304 6.00191 17.5108 6.11805 17.8031 6.11805H19.5559L16.9102 8.76372C15.3481 7.59461 13.4011 7.06068 11.4611 7.26942C9.52109 7.47817 7.73221 8.41408 6.45454 9.88876C5.17688 11.3634 4.5053 13.2674 4.575 15.2173C4.6447 17.1673 5.4505 19.0184 6.83019 20.3981C8.20989 21.7778 10.061 22.5836 12.011 22.6533C13.9609 22.723 15.8648 22.0514 17.3395 20.7737C18.8142 19.4961 19.7501 17.7072 19.9589 15.7672C20.1676 13.8272 19.6337 11.8802 18.4646 10.3181L21.1102 7.67239V9.42514C21.1102 9.71751 21.2264 9.9979 21.4331 10.2046C21.6398 10.4114 21.9202 10.5275 22.2126 10.5275C22.505 10.5275 22.7853 10.4114 22.9921 10.2046C23.1988 9.9979 23.315 9.71751 23.315 9.42514V5.01569C23.3132 4.87164 23.2833 4.72932 23.2268 4.59679ZM16.1496 18.7952C15.3832 19.5771 14.4014 20.113 13.3292 20.3346C12.257 20.5563 11.1432 20.4536 10.1296 20.0397C9.11602 19.6258 8.24867 18.9194 7.63814 18.0106C7.02762 17.1018 6.70157 16.0318 6.70157 14.937C6.70157 13.8421 7.02762 12.7721 7.63814 11.8633C8.24867 10.9545 9.11602 10.2481 10.1296 9.83422C11.1432 9.42031 12.257 9.31763 13.3292 9.53927C14.4014 9.76091 15.3832 10.2968 16.1496 11.0787C17.1595 12.109 17.7252 13.4942 17.7252 14.937C17.7252 16.3797 17.1595 17.7649 16.1496 18.7952Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class MaleIconComponent extends BaseIconComponent {}
