export enum LegalProtectionDetailsEnum {
  CONSUMER_LAW = 'CONSUMER_LAW',
  PROPERTY_LAW = 'PROPERTY_LAW',
  LABOR_LAW = 'LABOR_LAW',
  GENDER_EQUALITY = 'GENDER_EQUALITY',
  LANDLORD_LITIGATION = 'LANDLORD_LITIGATION',
  ASSOCIATION_LAW = 'ASSOCIATION_LAW',
  INSURANCE_LAW = 'INSURANCE_LAW',
  PATIENT_LAW = 'PATIENT_LAW',
  CIVIL_RESPONSIBILITY_LAW = 'CIVIL_RESPONSIBILITY_LAW',
  VICTIM_ASSISTANCE = 'VICTIM_ASSISTANCE',
  MATRIMONIAL_LAW = 'MATRIMONIAL_LAW',
  INHERITANCE_LAW = 'INHERITANCE_LAW',
  LAND_LAW = 'LAND_LAW',
  VEHICLE_LAW = 'VEHICLE_LAW',
  CRIMINAL_LAW = 'CRIMINAL_LAW',
  TAX_LAW = 'TAX_LAW',
  CYBER_RISK = 'CYBER_RISK',
  SCHOOL_LAW = 'SCHOOL_LAW',
  CHILDREN_LAW = 'CHILDREN_LAW',
  DEATH_INSURANCE = 'DEATH_INSURANCE',
  ANIMAL_LAW = 'ANIMAL_LAW',
  DEPTH_INSURANCE = 'DEPTH_INSURANCE',
  CONSTRUCTION_CONTRACT = 'CONSTRUCTION_CONTRACT',
  POPERTY_LAW = 'POPERTY_LAW',
  DATA_PROTECTION = 'DATA_PROTECTION',
  INTELECTUAL_PROPERTY = 'INTELECTUAL_PROPERTY',
  INDEPENDANT_ACTIVITY = 'INDEPENDANT_ACTIVITY',
  WEB_LAW = 'WEB_LAW',
  NATIONALITY_LAW = 'NATIONALITY_LAW',
  PERSONALITY_LAW = 'PERSONALITY_LAW',
  NEIGHBORHOOD_LAW = 'NEIGHBORHOOD_LAW',
  CONTRACTS_LAW = 'CONTRACTS_LAW',
  CUSTOMS_LAW = 'CUSTOMS_LAW',
  LANDLORD_LAW = 'LANDLORD_LAW',
  TRAVEL_LAW = 'TRAVEL_LAW',
  VEHICLE_TAX = 'VEHICLE_TAX',
  LICENCE_DRIVE_WITHDRAWAL = 'LICENCE_DRIVE_WITHDRAWAL',
  WORK_CONTRACT = 'WORK_CONTRACT',
  EXPROPRIATION_RIGHT = 'EXPROPRIATION_RIGHT',
  FLOOR_PROPERTY_RIGHT = 'FLOOR_PROPERTY_RIGHT',
  PUBLIC_CONSTRUCTION_RIGHT = 'PUBLIC_CONSTRUCTION_RIGHT',
  MANDATE = 'MANDATE',
  SERVITUDE = 'SERVITUDE',
  DAMAGES = 'DAMAGES',
  RIGHT_PROTECTION = 'RIGHT_PROTECTION',
  COPYRIGHT = 'COPYRIGHT',
  HARASSMENT = 'HARASSMENT',
  FOREIGN_TRAVEL = 'FOREIGN_TRAVEL',
  VEHICLE_CONTRACT = 'VEHICLE_CONTRACT',
  GARAGE_RENT = 'GARAGE_RENT',
}
