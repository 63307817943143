export enum CentralisationInsurersEnum {
  AGRISANO = 'AGRISANO',
  ALLIANZ = 'ALLIANZ',
  ANIMALIA = 'ANIMALIA',
  APPENZELLER = 'APPENZELLER',
  AQUILANA = 'AQUILANA',
  ASSISTA = 'ASSISTA',
  ASSURA = 'ASSURA',
  ATUPRI = 'ATUPRI',
  AXA = 'AXA',
  AXA_ARAG = 'AXA_ARAG',
  BALOISE = 'BALOISE',
  CAP = 'CAP',
  CONCORDIA = 'CONCORDIA',
  COOP = 'COOP',
  CSS = 'CSS',
  DEXTRA = 'DEXTRA',
  EGK = 'EGK',
  ELVIA = 'ELVIA',
  EMILIA = 'EMILIA',
  EMMENTAL = 'EMMENTAL',
  EPONA = 'EPONA',
  EUROP_ASSISTANCE = 'EUROP_ASSISTANCE',
  FIRSTCAUTION = 'FIRSTCAUTION',
  FORTUNA = 'FORTUNA',
  GENERALI = 'GENERALI',
  GENOSSENSCHAFT = 'GENOSSENSCHAFT',
  GENOSSENSCHAFT_SLKK = 'GENOSSENSCHAFT_SLKK',
  GROUPE_MUTUEL = 'GROUPE_MUTUEL',
  GVB = 'GVB',
  HELSANA = 'HELSANA',
  HELVETIA = 'HELVETIA',
  IMPERIO = 'IMPERIO',
  INFRASSURE = 'INFRASSURE',
  INNOVA = 'INNOVA',
  KPT = 'KPT',
  K_TIPP = 'K_TIPP',
  LA_MOBILIERE = 'LA_MOBILIERE',
  LIMMAT = 'LIMMAT',
  LUZEMER_HINTERLAND = 'LUZEMER_HINTERLAND',
  OKK = 'OKK',
  ORION = 'ORION',
  PALLADIO = 'PALLADIO',
  PAX = 'PAX',
  POST_FINANCES = 'POST_FINANCES',
  PROTEKTA = 'PROTEKTA',
  RETRAITES_POPULAIRES = 'RETRAITES_POPULAIRES',
  RHENUSANA = 'RHENUSANA',
  SANITAS = 'SANITAS',
  SAVANALS = 'SAVANALS',
  SLLK = 'SLLK',
  STEFFISBURG = 'STEFFISBURG',
  SWISSCAUTION = 'SWISSCAUTION',
  SCHWEIZERISCHE_HAGEL = 'SCHWEIZERISCHE_HAGEL',
  SIMPEGO = 'SIMPEGO',
  SMARTCAUTION = 'SMARTCAUTION',
  SMILE = 'SMILE',
  SODALIS = 'SODALIS',
  STIFTUNG_KRANKENKASSE_WADENSWIL = 'STIFTUNG_KRANKENKASSE_WADENSWIL',
  SUMISWALDER = 'SUMISWALDER',
  SWICA = 'SWICA',
  SWISS_LIFE = 'SWISS_LIFE',
  SWISSGARANTA = 'SWISSGARANTA',
  SYMPANY = 'SYMPANY',
  TAS_TCS = 'TAS_TCS',
  TSM = 'TSM',
  VAUDOISE = 'VAUDOISE',
  VERSICHERUNG_DER_SCHWEIZER_ARZTE_GENOSSENCHAFT = 'VERSICHERUNG_DER_SCHWEIZER_ARZTE_GENOSSENCHAFT',
  VISANA = 'VISANA',
  VITA_SURSELVA = 'VITA_SURSELVA',
  VZ = 'VZ',
  YOUPLUS = 'YOUPLUS',
  ZURICH_ASSURANCE = 'ZURICH_ASSURANCE',
  WADENSWILL_KK = 'WADENSWILL_KK',
  WAU_MIAU = 'WAU_MIAU',
}
