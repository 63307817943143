import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-wallet-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 6.1052H2.25V5.3552L18.75 4.0352V5.3552H21V3.1052C21 1.4552 19.6635 0.297204 18.0315 0.529704L2.97 2.6807C1.3365 2.9147 0 4.4552 0 6.1052V21.1052C0 21.9009 0.31607 22.6639 0.87868 23.2265C1.44129 23.7891 2.20435 24.1052 3 24.1052H21C21.7956 24.1052 22.5587 23.7891 23.1213 23.2265C23.6839 22.6639 24 21.9009 24 21.1052V9.1052C24 8.30956 23.6839 7.54649 23.1213 6.98388C22.5587 6.42127 21.7956 6.1052 21 6.1052ZM18.75 16.6142C18.4544 16.6141 18.1618 16.5558 17.8887 16.4426C17.6157 16.3294 17.3676 16.1635 17.1587 15.9544C16.9498 15.7454 16.7841 15.4972 16.671 15.2241C16.558 14.951 16.4999 14.6583 16.5 14.3627C16.5001 14.0671 16.5584 13.7745 16.6716 13.5014C16.7848 13.2284 16.9507 12.9803 17.1598 12.7714C17.3688 12.5625 17.617 12.3968 17.8901 12.2837C18.1632 12.1707 18.4559 12.1126 18.7515 12.1127C19.3484 12.1129 19.9208 12.3502 20.3428 12.7725C20.7648 13.1947 21.0017 13.7673 21.0015 14.3642C21.0013 14.9611 20.764 15.5335 20.3417 15.9555C19.9195 16.3775 19.3469 16.6144 18.75 16.6142Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class WalletIconComponent extends BaseIconComponent {}
