import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-body-massage-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6412 13.2449C15.1184 13.2442 15.5758 13.0543 15.9132 12.7169C16.2506 12.3795 16.4405 11.9221 16.4412 11.4449C16.3424 9.05614 12.9384 9.05668 12.84 11.4449C12.8407 11.9223 13.0307 12.3799 13.3684 12.7174C13.7061 13.0548 14.1638 13.2445 14.6412 13.2449ZM14.6412 10.1833C14.9752 10.1839 15.2954 10.3168 15.5316 10.553C15.7677 10.7892 15.9007 11.1093 15.9012 11.4433C15.842 13.1115 13.4388 13.1112 13.38 11.4433C13.3805 11.1091 13.5136 10.7887 13.7501 10.5525C13.9865 10.3163 14.307 10.1835 14.6412 10.1833ZM9.40377 4.62922C9.4031 4.15204 9.21325 3.69459 8.87582 3.35717C8.5384 3.01975 8.08095 2.82989 7.60377 2.82922C5.21517 2.92804 5.21589 6.33184 7.60377 6.4303C8.08114 6.42964 8.53875 6.23963 8.8762 5.90198C9.21366 5.56432 9.40339 5.1066 9.40377 4.62922ZM6.34251 4.62922C6.34303 4.29521 6.47595 3.97503 6.71213 3.73885C6.94831 3.50267 7.2685 3.36975 7.60251 3.36922C9.27111 3.42826 9.27075 5.83144 7.60251 5.8903C7.26831 5.88978 6.94796 5.75671 6.71175 5.5203C6.47554 5.28388 6.34275 4.96342 6.34251 4.62922ZM10.1428 10.805C10.1685 10.7806 10.1892 10.7513 10.2035 10.7189C10.2179 10.6865 10.2257 10.6515 10.2266 10.6161C10.2275 10.5806 10.2214 10.5453 10.2086 10.5123C10.1958 10.4792 10.1767 10.4489 10.1522 10.4232L6.72663 6.82324C6.52266 6.61276 6.27118 6.45427 5.99329 6.36107C5.7154 6.26788 5.41921 6.24269 5.12957 6.28764C4.83994 6.33259 4.56531 6.44635 4.32873 6.61938C4.09216 6.79241 3.90053 7.01966 3.76995 7.28206L2.10729 10.7001C2.07595 10.7645 2.07149 10.8388 2.0949 10.9065C2.11831 10.9743 2.16767 11.0299 2.23212 11.0613C2.29657 11.0926 2.37082 11.097 2.43856 11.0736C2.50629 11.0502 2.56195 11.0009 2.59329 10.9364L4.25559 7.5193C4.3459 7.33309 4.47998 7.17153 4.64637 7.04846C4.81276 6.92538 5.00649 6.84446 5.21098 6.81261C5.41547 6.78077 5.62464 6.79895 5.82057 6.86559C6.0165 6.93224 6.19337 7.04537 6.33603 7.1953L9.76179 10.7953C9.81109 10.847 9.87888 10.8771 9.95031 10.8789C10.0217 10.8807 10.091 10.8542 10.1428 10.805Z"
        [attr.fill]="color"
      />
      <path
        d="M6.26117 9.13997C6.21056 9.19061 6.18212 9.25927 6.18212 9.33086C6.18212 9.40246 6.21056 9.47112 6.26117 9.52175L7.74833 11.0089C7.77324 11.0347 7.80303 11.0553 7.83598 11.0694C7.86892 11.0836 7.90435 11.091 7.9402 11.0913C7.97605 11.0916 8.0116 11.0848 8.04478 11.0712C8.07796 11.0577 8.10811 11.0376 8.13346 11.0123C8.15881 10.9869 8.17886 10.9568 8.19244 10.9236C8.20601 10.8904 8.21284 10.8548 8.21253 10.819C8.21222 10.7831 8.20477 10.7477 8.19062 10.7148C8.17647 10.6818 8.1559 10.652 8.13011 10.6271L6.64223 9.13997C6.59163 9.08958 6.52312 9.06128 6.4517 9.06128C6.38029 9.06128 6.31178 9.08958 6.26117 9.13997ZM16.0271 13.7716H13.3742L11.35 11.748C11.2418 11.6393 11.1131 11.553 10.9714 11.4943C10.8296 11.4356 10.6777 11.4056 10.5243 11.406H1.26023C1.18863 11.406 1.11995 11.4344 1.06932 11.4851C1.01868 11.5357 0.990234 11.6044 0.990234 11.676C0.990234 11.7476 1.01868 11.8163 1.06932 11.8669C1.11995 11.9175 1.18863 11.946 1.26023 11.946H10.5243C10.6068 11.9458 10.6884 11.962 10.7646 11.9935C10.8408 12.025 10.91 12.0714 10.9682 12.1298L13.0706 14.2331C13.0956 14.2582 13.1254 14.2781 13.1582 14.2917C13.191 14.3053 13.2261 14.3123 13.2616 14.3123H16.0271C16.143 14.3146 16.2535 14.3623 16.3346 14.4452C16.4158 14.528 16.4613 14.6394 16.4613 14.7553C16.4613 14.8713 16.4158 14.9827 16.3346 15.0655C16.2535 15.1484 16.143 15.1961 16.0271 15.1984H12.7124L11.5825 14.0686C11.4888 13.9743 11.3773 13.8997 11.2545 13.8488C11.1317 13.7979 11.0001 13.7719 10.8672 13.7723H8.61179C8.54019 13.7723 8.47151 13.8007 8.42088 13.8514C8.37024 13.902 8.34179 13.9707 8.34179 14.0423C8.34179 14.1139 8.37024 14.1826 8.42088 14.2332C8.47151 14.2838 8.54019 14.3123 8.61179 14.3123H10.8672C10.9292 14.3121 10.9905 14.3242 11.0478 14.3479C11.105 14.3716 11.157 14.4064 11.2007 14.4503L12.4096 15.6594C12.4605 15.7094 12.5289 15.7375 12.6002 15.7377H16.0271C17.324 15.6974 17.3281 13.8131 16.0271 13.7716Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class BodyMassageIconComponent extends BaseIconComponent {}
