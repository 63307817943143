export enum EntityType {
  OWNER = 'OWNER',
  ARCHITECT = 'ARCHITECT',
  ENTERPRISE = 'ENTERPRISE',
}

export const getEntityTypeLabel = (value: EntityType): string => {
  switch (value) {
    case EntityType.OWNER:
      return $localize`Propriétaire`;
    case EntityType.ARCHITECT:
      return $localize`Architecte`;
    case EntityType.ENTERPRISE:
      return $localize`Entreprise générale`;
    default:
      return '';
  }
};
