import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-repair-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6802 8.03906L17.8332 6.7723L16.5665 2.9253L19.4407 0.0400566C18.4409 -0.0776624 17.4275 0.0651337 16.4992 0.454582C15.5708 0.84403 14.7588 1.4669 14.1422 2.26272C13.5256 3.05854 13.1252 4.00028 12.9799 4.9965C12.8346 5.99272 12.9493 7.00959 13.313 7.94838L11.0653 10.1989L13.2635 12.3972L13.673 11.9905C13.8397 11.8308 14.0617 11.7416 14.2926 11.7416C14.5235 11.7416 14.7455 11.8308 14.9122 11.9905L15.4206 12.4988L16.6406 11.2788C17.5793 11.6436 18.5963 11.7596 19.593 11.6155C20.5897 11.4714 21.5322 11.0721 22.3291 10.4563C23.126 9.84056 23.7502 9.02926 24.141 8.10114C24.5319 7.17303 24.6762 6.15964 24.56 5.15931L21.6802 8.03906ZM10.9608 14.7163L11.3703 14.3097L9.17198 12.1114L2.27487 18.9893C1.8325 19.4316 1.58398 20.0316 1.58398 20.6572C1.58398 21.2828 1.8325 21.8828 2.27487 22.3251C2.71724 22.7675 3.31722 23.016 3.94282 23.016C4.56842 23.016 5.1684 22.7675 5.61076 22.3251L11.4719 16.464L10.9608 15.9556C10.7972 15.7909 10.7054 15.5682 10.7054 15.336C10.7054 15.1038 10.7972 14.8811 10.9608 14.7163ZM5.02822 21.0969C4.87827 21.2466 4.68732 21.3484 4.47949 21.3896C4.27165 21.4309 4.05627 21.4095 3.86055 21.3284C3.66483 21.2472 3.49757 21.1098 3.37989 20.9336C3.26221 20.7575 3.1994 20.5503 3.1994 20.3384C3.1994 20.1266 3.26221 19.9194 3.37989 19.7432C3.49757 19.5671 3.66483 19.4297 3.86055 19.3485C4.05627 19.2674 4.27165 19.246 4.47949 19.2872C4.68732 19.3285 4.87827 19.4303 5.02822 19.58C5.22891 19.7814 5.34159 20.0541 5.34159 20.3384C5.34159 20.6228 5.22891 20.8955 5.02822 21.0969Z"
        [attr.fill]="color"
      />
      <path
        d="M14.2888 12.0287C14.2102 12.0287 14.1323 12.0442 14.0597 12.0744C13.9871 12.1046 13.9211 12.1488 13.8657 12.2046L11.1453 14.925C11.033 15.0378 10.97 15.1904 10.97 15.3495C10.97 15.5087 11.033 15.6613 11.1453 15.7741L11.6536 16.2852L17.949 22.5778C18.1276 22.7564 18.3396 22.8981 18.573 22.9948C18.8064 23.0914 19.0565 23.1412 19.3092 23.1412C19.5618 23.1412 19.8119 23.0914 20.0453 22.9948C20.2787 22.8981 20.4907 22.7564 20.6694 22.5778L21.5184 21.7287C21.6971 21.5501 21.8388 21.338 21.9354 21.1046C22.0321 20.8712 22.0819 20.6211 22.0819 20.3685C22.0819 20.1159 22.0321 19.8657 21.9354 19.6323C21.8388 19.399 21.6971 19.1869 21.5184 19.0083L15.2259 12.7157L14.7148 12.2046C14.6589 12.1486 14.5925 12.1043 14.5194 12.0741C14.4463 12.0439 14.3679 12.0285 14.2888 12.0287ZM13.077 12.6003L10.8788 10.402L5.65783 5.1811C5.6413 5.16624 5.62897 5.14728 5.62211 5.12614L5.03407 3.26859L1.57177 1.07031L0 2.59812L2.19828 6.06316L4.05858 6.6512C4.07901 6.65821 4.09773 6.66945 4.11354 6.68418L9.33446 11.9051L11.5327 14.1034L13.077 12.6003Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class RepairIconComponent extends BaseIconComponent {}
