export const CANCELLATION_BASE_URL = 'resiliation';

export const CANCELLATION_STEPS = {
  BASE: CANCELLATION_BASE_URL,
  ACCOUNT: { step: [1, 1], url: 'compte' },
  LOGIN: { step: [1, 1], url: 'connexion' },
  REGISTER: { step: [1, 1], url: 'inscription' },
  GENERAL_INFO: { step: [1, 1], url: 'informations-generales' },
  SENDER_ADDRESS: { step: [2, 1], url: 'adresse-expediteur' },
  INSURANCE_CHOICE: { step: [2, 1], url: 'assurance' },
  RECEIVER_ADDRESS: { step: [2, 1], url: 'adresse-destinataire' },
  POLICY: { step: [3, 1], url: 'police' },
  CANCELLATION_DATE: { step: [3, 1], url: 'date-resiliation' },
  LETTER_DATE: { step: [3, 1], url: 'date-courier' },
  SIGNATURE: { step: [4, 1], url: 'signature' },
  RECEIVER: { step: [5, 1], url: 'destinataire' },
  RECAP: { step: [5, 1], url: 'resume' },
  END: 'fin',
};
