import { SelectElementMapper } from '../_services/types/commons.type';
import { ClaimType } from '../enums/claim/claim-type.enum';
import {
  ClaimLegalDomain,
  getClaimLegalDomainLabel,
  getLegalDelayLabel,
  LegalDelay,
} from '../enums/claim/legal-claim.enums';
import { DamagedGood, getDamagedGoodLabel } from '../enums/claim/valuable-object.enums';
import {
  getVehicleDamageTargetLabel,
  VehicleDamageTarget,
} from '../enums/claim/vehicle-claim.enums';
import { ANIMAL_INSURANCE_BASE } from '../insurance-flows/animal/constants';
import { BUILDING_INSURANCE_BASE } from '../insurance-flows/building/constants';
import { CONSTRUCTION_INSURANCE_BASE_URL } from '../insurance-flows/construction/constants';
import { HOUSEHOLD_INSURANCE_BASE_URL } from '../insurance-flows/household/household-constants';
import { LEGAL_PROTECTION_BASE_URL } from '../insurance-flows/legal-protection/legal-protection-constants';
import { TRAVEL_INSURANCE_BASE_URL } from '../insurance-flows/travel/travel-constants';
import { VALUABLE_OBJECTS_BASE } from '../insurance-flows/valuable-objects/constants';
import { VEHICLE_BASE_URL } from '../insurance-flows/vehicle/vehicle-constants';
import { CameraIconComponent } from '../ui-kit/icons/camera-icon.component';
import { EarIconComponent } from '../ui-kit/icons/ear-icon.component';
import { FurcoatIconComponent } from '../ui-kit/icons/furcoat-icon.component';
import { GunIconComponent } from '../ui-kit/icons/gun-icon.component';
import { JewelryIconComponent } from '../ui-kit/icons/jewelry-icon.component';
import { PaintingIconComponent } from '../ui-kit/icons/painting-icon.component';
import { PianoIconComponent } from '../ui-kit/icons/piano-icon.component';

export const CLAIM_BASE_URL = 'sinistre';
export const CLAIM_FORM_BASE_URL = 'formulaire';

export const CLAIM_FORM_FULL_URL = `${CLAIM_BASE_URL}/${CLAIM_FORM_BASE_URL}`;

export const CLAIM_STEPS = {
  START: { url: 'recap' },
  ACCOUNT: { step: [1, 1], url: 'compte' },
  LOGIN: { step: [1, 1], url: 'connexion' },
  REGISTER: { step: [1, 1], url: 'inscription' },
  DOCUMENT_UPLOAD: { step: [1, 1], url: 'documents' },
  ID_UPLOAD: { step: [1, 1], url: 'identite' },
  INSURANCE_CHOICE: { step: [2, 1], url: 'choix-assurance' },
  END: { url: 'fin' },
};

export const CLAIM_ANIMAL_STEPS = {
  CAUSE: { step: [3, 1], url: 'cause' },
  SITUATION: { step: [3, 1], url: 'situation' },
  REIMBURSEMENT_INFO: { step: [3, 1], url: 'remboursement' },
  ADDITIONAL_INFO: { step: [3, 1], url: 'informations-complementaires' },
  SUMMARY: { step: [4, 1], url: 'resume' },
};

export const CLAIM_VEHICLE_STEPS = {
  CAUSE: { step: [3, 1], url: 'cause' },
  OATH: { step: [3, 1], url: 'serment' },
  CIRCUMSTANCES: { step: [3, 1], url: 'circonstances' },
  DAMAGE_TARGET: { step: [3, 1], url: 'dommage' },
  GARAGE_VISIT: { step: [3, 1], url: 'garage' },
  CLAIM_DETAILS: { step: [3, 1], url: 'details' },
  ADDITIONAL_INFO: { step: [3, 1], url: 'informations-complementaires' },
  PROOF: { step: [3, 1], url: 'preuves' },
  SUMMARY: { step: [4, 1], url: 'resume' },
};

export const CLAIM_BUILDING_STEPS = {
  CAUSE: { step: [3, 1], url: 'cause' },
  OATH: { step: [3, 1], url: 'serment' },
  CIRCUMSTANCES: { step: [3, 1], url: 'circonstances' },
  DAMAGE_TARGET: { step: [3, 1], url: 'dommage' },
  CLAIM_DETAILS: { step: [3, 1], url: 'details' },
  ADDITIONAL_INFO: { step: [3, 1], url: 'informations-complementaires' },
  PROOF: { step: [3, 1], url: 'preuves' },
  SUMMARY: { step: [4, 1], url: 'resume' },
};

export const CLAIM_HOUSEHOLD_STEPS = {
  CAUSE: { step: [3, 1], url: 'cause' },
  OATH: { step: [3, 1], url: 'serment' },
  CIRCUMSTANCES: { step: [3, 1], url: 'circonstances' },
  DAMAGE_TARGET: { step: [3, 1], url: 'dommage' },
  CLAIM_DETAILS: { step: [3, 1], url: 'details' },
  ADDITIONAL_INFO: { step: [3, 1], url: 'informations-complementaires' },
  PROOF: { step: [3, 1], url: 'preuves' },
  SUMMARY: { step: [4, 1], url: 'resume' },
};

export const CLAIM_VALUABLE_OBJECTS_STEPS = {
  CAUSE: { step: [3, 1], url: 'cause' },
  OATH: { step: [3, 1], url: 'serment' },
  CIRCUMSTANCES: { step: [3, 1], url: 'circonstances' },
  DAMAGE_TARGET: { step: [3, 1], url: 'dommage' },
  CLAIM_DETAILS: { step: [3, 1], url: 'details' },
  ADDITIONAL_INFO: { step: [3, 1], url: 'informations-complementaires' },
  PROOF: { step: [3, 1], url: 'preuves' },
  SUMMARY: { step: [4, 1], url: 'resume' },
};

export const CLAIM_CONSTRUCTION_STEPS = {
  CAUSE: { step: [3, 1], url: 'cause' },
  OATH: { step: [3, 1], url: 'serment' },
  CIRCUMSTANCES: { step: [3, 1], url: 'circonstances' },
  DAMAGE_TARGET: { step: [3, 1], url: 'dommage' },
  CLAIM_DETAILS: { step: [3, 1], url: 'details' },
  ADDITIONAL_INFO: { step: [3, 1], url: 'informations-complementaires' },
  PROOF: { step: [3, 1], url: 'preuves' },
  SUMMARY: { step: [4, 1], url: 'resume' },
};

export const CLAIM_TRAVEL_STEPS = {
  OCCURENCE: { step: [3, 1], url: 'moment' },
  OATH: { step: [3, 1], url: 'serment' },
  CIRCUMSTANCES: { step: [3, 1], url: 'circonstances' },
  DAMAGE_TARGET: { step: [3, 1], url: 'dommage' },
  CLAIM_DETAILS: { step: [3, 1], url: 'details' },
  ADDITIONAL_INFO: { step: [3, 1], url: 'informations-complementaires' },
  PROOF: { step: [3, 1], url: 'preuves' },
  SUMMARY: { step: [4, 1], url: 'resume' },
};

export const CLAIM_LEGAL_STEPS = {
  CASE: { step: [3, 1], url: 'cas' },
  OATH: { step: [3, 1], url: 'serment' },
  DOMAIN: { step: [3, 1], url: 'domaine' },
  DISPUTE_DATE: { step: [3, 1], url: 'date' },
  FACTS: { step: [3, 1], url: 'faits' },
  ADDITIONAL_INFO: { step: [3, 1], url: 'informations-complementaires' },
  PROOF: { step: [3, 1], url: 'preuves' },
  SUMMARY: { step: [4, 1], url: 'resume' },
};

export const CLAIM_LEGAL_DOMAINS: SelectElementMapper<ClaimLegalDomain> = {
  [ClaimLegalDomain.WORK]: {
    value: ClaimLegalDomain.WORK,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.WORK),
  },
  [ClaimLegalDomain.HOME]: {
    value: ClaimLegalDomain.HOME,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.HOME),
  },
  [ClaimLegalDomain.CONTRACT]: {
    value: ClaimLegalDomain.CONTRACT,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.CONTRACT),
  },
  [ClaimLegalDomain.INSURANCE]: {
    value: ClaimLegalDomain.INSURANCE,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.INSURANCE),
  },
  [ClaimLegalDomain.TRAFFIC]: {
    value: ClaimLegalDomain.TRAFFIC,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.TRAFFIC),
  },
  [ClaimLegalDomain.INTERNET]: {
    value: ClaimLegalDomain.INTERNET,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.INTERNET),
  },
  [ClaimLegalDomain.OTHER]: {
    value: ClaimLegalDomain.OTHER,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.OTHER),
  },
};

export const CLAIM_VEHICLE_DAMAGE_TARGETS: SelectElementMapper<VehicleDamageTarget> = {
  [VehicleDamageTarget.PERSON]: {
    value: VehicleDamageTarget.PERSON,
    label: getVehicleDamageTargetLabel(VehicleDamageTarget.PERSON),
  },
  [VehicleDamageTarget.MY_VEHICLE]: {
    value: VehicleDamageTarget.MY_VEHICLE,
    label: getVehicleDamageTargetLabel(VehicleDamageTarget.MY_VEHICLE),
  },
  [VehicleDamageTarget.OTHER_VEHICLE]: {
    value: VehicleDamageTarget.OTHER_VEHICLE,
    label: getVehicleDamageTargetLabel(VehicleDamageTarget.OTHER_VEHICLE),
  },
  [VehicleDamageTarget.OTHER]: {
    value: VehicleDamageTarget.OTHER,
    label: getVehicleDamageTargetLabel(VehicleDamageTarget.OTHER),
  },
};

export const CLAIM_VALUABLE_OBJECTS_DAMAGED_GOODS: SelectElementMapper<DamagedGood> = {
  [DamagedGood.JEWELRY]: {
    value: DamagedGood.JEWELRY,
    label: getDamagedGoodLabel(DamagedGood.JEWELRY),
    iconComponent: JewelryIconComponent,
  },
  [DamagedGood.HEARING_AID]: {
    value: DamagedGood.HEARING_AID,
    label: getDamagedGoodLabel(DamagedGood.HEARING_AID),
    iconComponent: EarIconComponent,
  },
  [DamagedGood.WEAPON]: {
    value: DamagedGood.WEAPON,
    label: getDamagedGoodLabel(DamagedGood.WEAPON),
    iconComponent: GunIconComponent,
  },
  [DamagedGood.PHOTOGRAHIC_EQUIPMENT]: {
    value: DamagedGood.PHOTOGRAHIC_EQUIPMENT,
    label: getDamagedGoodLabel(DamagedGood.PHOTOGRAHIC_EQUIPMENT),
    iconComponent: CameraIconComponent,
  },
  [DamagedGood.CLOTHES]: {
    value: DamagedGood.CLOTHES,
    label: getDamagedGoodLabel(DamagedGood.CLOTHES),
    iconComponent: FurcoatIconComponent,
  },
  [DamagedGood.PAINTING]: {
    value: DamagedGood.PAINTING,
    label: getDamagedGoodLabel(DamagedGood.PAINTING),
    iconComponent: PaintingIconComponent,
  },
  [DamagedGood.MUSIC_INSTRUMENT]: {
    value: DamagedGood.MUSIC_INSTRUMENT,
    label: getDamagedGoodLabel(DamagedGood.MUSIC_INSTRUMENT),
    iconComponent: PianoIconComponent,
  },
  [DamagedGood.OTHERS]: {
    value: DamagedGood.OTHERS,
    label: getDamagedGoodLabel(DamagedGood.OTHERS),
  },
};

export const DELAYS: SelectElementMapper<LegalDelay> = {
  [LegalDelay.YES]: {
    value: LegalDelay.YES,
    label: getLegalDelayLabel(LegalDelay.YES),
  },
  [LegalDelay.NO]: {
    value: LegalDelay.NO,
    label: getLegalDelayLabel(LegalDelay.NO),
  },
  [LegalDelay.DO_NOT_KNOW]: {
    value: LegalDelay.DO_NOT_KNOW,
    label: getLegalDelayLabel(LegalDelay.DO_NOT_KNOW),
  },
};

export const INSURANCE_ROUTES_MAPPING: { [key in ClaimType]: string } = {
  [ClaimType.ANIMAL]: ANIMAL_INSURANCE_BASE,
  [ClaimType.MOTOR_VEHICLE]: VEHICLE_BASE_URL,
  [ClaimType.BUILDING]: BUILDING_INSURANCE_BASE,
  [ClaimType.RC_HOUSEHOLD]: HOUSEHOLD_INSURANCE_BASE_URL,
  [ClaimType.VALUABLE_OBJECTS]: VALUABLE_OBJECTS_BASE,
  [ClaimType.CONSTRUCTION]: CONSTRUCTION_INSURANCE_BASE_URL,
  [ClaimType.TRAVEL]: TRAVEL_INSURANCE_BASE_URL,
  [ClaimType.LEGAL_PROTECTION]: LEGAL_PROTECTION_BASE_URL,
};
