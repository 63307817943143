export enum Animal {
  CAT = 'CAT',
  DOG = 'DOG',
}

export const getAnimalLabel = (value: Animal): string => {
  switch (value) {
    case Animal.CAT:
      return $localize`Chat`;
    case Animal.DOG:
      return $localize`Chien`;
    default:
      return '';
  }
};
