export enum ConstructionType {
  NEW = 'NEW',
  EXTENSION = 'EXTENSION',
  TRANSFORMATION = 'TRANSFORMATION',
  RENOVATION = 'RENOVATION',
  OTHER = 'OTHER',
}

export const getConstructionTypeEnumLabel = (value: ConstructionType): string => {
  switch (value) {
    case ConstructionType.NEW:
      return $localize`Nouvelle construction`;
    case ConstructionType.EXTENSION:
      return $localize`Agrandissement`;
    case ConstructionType.TRANSFORMATION:
      return $localize`Transformation`;
    case ConstructionType.RENOVATION:
      return $localize`Rénovation`;
    case ConstructionType.OTHER:
      return $localize`Autres`;
    default:
      return '';
  }
};
