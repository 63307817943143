export enum VehicleDamageTarget {
  PERSON = 'PERSON',
  MY_VEHICLE = 'MY_VEHICLE',
  OTHER_VEHICLE = 'OTHER_VEHICLE',
  OTHER = 'OTHER',
}

export const getVehicleDamageTargetLabel = (target: VehicleDamageTarget): string => {
  switch (target) {
    case VehicleDamageTarget.PERSON:
      return $localize`Une personne (blessée, etc...)`;
    case VehicleDamageTarget.MY_VEHICLE:
      return $localize`Mon véhicule`;
    case VehicleDamageTarget.OTHER_VEHICLE:
      return $localize`Autre véhicule`;
    case VehicleDamageTarget.OTHER:
      return $localize`Autre chose`;
    default:
      return '';
  }
};
