import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-minimalist-house-icon',
  template: `<svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2491 29.2483V21.7481C20.2491 21.4166 20.1174 21.0986 19.883 20.8642C19.6485 20.6298 19.3306 20.4981 18.9991 20.4981H13.9991C13.6676 20.4981 13.3496 20.6298 13.1152 20.8642C12.8808 21.0986 12.7491 21.4166 12.7491 21.7481V29.2483C12.7491 29.5798 12.6174 29.8977 12.383 30.1321C12.1486 30.3665 11.8307 30.4982 11.4992 30.4983L4.00016 30.4992C3.83599 30.4993 3.67343 30.467 3.52175 30.4041C3.37008 30.3413 3.23226 30.2493 3.11617 30.1332C3.00008 30.0171 2.90799 29.8793 2.84517 29.7276C2.78234 29.576 2.75 29.4134 2.75 29.2492V14.8023C2.75 14.6282 2.78639 14.4559 2.85684 14.2967C2.92728 14.1374 3.03023 13.9946 3.15908 13.8775L15.6582 2.51265C15.8883 2.30344 16.1881 2.18751 16.4991 2.1875C16.8101 2.18749 17.1099 2.3034 17.34 2.51259L29.8409 13.8775C29.9697 13.9946 30.0727 14.1374 30.1431 14.2967C30.2136 14.456 30.25 14.6282 30.25 14.8024V29.2493C30.25 29.4134 30.2177 29.576 30.1548 29.7276C30.092 29.8793 29.9999 30.0171 29.8838 30.1332C29.7677 30.2493 29.6299 30.3413 29.4782 30.4041C29.3266 30.467 29.164 30.4993 28.9998 30.4993L21.4989 30.4983C21.1674 30.4982 20.8495 30.3665 20.6151 30.1321C20.3808 29.8977 20.2491 29.5798 20.2491 29.2483Z"
      [attr.stroke]="color"
      stroke-width="3.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>`,
})
export class MinimalistHouseIconComponent extends BaseIconComponent {}
