import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-appartment-complex2-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 33.375C20.1685 33.375 19.8505 33.5067 19.6161 33.7411C19.3817 33.9755 19.25 34.2935 19.25 34.625V38.375H21.75V34.625C21.75 34.2935 21.6183 33.9755 21.3839 33.7411C21.1495 33.5067 20.8315 33.375 20.5 33.375ZM20.5 2.125C17.1114 2.13387 13.815 3.22917 11.095 5.25H29.9075C27.1868 3.22866 23.8894 2.13334 20.5 2.125ZM8 6.5H33V7.75H8V6.5Z"
        [attr.fill]="color"
      />
      <path
        d="M11.75 38.375H18V34.625C18 33.962 18.2634 33.3261 18.7322 32.8572C19.2011 32.3884 19.837 32.125 20.5 32.125C21.163 32.125 21.7989 32.3884 22.2678 32.8572C22.7366 33.3261 23 33.962 23 34.625V38.375H29.25V9H11.75V38.375ZM24.875 10.875H27.375V13.375H24.875V10.875ZM24.875 15.875H27.375V18.375H24.875V15.875ZM24.875 20.875H27.375V23.375H24.875V20.875ZM24.875 25.875H27.375V28.375H24.875V25.875ZM24.875 30.875H27.375V36.5H24.875V30.875ZM19.25 10.875H21.75V13.375H19.25V10.875ZM19.25 15.875H21.75V18.375H19.25V15.875ZM19.25 20.875H21.75V23.375H19.25V20.875ZM19.25 25.875H21.75V28.375H19.25V25.875ZM13.625 10.875H16.125V13.375H13.625V10.875ZM13.625 15.875H16.125V18.375H13.625V15.875ZM13.625 20.875H16.125V23.375H13.625V20.875ZM13.625 25.875H16.125V28.375H13.625V25.875ZM13.625 30.875H16.125V36.5H13.625V30.875Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class AppartmentComplex2IconComponent extends BaseIconComponent {}
