import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-open-square-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 5.25H8.33333C7.44928 5.25 6.60143 5.60119 5.97631 6.22631C5.35119 6.85143 5 7.69928 5 8.58333V13.5833M35 13.5833V8.58333C35 7.69928 34.6488 6.85143 34.0237 6.22631C33.3986 5.60119 32.5507 5.25 31.6667 5.25H26.6667M26.6667 35.25H31.6667C32.5507 35.25 33.3986 34.8988 34.0237 34.2737C34.6488 33.6486 35 32.8007 35 31.9167V26.9167M5 26.9167V31.9167C5 32.8007 5.35119 33.6486 5.97631 34.2737C6.60143 34.8988 7.44928 35.25 8.33333 35.25H13.3333"
        [attr.stroke]="color"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
})
export class OpenSquareIconComponent extends BaseIconComponent {}
