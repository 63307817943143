import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CLAIM_BASE_URL } from './claims/constants';
import { ANIMAL_INSURANCE_BASE } from './insurance-flows/animal/constants';
import { BUILDING_INSURANCE_BASE } from './insurance-flows/building/constants';
import { CANCELLATION_BASE_URL } from './insurance-flows/cancellation-flow/constants';
import { CENTRALISATION_BASE_URL } from './insurance-flows/centralisation/constants';
import { CONSTRUCTION_INSURANCE_BASE_URL } from './insurance-flows/construction/constants';
import { HEALTH_INSURANCE_BASE_URL } from './insurance-flows/health/constants';
import { HOUSEHOLD_INSURANCE_BASE_URL } from './insurance-flows/household/household-constants';
import { LEGAL_PROTECTION_BASE_URL } from './insurance-flows/legal-protection/legal-protection-constants';
import { THIRD_PILLAR_INSURANCE_BASE } from './insurance-flows/third-pillar/constants';
import { TRAVEL_INSURANCE_BASE_URL } from './insurance-flows/travel/travel-constants';
import { VALUABLE_OBJECTS_BASE } from './insurance-flows/valuable-objects/constants';
import { VEHICLE_BASE_URL } from './insurance-flows/vehicle/vehicle-constants';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./dashboard-v2/dashboard-v2.module').then((m) => m.DashboardV2Module),
  },
  {
    path: HEALTH_INSURANCE_BASE_URL,
    loadChildren: () =>
      import('./insurance-flows/health/maladie.module').then((m) => m.AssuranceMaladieModule),
  },
  {
    path: TRAVEL_INSURANCE_BASE_URL,
    loadChildren: () =>
      import('./insurance-flows/travel/travel.module').then((m) => m.TravelModule),
  },
  {
    path: CENTRALISATION_BASE_URL,
    loadChildren: () =>
      import('./insurance-flows/centralisation/centralisation.module').then(
        (m) => m.CentralisationModule,
      ),
  },
  {
    path: VEHICLE_BASE_URL,
    loadChildren: () =>
      import('./insurance-flows/vehicle/vehicle.module').then((m) => m.VehicleModule),
  },
  {
    path: BUILDING_INSURANCE_BASE,
    loadChildren: () =>
      import('./insurance-flows/building/building.module').then((m) => m.BuildingModule),
  },
  {
    path: LEGAL_PROTECTION_BASE_URL,
    loadChildren: () =>
      import('./insurance-flows/legal-protection/legal-protection.module').then(
        (m) => m.LegalProtectionModule,
      ),
  },
  {
    path: HOUSEHOLD_INSURANCE_BASE_URL,
    loadChildren: () =>
      import('./insurance-flows/household/household.module').then((m) => m.HouseholdModule),
  },
  {
    path: CLAIM_BASE_URL,
    loadChildren: () => import('./claims/claims.module').then((m) => m.ClaimsModule),
  },
  {
    path: CANCELLATION_BASE_URL,
    loadChildren: () =>
      import('./insurance-flows/cancellation-flow/cancellation-flow.module').then(
        (m) => m.CancellationFlowModule,
      ),
  },
  {
    path: CONSTRUCTION_INSURANCE_BASE_URL,
    loadChildren: () =>
      import('./insurance-flows/construction/construction.module').then(
        (m) => m.ConstructionModule,
      ),
  },
  {
    path: ANIMAL_INSURANCE_BASE,
    loadChildren: () =>
      import('./insurance-flows/animal/animal.module').then((m) => m.AnimalModule),
  },
  {
    path: VALUABLE_OBJECTS_BASE,
    loadChildren: () =>
      import('./insurance-flows/valuable-objects/valuable-objects.module').then(
        (m) => m.ValuableObjectsModule,
      ),
  },
  {
    path: THIRD_PILLAR_INSURANCE_BASE,
    loadChildren: () =>
      import('./insurance-flows/third-pillar/third-pillar.module').then((m) => m.ThirdPillarModule),
  },
  {
    path: 'user',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
