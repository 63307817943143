import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-key-icon',
  template: `<svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3311 7.62571L23.6745 5.98071L25.3311 4.33571C25.5508 4.11602 25.6742 3.81806 25.6742 3.50738C25.6742 3.19669 25.5508 2.89873 25.3311 2.67904C25.1114 2.45936 24.8135 2.33594 24.5028 2.33594C24.1921 2.33594 23.8942 2.45936 23.6745 2.67904L11.3778 14.9757C10.1715 14.1799 8.71275 13.8601 7.28416 14.0784C5.85558 14.2967 4.55889 15.0376 3.64535 16.1574C2.73181 17.2772 2.26647 18.6962 2.33949 20.1395C2.41251 21.5829 3.01869 22.9477 4.04058 23.9696C5.06247 24.9915 6.42731 25.5977 7.87063 25.6707C9.31395 25.7437 10.733 25.2784 11.8528 24.3648C12.9726 23.4513 13.7134 22.1546 13.9318 20.726C14.1501 19.2974 13.8303 17.8387 13.0345 16.6324L18.7278 10.9274L20.3728 12.584C20.4818 12.6922 20.6111 12.7777 20.7532 12.8358C20.8954 12.8938 21.0476 12.9233 21.2011 12.9224C21.3547 12.9233 21.5069 12.8938 21.649 12.8358C21.7912 12.7777 21.9205 12.6922 22.0295 12.584C22.1388 12.4756 22.2256 12.3466 22.2848 12.2044C22.3441 12.0622 22.3746 11.9097 22.3746 11.7557C22.3746 11.6017 22.3441 11.4492 22.2848 11.307C22.2256 11.1649 22.1388 11.0358 22.0295 10.9274L20.3728 9.34071L22.0295 7.68405L23.6745 9.34071C23.7835 9.44884 23.9128 9.53439 24.0549 9.59245C24.1971 9.6505 24.3493 9.67993 24.5028 9.67905C24.6563 9.67993 24.8085 9.6505 24.9507 9.59245C25.0928 9.53439 25.2221 9.44884 25.3311 9.34071C25.4496 9.23146 25.5441 9.09887 25.6087 8.95129C25.6733 8.8037 25.7067 8.64433 25.7067 8.48321C25.7067 8.32209 25.6733 8.16272 25.6087 8.01514C25.5441 7.86756 25.4496 7.73496 25.3311 7.62571ZM8.16946 23.3407C7.47723 23.3407 6.80054 23.1354 6.22497 22.7509C5.6494 22.3663 5.20079 21.8196 4.93589 21.1801C4.67098 20.5406 4.60167 19.8368 4.73672 19.1579C4.87176 18.479 5.20511 17.8553 5.69459 17.3658C6.18407 16.8764 6.80771 16.543 7.48665 16.408C8.16558 16.2729 8.86932 16.3422 9.50886 16.6071C10.1484 16.872 10.695 17.3206 11.0796 17.8962C11.4642 18.4718 11.6695 19.1485 11.6695 19.8407C11.6695 20.769 11.3007 21.6592 10.6443 22.3156C9.98796 22.972 9.09772 23.3407 8.16946 23.3407Z"
      [attr.fill]="color"
    />
  </svg> `,
})
export class KeyIconComponent extends BaseIconComponent {}
