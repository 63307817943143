import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-piano-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.75 12.5049C37.75 11.8418 37.4866 11.206 37.0178 10.7371C36.5489 10.2683 35.913 10.0049 35.25 10.0049H20.25C20.188 7.42545 19.1567 4.96402 17.3614 3.11083C15.5661 1.25764 13.1387 0.148705 10.5625 0.00488434C9.22322 -0.0369887 7.88919 0.19072 6.63963 0.674486C5.39007 1.15825 4.25048 1.88821 3.28854 2.821C2.3266 3.75379 1.56194 4.87038 1.03996 6.10447C0.517985 7.33855 0.249347 8.66495 0.250001 10.0049V35.0049C0.250001 35.6679 0.513393 36.3038 0.982234 36.7727C1.45108 37.2415 2.08696 37.5049 2.75 37.5049H35.25C35.913 37.5049 36.5489 37.2415 37.0178 36.7727C37.4866 36.3038 37.75 35.6679 37.75 35.0049V12.5049ZM2.75 10.0049C2.7495 8.99966 2.95107 8.0046 3.34275 7.07882C3.73442 6.15305 4.3082 5.31545 5.02999 4.61582C5.75178 3.91618 6.60685 3.36878 7.54439 3.00615C8.48192 2.64352 9.48278 2.47305 10.4875 2.50488C12.4769 2.63 14.3415 3.51681 15.694 4.98105C17.0465 6.44529 17.7829 8.37435 17.75 10.3674V11.2549C17.75 11.5864 17.8817 11.9043 18.1161 12.1388C18.3505 12.3732 18.6685 12.5049 19 12.5049H35.25V22.5049H2.75V10.0049ZM2.75 25.0049H9V35.0049H2.75V25.0049ZM11.5 25.0049H17.75V35.0049H11.5V25.0049ZM20.25 25.0049H26.5V35.0049H20.25V25.0049ZM35.25 35.0049H29V25.0049H35.25V35.0049Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class PianoIconComponent extends BaseIconComponent {}
