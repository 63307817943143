export enum ContractType {
  ARTICLE_45 = 'ARTICLE_45',
  MANAGEMENT_AGREEMENT = 'MANAGEMENT_AGREEMENT',
}

export const getContractTypeLabel = (contractType: ContractType): string => {
  switch (contractType) {
    case ContractType.ARTICLE_45:
      return 'Article 45';
    case ContractType.MANAGEMENT_AGREEMENT:
      return 'Mandat de gestion';
    default:
      return '';
  }
};
