import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-plane-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2580_13041)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.8052 33.1159C17.6096 25.0799 17.2876 11.8623 17.1656 6.8483C17.1304 5.4235 17.5364 4.0223 18.3272 2.8363C18.5676 2.4751 18.8204 2.0959 19.0556 1.7439C19.3776 1.2607 19.9196 0.970703 20.5 0.970703C21.0804 0.970703 21.6224 1.2607 21.9444 1.7435C22.1796 2.0959 22.4324 2.4751 22.6728 2.8359C23.4632 4.0215 23.8692 5.4219 23.8344 6.8463V6.8487C23.7124 11.8627 23.3904 25.0803 23.1948 33.1163L32.498 36.8619C32.8224 36.9931 33.0348 37.3075 33.0348 37.6575V37.6743C33.0348 37.9175 32.9316 38.1491 32.7508 38.3115C32.57 38.4743 32.3284 38.5519 32.0868 38.5263L23.0864 37.5671L23.0844 37.6491C23.0496 39.0515 21.9032 40.1707 20.5004 40.1707H20.4996C19.0968 40.1707 17.9504 39.0515 17.9156 37.6491L17.9136 37.5671L8.91319 38.5263C8.67159 38.5519 8.42999 38.4743 8.24839 38.3111C8.06799 38.1479 7.96519 37.9167 7.96519 37.6739V37.6575C7.96519 37.3075 8.17719 36.9935 8.50119 36.8619L17.8052 33.1159ZM25.0768 13.4203L27.5548 14.5511V12.3727C27.5548 11.9399 27.906 11.5891 28.3388 11.5891C28.7712 11.5891 29.1228 11.9399 29.1228 12.3727V15.2667L33.0416 17.0555V14.7239C33.0416 14.2915 33.3932 13.9399 33.8256 13.9399C34.2584 13.9399 34.6096 14.2915 34.6096 14.7239V17.7711L37.9452 19.2931C38.6596 19.6191 39.1184 20.3323 39.1184 21.1179V21.2387C39.1184 21.8539 38.8364 22.4351 38.3528 22.8151C37.8692 23.1955 37.238 23.3331 36.6404 23.1883L24.908 20.3471L25.0768 13.4203ZM16.092 20.3471L4.35959 23.1883C3.76199 23.3331 3.13079 23.1955 2.64719 22.8151C2.16359 22.4351 1.88159 21.8539 1.88159 21.2387V21.1179C1.88159 20.3323 2.34039 19.6191 3.05439 19.2935L6.39039 17.7711V14.7239C6.39039 14.2915 6.74159 13.9399 7.17439 13.9399C7.60679 13.9399 7.95839 14.2915 7.95839 14.7239V17.0555L11.8772 15.2667V12.3723C11.8772 11.9395 12.2288 11.5887 12.6612 11.5887C13.094 11.5887 13.4452 11.9395 13.4452 12.3723V14.5511L15.9232 13.4203L16.092 20.3471Z"
          [attr.fill]="color"
        />
      </g>
      <defs>
        <clipPath id="clip0_2580_13041">
          <rect width="40" height="40.4" fill="white" transform="translate(0.5 0.371094)" />
        </clipPath>
      </defs>
    </svg>
  `,
})
export class PlaneIconComponent extends BaseIconComponent {}
