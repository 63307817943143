import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-simple-house-icon',
  template: `<svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 35 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.6003 14.3338L18.6003 1.00049C18.2961 0.733296 17.9051 0.585938 17.5003 0.585938C17.0954 0.585938 16.7044 0.733296 16.4003 1.00049L1.40028 14.3338C1.14534 14.5593 0.965559 14.8575 0.885112 15.1882C0.804664 15.519 0.827406 15.8664 0.95028 16.1838C1.07114 16.4971 1.28379 16.7665 1.56036 16.9568C1.83694 17.1471 2.16454 17.2495 2.50028 17.2505H4.16695V32.2505C4.16695 32.6925 4.34254 33.1164 4.6551 33.429C4.96766 33.7416 5.39159 33.9172 5.83361 33.9172H29.1669C29.609 33.9172 30.0329 33.7416 30.3455 33.429C30.658 33.1164 30.8336 32.6925 30.8336 32.2505V17.2505H32.5003C32.836 17.2495 33.1636 17.1471 33.4402 16.9568C33.7168 16.7665 33.9294 16.4971 34.0503 16.1838C34.1732 15.8664 34.1959 15.519 34.1154 15.1882C34.035 14.8575 33.8552 14.5593 33.6003 14.3338ZM19.1669 30.5838H15.8336V25.5838C15.8336 25.1418 16.0092 24.7179 16.3218 24.4053C16.6343 24.0928 17.0583 23.9172 17.5003 23.9172C17.9423 23.9172 18.3662 24.0928 18.6788 24.4053C18.9914 24.7179 19.1669 25.1418 19.1669 25.5838V30.5838ZM27.5003 30.5838H22.5003V25.5838C22.5003 24.2577 21.9735 22.986 21.0358 22.0483C20.0981 21.1106 18.8264 20.5838 17.5003 20.5838C16.1742 20.5838 14.9024 21.1106 13.9647 22.0483C13.0271 22.986 12.5003 24.2577 12.5003 25.5838V30.5838H7.50028V17.2505H27.5003V30.5838ZM6.88361 13.9172L17.5003 4.48383L28.1169 13.9172H6.88361Z"
      [attr.fill]="color"
    />
  </svg>`,
})
export class SimpleHouseIconComponent extends BaseIconComponent {}
