import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-smiley-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.7715 23.3745C30.5952 23.2825 30.3994 23.2344 30.2006 23.2341C29.9729 23.2337 29.7496 23.2962 29.5552 23.4145C29.3608 23.5329 29.2028 23.7027 29.0987 23.9051C27.6727 26.6665 24.2978 28.25 20.5008 28.25C16.6153 28.25 13.1695 26.6436 11.926 23.9534C11.7238 23.5167 11.282 23.2347 10.8005 23.2347C10.6213 23.2347 10.4473 23.2726 10.2803 23.349C9.66111 23.636 9.38994 24.3738 9.67611 24.9941C11.3172 28.5441 15.6673 30.75 20.5008 30.75C25.2149 30.75 29.4542 28.6187 31.3009 25.0426C31.6141 24.4363 31.3756 23.6873 30.7715 23.3745ZM32.7499 18.8105C31.9311 17.7495 30.893 17 29.2612 17C27.6294 17 26.6001 17.7479 25.776 18.806C25.3461 19.3577 25.4224 20.1055 26.0686 20.5409C26.2963 20.6943 26.5645 20.75 26.8442 20.75C27.2657 20.7499 27.6651 20.5852 27.9122 20.2673C28.1817 19.9208 28.5143 19.5 29.2613 19.5C30.012 19.5 30.3419 19.9188 30.6074 20.2638C30.8556 20.5845 31.2562 20.75 31.679 20.75C31.9588 20.75 32.2264 20.6947 32.4542 20.5404C33.0441 20.1395 33.1768 19.3633 32.7499 18.8105ZM15.2499 18.8105C14.4311 17.7495 13.393 17 11.7612 17C10.1294 17 9.10002 17.7479 8.27588 18.806C7.84611 19.3577 7.92236 20.1055 8.56861 20.5409C8.79627 20.6943 9.06439 20.75 9.34416 20.75C9.76564 20.7499 10.165 20.5852 10.412 20.2673C10.6817 19.9208 11.0142 19.5 11.7612 19.5C12.512 19.5 12.8418 19.9188 13.1073 20.2638C13.3555 20.5845 13.756 20.75 14.1788 20.75C14.4587 20.75 14.7263 20.6947 14.954 20.5404C15.5441 20.1395 15.6768 19.3633 15.2499 18.8105Z"
        [attr.fill]="color"
      />
      <path
        d="M20.5 3.25C10.835 3.25 3 11.085 3 20.75C3 30.415 10.835 38.25 20.5 38.25C30.165 38.25 38 30.415 38 20.75C38 11.085 30.165 3.25 20.5 3.25ZM31.1066 31.3566C28.2734 34.1897 24.5066 35.75 20.5 35.75C16.4934 35.75 12.7266 34.1897 9.89336 31.3566C7.06031 28.5234 5.5 24.7566 5.5 20.75C5.5 16.7434 7.06031 12.9766 9.89336 10.1434C12.7266 7.31031 16.4934 5.75 20.5 5.75C24.5066 5.75 28.2734 7.31031 31.1066 10.1434C33.9397 12.9766 35.5 16.7434 35.5 20.75C35.5 24.7566 33.9397 28.5234 31.1066 31.3566Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class SmileyIconComponent extends BaseIconComponent {}
