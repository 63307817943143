import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-antenna-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1238 20.0182C13.8314 18.724 13.1055 16.9699 13.1055 15.1409C13.1055 13.312 13.8314 11.5578 15.1238 10.2637M24.8783 10.2637C26.1713 11.5574 26.8977 13.3117 26.8977 15.1409C26.8977 16.9701 26.1713 18.7244 24.8783 20.0182"
        [attr.stroke]="color"
        stroke-width="2.75862"
        stroke-miterlimit="10"
      />
      <path
        d="M20.0008 17.9001C21.5244 17.9001 22.7594 16.665 22.7594 15.1414C22.7594 13.6179 21.5244 12.3828 20.0008 12.3828C18.4773 12.3828 17.2422 13.6179 17.2422 15.1414C17.2422 16.665 18.4773 17.9001 20.0008 17.9001Z"
        [attr.fill]="color"
      />
      <path
        d="M28.7794 6.36328C31.1061 8.69204 32.4131 11.8493 32.4131 15.1412C32.4131 18.4331 31.1061 21.5904 28.7794 23.9191M11.2235 23.9191C8.89681 21.5904 7.58984 18.4331 7.58984 15.1412C7.58984 11.8493 8.89681 8.69204 11.2235 6.36328M20.0015 35.1412V14.4516"
        [attr.stroke]="color"
        stroke-width="2.75862"
        stroke-miterlimit="10"
      />
    </svg>
  `,
})
export class AntennaIconComponent extends BaseIconComponent {}
