export enum HouseholdCovers {
  ROBBERY = 'ROBBERY',
  OUTSIDE_ROBBERY = 'OUTSIDE_ROBBERY',
  HEARTHQUAKE = 'HEARTHQUAKE',
  WATER_DAMAGE = 'WATER_DAMAGE',
  NATURAL_EVENTS = 'NATURAL_EVENTS',
  GLASS_BREAKAGE = 'GLASS_BREAKAGE',
  ELECTRONIC_DEVICES = 'ELECTRONIC_DEVICES',
  OUTDOOR_EQUIPMENT = 'OUTDOOR_EQUIPMENT',
}

export const getHouseholdCoversLabel = (value: HouseholdCovers): string => {
  switch (value) {
    case HouseholdCovers.ROBBERY:
      return $localize`Vol`;
    case HouseholdCovers.NATURAL_EVENTS:
      return $localize`Incendie/évènements naturels`;
    case HouseholdCovers.OUTSIDE_ROBBERY:
      return $localize`Vol simple à l'extérieur du domicile`;
    case HouseholdCovers.HEARTHQUAKE:
      return $localize`Tremblement de terre`;
    case HouseholdCovers.WATER_DAMAGE:
      return $localize`Dégâts des eaux`;
    case HouseholdCovers.GLASS_BREAKAGE:
      return $localize`Bris de glace`;
    case HouseholdCovers.OUTDOOR_EQUIPMENT:
      return $localize`Aménagement extérieur mobiles`;
    case HouseholdCovers.ELECTRONIC_DEVICES:
      return $localize`Casco ménage`;
    default:
      return '';
  }
};
