export enum TravelCoversEnum {
  ANNULATION_FEE = 'ANNULATION_FEE',
  PREGNANCY = 'PREGNANCY',
  RAPATRIATION = 'RAPATRIATION',
  RECHERCHE_AND_RESCUE = 'RECHERCHE_AND_RESCUE',
  HOSPITALIZATION_ABROAD = 'HOSPITALIZATION_ABROAD',
  LEISURE_PROTECTION = 'LEISURE_PROTECTION',
  FLIGHT_DELAY = 'FLIGHT_DELAY',
  LUGGAGE = 'LUGGAGE',
  CAT_AND_DOG = 'CAT_AND_DOG',
  MEDICAL_EXPENSE_WORLWIDE = 'MEDICAL_EXPENSE_WORLWIDE',
  FLIGHT_COMPANY_INSOLVENCY = 'FLIGHT_COMPANY_INSOLVENCY',
  NATURAL_DISASTER = 'NATURAL_DISASTER',
  SECOND_EXAMINATION_COMPENSATION = 'SECOND_EXAMINATION_COMPENSATION',
  EUROPE_LEGAL_PROTECTION = 'EUROPE_LEGAL_PROTECTION',
  WORLD_LEGAL_PROTECTION = 'WORLD_LEGAL_PROTECTION',
  AIRCRAFT_ACCIDENT = 'AIRCRAFT_ACCIDENT',
}
