import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-flat-house-icon',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-1 -1 100 100"
    id="House-2--Streamline-Streamline--3.0.svg"
    [attr.height]="height"
    [attr.width]="width"
  >
    <path
      [attr.stroke]="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M83.70833333333334 49.00000000000001v36.75000000000001c0 1.0829000000000002 -0.4303833333333334 2.1217 -1.1960083333333336 2.887325S80.70790000000001 89.83333333333334 79.62500000000001 89.83333333333334h-20.41666666666667v-24.500000000000004c0 -2.7072500000000006 -1.0755500000000002 -5.303841666666667 -2.989816666666667 -7.218516666666668C54.30384166666667 56.200550000000014 51.70725000000001 55.12500000000001 49.00000000000001 55.12500000000001s-5.303841666666667 1.0755500000000002 -7.218516666666668 2.989816666666667C39.867175833333334 60.02949166666668 38.79166666666667 62.62608333333334 38.79166666666667 65.33333333333334v24.500000000000004h-20.41666666666667c-1.082981666666667 0 -2.1215775000000003 -0.4303833333333334 -2.887365833333334 -1.1960083333333336C14.72188666666667 87.87170000000002 14.291666666666668 86.83290000000001 14.291666666666668 85.75000000000001v-36.75000000000001"
      stroke-width="8"
    />
    <path
      [attr.stroke]="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M93.00200000000001 34.92475000000001c0.31115000000000004 0.6223816666666667 0.45774166666666677 1.3138941666666668 0.42630000000000007 2.008959166666667 -0.031033333333333336 0.6950650000000002 -0.23969166666666672 1.370611666666667 -0.6051500000000001 1.962531666666667 -0.36586666666666673 0.5918791666666667 -0.8766916666666668 1.080531666666667 -1.4842916666666668 1.4195708333333337 -0.6076 0.33899833333333335 -1.2915583333333336 0.5171541666666668 -1.9873583333333338 0.5175216666666667H8.6485c-0.6957591666666668 -0.0003675000000000001 -1.379921666666667 -0.17852333333333337 -1.9874808333333336 -0.5175216666666667 -0.6076 -0.3390391666666667 -1.1184658333333335 -0.8276916666666667 -1.484169166666667 -1.4195708333333337 -0.3657033333333334 -0.5919200000000001 -0.5740758333333333 -1.267466666666667 -0.6053950000000001 -1.962531666666667 -0.03127833333333334 -0.6950650000000002 0.11551750000000001 -1.3865775000000002 0.42654500000000006 -2.008959166666667L14.291666666666668 16.333333333333336h69.41666666666667l9.293666666666667 18.591416666666667Z"
      stroke-width="8"
    />
    <path
      [attr.stroke]="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M14.291666666666668 16.333333333333336V12.250000000000002c0 -1.082981666666667 0.43022000000000005 -2.1215775000000003 1.1959675 -2.887365833333334C16.253422500000003 8.596886666666668 17.292018333333335 8.166666666666668 18.375000000000004 8.166666666666668h61.25000000000001c1.0829000000000002 0 2.1217 0.43022000000000005 2.887325 1.1959675 0.7656250000000001 0.7657883333333335 1.1960083333333336 1.804384166666667 1.1960083333333336 2.887365833333334v4.083333333333334"
      stroke-width="8"
    />
    <path
      [attr.stroke]="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M38.79166666666667 89.83333333333334h20.41666666666667"
      stroke-width="8"
    />
    <path
      [attr.stroke]="color"
      d="M51.04166666666667 74.52083333333334c-0.5639083333333335 0 -1.0208333333333335 -0.4569250000000001 -1.0208333333333335 -1.0208333333333335s0.4569250000000001 -1.0208333333333335 1.0208333333333335 -1.0208333333333335"
      stroke-width="8"
    />
    <path
      [attr.stroke]="color"
      d="M51.04166666666667 74.52083333333334c0.5639083333333335 0 1.0208333333333335 -0.4569250000000001 1.0208333333333335 -1.0208333333333335s-0.4569250000000001 -1.0208333333333335 -1.0208333333333335 -1.0208333333333335"
      stroke-width="8"
    />
  </svg>`,
})
export class FlatHouseIconComponent extends BaseIconComponent {}
