export enum ProjectOwnerType {
  OWNER = 'OWNER',
  BENEFICIARY = 'BENEFICIARY',
  TENANT = 'TENANT',
  OTHER = 'OTHER',
}

export const getProjectOwnerTypeLabel = (value: ProjectOwnerType): string => {
  switch (value) {
    case ProjectOwnerType.OWNER:
      return $localize`Propriétaire`;
    case ProjectOwnerType.BENEFICIARY:
      return $localize`Bénéficiaire d'un droit de surface`;
    case ProjectOwnerType.TENANT:
      return $localize`Locataire`;
    case ProjectOwnerType.OTHER:
      return $localize`Autres`;
    default:
      return '';
  }
};
