import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-parking-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6654 8.41537H13.6654C13.2233 8.41537 12.7994 8.59096 12.4869 8.90352C12.1743 9.21608 11.9987 9.64 11.9987 10.082V23.4154C11.9987 23.8574 12.1743 24.2813 12.4869 24.5939C12.7994 24.9064 13.2233 25.082 13.6654 25.082C14.1074 25.082 14.5313 24.9064 14.8439 24.5939C15.1564 24.2813 15.332 23.8574 15.332 23.4154V20.082H18.6654C19.9915 20.082 21.2632 19.5552 22.2009 18.6176C23.1386 17.6799 23.6654 16.4081 23.6654 15.082V13.4154C23.6654 12.0893 23.1386 10.8175 22.2009 9.87983C21.2632 8.94215 19.9915 8.41537 18.6654 8.41537ZM20.332 15.082C20.332 15.5241 20.1564 15.948 19.8439 16.2605C19.5313 16.5731 19.1074 16.7487 18.6654 16.7487H15.332V11.7487H18.6654C19.1074 11.7487 19.5313 11.9243 19.8439 12.2369C20.1564 12.5494 20.332 12.9733 20.332 13.4154V15.082ZM16.9987 0.0820312C13.7024 0.0820312 10.48 1.05951 7.7392 2.89087C4.99838 4.72223 2.86217 7.32521 1.60071 10.3706C0.339253 13.4161 0.00919709 16.7672 0.652284 20.0002C1.29537 23.2332 2.88272 26.2029 5.21359 28.5338C7.54447 30.8647 10.5142 32.452 13.7472 33.0951C16.9802 33.7382 20.3313 33.4082 23.3768 32.1467C26.4222 30.8852 29.0252 28.749 30.8565 26.0082C32.6879 23.2674 33.6654 20.0451 33.6654 16.7487C33.6654 14.56 33.2343 12.3927 32.3967 10.3706C31.5591 8.34855 30.3315 6.51123 28.7838 4.96358C27.2362 3.41594 25.3989 2.18828 23.3768 1.35071C21.3547 0.513127 19.1874 0.0820313 16.9987 0.0820312ZM16.9987 30.082C14.3616 30.082 11.7838 29.3 9.5911 27.835C7.39845 26.3699 5.68948 24.2875 4.68031 21.8511C3.67114 19.4148 3.4071 16.7339 3.92157 14.1475C4.43604 11.5611 5.70591 9.18531 7.57061 7.32061C9.43532 5.45591 11.8111 4.18603 14.3975 3.67156C16.9839 3.15709 19.6648 3.42114 22.1012 4.4303C24.5375 5.43947 26.6199 7.14844 28.085 9.34109C29.55 11.5338 30.332 14.1116 30.332 16.7487C30.332 20.2849 28.9273 23.6763 26.4268 26.1768C23.9263 28.6773 20.5349 30.082 16.9987 30.082Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class ParkingIconComponent extends BaseIconComponent {}
