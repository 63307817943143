/// <reference types="@angular/localize" />
/// <reference types="@types/google.maps" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: 'https://961952fbd26de206c5f0374222b476ca@o4506773103837184.ingest.us.sentry.io/4506897127833600',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    tracesSampleRate: 1.0, //  Capture 100% of the transactions

    tracePropagationTargets: ['localhost', /^https:\/\/(staging\.)?clients\.lty\.ch/],

    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: environment.name,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule);
