export enum DamagedGood {
  JEWELRY = 'JEWELRY',
  HEARING_AID = 'HEARING_AID',
  WEAPON = 'WEAPON',
  PHOTOGRAHIC_EQUIPMENT = 'PHOTOGRAHIC_EQUIPMENT',
  CLOTHES = 'CLOTHES',
  PAINTING = 'PAINTING',
  MUSIC_INSTRUMENT = 'MUSIC_INSTRUMENT',
  OTHERS = 'OTHERS',
}

export const getDamagedGoodLabel = (good: DamagedGood): string => {
  switch (good) {
    case DamagedGood.JEWELRY:
      return $localize`Bijoux & Montres`;
    case DamagedGood.HEARING_AID:
      return $localize`Prothèses auditives`;
    case DamagedGood.WEAPON:
      return $localize`Armes`;
    case DamagedGood.PHOTOGRAHIC_EQUIPMENT:
      return $localize`Équipement photo et vidéo`;
    case DamagedGood.CLOTHES:
      return $localize`Fourrures - Vêtements`;
    case DamagedGood.PAINTING:
      return $localize`Peintures & Sculptures`;
    case DamagedGood.MUSIC_INSTRUMENT:
      return $localize`Instruments de musique`;
    case DamagedGood.OTHERS:
      return $localize`Autres`;
    default:
      return good;
  }
};
