import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-fire-alarm-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.3001 20.5508H34.9001V27.7508C34.9001 31.5908 31.9001 34.7108 28.1001 34.9508V33.3508C30.9801 33.1508 33.3001 30.7108 33.3001 27.7508V20.5508ZM18.5001 30.9508C21.4601 30.9508 24.2201 30.0308 26.5001 28.4308V34.9508C26.5001 36.0708 25.6201 36.9508 24.5001 36.9508H12.5001C11.3801 36.9508 10.5001 36.0708 10.5001 34.9508V28.4308C12.7801 30.0308 15.5401 30.9508 18.5001 30.9508ZM20.9001 33.3508H16.1001C15.6601 33.3508 15.3001 33.7108 15.3001 34.1508C15.3001 34.5908 15.6601 34.9508 16.1001 34.9508H20.9001C21.3401 34.9508 21.7001 34.5908 21.7001 34.1508C21.7001 33.7108 21.3401 33.3508 20.9001 33.3508ZM30.9001 16.9508C30.9001 23.7908 25.3401 29.3508 18.5001 29.3508C11.6601 29.3508 6.1001 23.7908 6.1001 16.9508C6.1001 10.1108 11.6601 4.55078 18.5001 4.55078C25.3401 4.55078 30.9001 10.1108 30.9001 16.9508ZM21.3001 16.9508C21.3001 15.3908 20.0601 14.1508 18.5001 14.1508C16.9401 14.1508 15.7001 15.3908 15.7001 16.9508C15.7001 18.5108 16.9401 19.7508 18.5001 19.7508C20.0601 19.7508 21.3001 18.5108 21.3001 16.9508ZM18.5001 15.7508C17.8201 15.7508 17.3001 16.2708 17.3001 16.9508C17.3001 17.5908 17.8201 18.1508 18.5001 18.1508C19.1801 18.1508 19.7001 17.5908 19.7001 16.9508C19.7001 16.2708 19.1801 15.7508 18.5001 15.7508ZM33.3001 14.9508C32.8601 14.9508 32.5001 15.3108 32.5001 15.7508V18.1508C32.5001 18.5908 32.8601 18.9508 33.3001 18.9508H34.9001V14.9508H33.3001Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class FireAlarmIconComponent extends BaseIconComponent {}
