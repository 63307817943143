import {
  getLegalProtectionCoverLabel,
  LegalProtectionCoversEnum,
} from 'src/app/enums/legal-protection/legal-protection-covers.enum';
import { LegalProtectionDetailsEnum } from 'src/app/enums/legal-protection/legal-protection-details.enum';
import { LegalProtectionOptionsEnum } from 'src/app/enums/legal-protection/legal-protection-options.enum';

import { SelectElementMapper } from '../../_services/types/commons.type';
import { ContractToSignInfo } from '../../_services/types/contract-template.types';
import { ContractType } from '../../enums/contract-type.enum';
import { InsuranceTypeEnum } from '../../enums/insurance-type.enum';
import { MinimalistHouseIconComponent } from '../../ui-kit/icons/house-minimalist-icon.component';
import { KeyIconComponent } from '../../ui-kit/icons/key-icon.component';
import { RoadIconComponent } from '../../ui-kit/icons/road-icon.component';

export const LEGAL_PROTECTION_BASE_URL = 'protection-juridique';

export const LEGAL_PROTECTION_FORM_BASE_URL = 'formulaire';

export const LEGAL_PROTECTION_OFFER_BASE_URL = 'offre';

export const LEGAL_PROTECTION_STEPS = {
  BASE: `${LEGAL_PROTECTION_BASE_URL}/${LEGAL_PROTECTION_FORM_BASE_URL}/`,
  RESIDENT_STATUS: { step: [1, 1], url: 'statut-resident' },
  POLICYHOLDER_COUNT: { step: [1, 2], url: 'nombre-assure' },
  CONTRACT_START: { step: [1, 3], url: 'debut-contrat' },
  BIRTHDATE: { step: [2, 1], url: 'date-naissance' },
  COVERS: { step: [2, 2], url: 'couvertures' },
  OFFER_SEARCH: { step: [3, 1], url: 'offres' },
  OFFRES: { step: [3, 1], url: 'offres' },
};

export const LEGAL_PROTECTION_OFFER_FORM_STEPS = {
  BASE: `${LEGAL_PROTECTION_BASE_URL}/${LEGAL_PROTECTION_OFFER_BASE_URL}/`,
  ACCOUNT: { step: [1, 1], url: 'compte' },
  LOGIN: { step: [1, 1], url: 'connexion' },
  REGISTER: { step: [1, 1], url: 'inscription' },
  PERSONAL_INFO: { step: [2, 1], url: 'informations-personnelles' },
  CONTRACTUAL_QUESTIONARY: { step: [3, 1], url: 'questionnaire-contractuel' },
  CO_INSURED: { step: [3, 1], url: 'co-assures' },
  CONTRACT_SIGNATURE: { step: [4, 1], url: 'signature-contrat' },
  SUMMARY: { step: [4, 1], url: 'recapitulatif' },
  INSURANCE_CONTRACT_SIGNATURE: { step: [5, 1], url: 'signature-contrat-assurance' },
  CANCELLATION: { step: [5, 1], url: 'resiliation' },
};

export const LEGAL_PROTECTION_END_URL = `${LEGAL_PROTECTION_BASE_URL}/fin`;

export const LEGAL_PROTECTION_COVERS: SelectElementMapper<LegalProtectionCoversEnum> = {
  [LegalProtectionCoversEnum.PRIVATE]: {
    value: LegalProtectionCoversEnum.PRIVATE,
    label: getLegalProtectionCoverLabel(LegalProtectionCoversEnum.PRIVATE),
    iconComponent: KeyIconComponent,
  },
  [LegalProtectionCoversEnum.CIRCULATION]: {
    value: LegalProtectionCoversEnum.CIRCULATION,
    label: getLegalProtectionCoverLabel(LegalProtectionCoversEnum.CIRCULATION),
    iconComponent: RoadIconComponent,
  },
  [LegalProtectionCoversEnum.BUILDING]: {
    value: LegalProtectionCoversEnum.BUILDING,
    label: getLegalProtectionCoverLabel(LegalProtectionCoversEnum.BUILDING),
    iconComponent: MinimalistHouseIconComponent,
    require: LegalProtectionCoversEnum.PRIVATE,
  },
};

export const LEGAL_PROTECTION_OPTIONS: SelectElementMapper<LegalProtectionOptionsEnum> = {
  [LegalProtectionOptionsEnum.MULTI_RISK]: {
    value: LegalProtectionOptionsEnum.MULTI_RISK,
    label: $localize`Multi Risk`,
  },
  [LegalProtectionOptionsEnum.INDEPENDANT]: {
    value: LegalProtectionOptionsEnum.INDEPENDANT,
    label: $localize`Indépendant`,
  },
  [LegalProtectionOptionsEnum.BUILDING]: {
    value: LegalProtectionOptionsEnum.BUILDING,
    label: $localize`Bâtiment`,
  },
  [LegalProtectionOptionsEnum.LANDLORD]: {
    value: LegalProtectionOptionsEnum.LANDLORD,
    label: $localize`Bailleur`,
  },
};

export const LEGAL_PROTECTION_DETAILS: SelectElementMapper<LegalProtectionDetailsEnum> = {
  [LegalProtectionDetailsEnum.CONSUMER_LAW]: {
    value: LegalProtectionDetailsEnum.CONSUMER_LAW,
    label: $localize`Droit des consommateurs et autre droit des contrats`,
  },
  [LegalProtectionDetailsEnum.PROPERTY_LAW]: {
    value: LegalProtectionDetailsEnum.PROPERTY_LAW,
    label: $localize`Droit de la propriété et droits réels`,
  },
  [LegalProtectionDetailsEnum.LABOR_LAW]: {
    value: LegalProtectionDetailsEnum.LABOR_LAW,
    label: $localize`Droit du travail`,
  },
  [LegalProtectionDetailsEnum.GENDER_EQUALITY]: {
    value: LegalProtectionDetailsEnum.GENDER_EQUALITY,
    label: $localize`Droit de l’égalité entre femmes et hommes`,
  },
  [LegalProtectionDetailsEnum.LANDLORD_LITIGATION]: {
    value: LegalProtectionDetailsEnum.LANDLORD_LITIGATION,
    label: $localize`Droit du bail`,
  },
  [LegalProtectionDetailsEnum.ASSOCIATION_LAW]: {
    value: LegalProtectionDetailsEnum.ASSOCIATION_LAW,
    label: $localize`Droit associatif`,
  },
  [LegalProtectionDetailsEnum.INSURANCE_LAW]: {
    value: LegalProtectionDetailsEnum.INSURANCE_LAW,
    label: $localize`Droit des assurances`,
  },
  [LegalProtectionDetailsEnum.PATIENT_LAW]: {
    value: LegalProtectionDetailsEnum.PATIENT_LAW,
    label: $localize`Droit des patients`,
  },
  [LegalProtectionDetailsEnum.CIVIL_RESPONSIBILITY_LAW]: {
    value: LegalProtectionDetailsEnum.CIVIL_RESPONSIBILITY_LAW,
    label: $localize`Droit de la responsabilité civile extracontractuelle`,
  },
  [LegalProtectionDetailsEnum.VICTIM_ASSISTANCE]: {
    value: LegalProtectionDetailsEnum.VICTIM_ASSISTANCE,
    label: $localize`Aide aux victimes`,
  },
  [LegalProtectionDetailsEnum.MATRIMONIAL_LAW]: {
    value: LegalProtectionDetailsEnum.MATRIMONIAL_LAW,
    label: $localize`Droit du mariage, du divorce et du partenariat enregistré`,
  },
  [LegalProtectionDetailsEnum.INHERITANCE_LAW]: {
    value: LegalProtectionDetailsEnum.INHERITANCE_LAW,
    label: $localize`Droit successoral`,
  },
  [LegalProtectionDetailsEnum.LAND_LAW]: {
    value: LegalProtectionDetailsEnum.LAND_LAW,
    label: $localize`Droit de la propriété foncière`,
  },
  [LegalProtectionDetailsEnum.VEHICLE_LAW]: {
    value: LegalProtectionDetailsEnum.VEHICLE_LAW,
    label: $localize`Droit des contrats pour les véhicules`,
  },
  [LegalProtectionDetailsEnum.CRIMINAL_LAW]: {
    value: LegalProtectionDetailsEnum.CRIMINAL_LAW,
    label: $localize`Droit pénal et sanctions de droit administratif`,
  },
  [LegalProtectionDetailsEnum.TAX_LAW]: {
    value: LegalProtectionDetailsEnum.TAX_LAW,
    label: $localize`Droit fiscal`,
  },
  [LegalProtectionDetailsEnum.CYBER_RISK]: {
    value: LegalProtectionDetailsEnum.CYBER_RISK,
    label: $localize`Cyber Risk`,
  },
  [LegalProtectionDetailsEnum.SCHOOL_LAW]: {
    value: LegalProtectionDetailsEnum.SCHOOL_LAW,
    label: $localize`Droit scolaire`,
  },
  [LegalProtectionDetailsEnum.CHILDREN_LAW]: {
    value: LegalProtectionDetailsEnum.CHILDREN_LAW,
    label: $localize`Droit de la protection de l'enfant et de l'adulte`,
  },
  [LegalProtectionDetailsEnum.DEATH_INSURANCE]: {
    value: LegalProtectionDetailsEnum.DEATH_INSURANCE,
    label: $localize`Protection juridique en cas de décès`,
  },
  [LegalProtectionDetailsEnum.ANIMAL_LAW]: {
    value: LegalProtectionDetailsEnum.ANIMAL_LAW,
    label: $localize`Droit du propriétaire d'animaux`,
  },
  [LegalProtectionDetailsEnum.DEPTH_INSURANCE]: {
    value: LegalProtectionDetailsEnum.DEPTH_INSURANCE,
    label: $localize`Recouvrement de créances`,
  },
  [LegalProtectionDetailsEnum.CONSTRUCTION_CONTRACT]: {
    value: LegalProtectionDetailsEnum.CONSTRUCTION_CONTRACT,
    label: $localize`Droit des contrats de la construction`,
  },
  [LegalProtectionDetailsEnum.POPERTY_LAW]: {
    value: LegalProtectionDetailsEnum.POPERTY_LAW,
    label: $localize`Droit immobilier`,
  },
  [LegalProtectionDetailsEnum.DATA_PROTECTION]: {
    value: LegalProtectionDetailsEnum.DATA_PROTECTION,
    label: $localize`Droit de la protection des données`,
  },
  [LegalProtectionDetailsEnum.INTELECTUAL_PROPERTY]: {
    value: LegalProtectionDetailsEnum.INTELECTUAL_PROPERTY,
    label: $localize`Droit de la propriété intellectuelle`,
  },
  [LegalProtectionDetailsEnum.INDEPENDANT_ACTIVITY]: {
    value: LegalProtectionDetailsEnum.INDEPENDANT_ACTIVITY,
    label: $localize`Activité accessoire indépendante`,
  },
  [LegalProtectionDetailsEnum.WEB_LAW]: {
    value: LegalProtectionDetailsEnum.WEB_LAW,
    label: $localize`Droit de l’internet`,
  },
  [LegalProtectionDetailsEnum.NATIONALITY_LAW]: {
    value: LegalProtectionDetailsEnum.NATIONALITY_LAW,
    label: $localize`Droit de la nationalité`,
  },
  [LegalProtectionDetailsEnum.PERSONALITY_LAW]: {
    value: LegalProtectionDetailsEnum.PERSONALITY_LAW,
    label: $localize`Droit de la personnalité`,
  },
  [LegalProtectionDetailsEnum.NEIGHBORHOOD_LAW]: {
    value: LegalProtectionDetailsEnum.NEIGHBORHOOD_LAW,
    label: $localize`Droit de voisinage`,
  },
  [LegalProtectionDetailsEnum.CONTRACTS_LAW]: {
    value: LegalProtectionDetailsEnum.CONTRACTS_LAW,
    label: $localize`Droit des contrats`,
  },
  [LegalProtectionDetailsEnum.CUSTOMS_LAW]: {
    value: LegalProtectionDetailsEnum.CUSTOMS_LAW,
    label: $localize`Droit douanier`,
  },
  [LegalProtectionDetailsEnum.LANDLORD_LAW]: {
    value: LegalProtectionDetailsEnum.LANDLORD_LAW,
    label: $localize`Droit de bail`,
  },
  [LegalProtectionDetailsEnum.TRAVEL_LAW]: {
    value: LegalProtectionDetailsEnum.TRAVEL_LAW,
    label: $localize`Voyage à l'étranger`,
  },
  [LegalProtectionDetailsEnum.VEHICLE_TAX]: {
    value: LegalProtectionDetailsEnum.VEHICLE_TAX,
    label: $localize`Imposition des véhicules`,
  },
  [LegalProtectionDetailsEnum.LICENCE_DRIVE_WITHDRAWAL]: {
    value: LegalProtectionDetailsEnum.LICENCE_DRIVE_WITHDRAWAL,
    label: $localize`Retrait du permis de conduire`,
  },
  [LegalProtectionDetailsEnum.WORK_CONTRACT]: {
    value: LegalProtectionDetailsEnum.WORK_CONTRACT,
    label: $localize`Droit public de la construction`,
  },
  [LegalProtectionDetailsEnum.EXPROPRIATION_RIGHT]: {
    value: LegalProtectionDetailsEnum.EXPROPRIATION_RIGHT,
    label: $localize`Droit de l’expropriation`,
  },
  [LegalProtectionDetailsEnum.FLOOR_PROPERTY_RIGHT]: {
    value: LegalProtectionDetailsEnum.FLOOR_PROPERTY_RIGHT,
    label: $localize`Droit de la propriété par étages`,
  },
  [LegalProtectionDetailsEnum.PUBLIC_CONSTRUCTION_RIGHT]: {
    value: LegalProtectionDetailsEnum.PUBLIC_CONSTRUCTION_RIGHT,
    label: $localize`Droit public de la construction`,
  },
  [LegalProtectionDetailsEnum.MANDATE]: {
    value: LegalProtectionDetailsEnum.MANDATE,
    label: $localize`Mandat`,
  },
  [LegalProtectionDetailsEnum.SERVITUDE]: {
    value: LegalProtectionDetailsEnum.SERVITUDE,
    label: $localize`Servitudes`,
  },
  [LegalProtectionDetailsEnum.DAMAGES]: {
    value: LegalProtectionDetailsEnum.DAMAGES,
    label: $localize`Dommages-intérêts`,
  },
  [LegalProtectionDetailsEnum.RIGHT_PROTECTION]: {
    value: LegalProtectionDetailsEnum.RIGHT_PROTECTION,
    label: $localize`Droits réels`,
  },
  [LegalProtectionDetailsEnum.COPYRIGHT]: {
    value: LegalProtectionDetailsEnum.COPYRIGHT,
    label: $localize`Droit d’auteur`,
  },
  [LegalProtectionDetailsEnum.HARASSMENT]: {
    value: LegalProtectionDetailsEnum.HARASSMENT,
    label: $localize`Contre harclèment`,
  },
  [LegalProtectionDetailsEnum.FOREIGN_TRAVEL]: {
    value: LegalProtectionDetailsEnum.FOREIGN_TRAVEL,
    label: $localize`Voyage à l'étranger`,
  },
  [LegalProtectionDetailsEnum.VEHICLE_CONTRACT]: {
    value: LegalProtectionDetailsEnum.VEHICLE_CONTRACT,
    label: $localize`Contrats en rapport avec un véhicule`,
  },
  [LegalProtectionDetailsEnum.GARAGE_RENT]: {
    value: LegalProtectionDetailsEnum.GARAGE_RENT,
    label: $localize`Location d’un garage`,
  },
};

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 protection juridique',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.LEGAL_PROTECTION,
  },
  {
    name: 'Mandat gestion protection juridique',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.LEGAL_PROTECTION,
  },
];
