import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-garage-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="7 8 26.5 26.59"
    >
      <path
        d="M22.7187 28.875H24.1766V30.3893H22.7187V28.875ZM16.3262 28.875H17.7841V30.3893H16.3262V28.875Z"
        [attr.fill]="color"
      />
      <path
        d="M28.5752 26.411L27.0625 26.4738C27.0693 26.6434 27.0208 26.6915 27.003 26.7097C26.9496 26.7627 26.8542 26.8006 26.7505 26.8286L25.3642 23.8405C25.1961 23.3999 24.8983 23.0205 24.5101 22.7526C24.122 22.4847 23.6617 22.3408 23.1901 22.3398H17.3052C16.8335 22.3408 16.3733 22.4847 15.9851 22.7526C15.597 23.0205 15.2992 23.3999 15.131 23.8405L13.7432 26.8324C13.6429 26.8051 13.5497 26.7665 13.4933 26.7112C13.4733 26.6911 13.4259 26.645 13.4328 26.4742L11.92 26.411C11.8908 27.1083 12.1831 27.5467 12.4333 27.792C12.4757 27.8337 12.5219 27.867 12.567 27.903C12.5666 27.9158 12.5632 27.9276 12.5632 27.9408V31.3173C12.5635 31.5987 12.6754 31.8686 12.8744 32.0676C13.0734 32.2666 13.3433 32.3785 13.6247 32.3788H14.1683V33.2684C14.1683 33.9991 14.7627 34.5931 15.4929 34.5931H17.2366C17.9673 34.5931 18.5609 33.9987 18.5609 33.2684V32.3788H21.9362V33.2684C21.9362 33.9991 22.5302 34.5931 23.2605 34.5931H25.0042C25.7348 34.5931 26.3288 33.9987 26.3288 33.2684V32.3788H26.8724C27.1539 32.3785 27.4237 32.2666 27.6227 32.0676C27.8217 31.8686 27.9336 31.5987 27.9339 31.3173V27.9404C27.9339 27.9272 27.9305 27.9147 27.9302 27.9014C27.9744 27.8659 28.0199 27.8333 28.0619 27.792C28.3118 27.5467 28.6044 27.1083 28.5752 26.411ZM16.5174 24.4508L16.5423 24.3891C16.5994 24.2325 16.7031 24.0972 16.8396 24.0015C16.976 23.9058 17.1385 23.8544 17.3052 23.8541H23.1901C23.5308 23.8541 23.837 24.0692 23.9529 24.3891L25.1045 26.8789H15.3907L16.5174 24.4508ZM17.0466 33.0788H15.683V32.3784H17.047L17.0466 33.0788ZM24.8145 33.0788H23.4505V32.3784H24.8145V33.0788ZM26.4197 30.8641H14.0778V28.3943L14.1293 28.3955C14.177 28.3955 14.2088 28.3943 14.2315 28.3932H26.2641C26.2868 28.3943 26.3186 28.3955 26.3663 28.3955L26.4197 28.3943V30.8641Z"
        [attr.fill]="color"
      />
      <path
        d="M7 14.8256V34.5946H11.1643V20.2089H29.3357V34.5946H33.5V14.8256L20.2489 8L7 14.8256ZM29.3357 18.6946H11.1643V18.4342C11.1646 18.2021 11.2569 17.9796 11.4211 17.8155C11.5853 17.6514 11.8078 17.5591 12.0399 17.5589H28.4601C28.6922 17.5591 28.9149 17.6514 29.079 17.8156C29.2432 17.9798 29.3355 18.2024 29.3357 18.4346V18.6946ZM31.9857 33.0804H30.85V18.4342C30.8492 17.8006 30.5971 17.1932 30.1491 16.7453C29.7011 16.2973 29.0936 16.0453 28.4601 16.0446H12.0399C11.4063 16.0453 10.7988 16.2974 10.3508 16.7454C9.90272 17.1935 9.6507 17.8009 9.65 18.4346V33.0804H8.51429V15.7494L20.2489 9.70357L31.9857 15.7494V33.0804Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class GarageIconComponent extends BaseIconComponent {}
