import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-beds-four-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.88208 6.5647V4.3147C7.88208 4.24011 7.85245 4.16857 7.7997 4.11582C7.74696 4.06308 7.67542 4.03345 7.60083 4.03345C7.52624 4.03345 7.4547 4.06308 7.40196 4.11582C7.34921 4.16857 7.31958 4.24011 7.31958 4.3147V4.8772H3.94458V4.3147C3.94458 4.24011 3.91495 4.16857 3.8622 4.11582C3.80946 4.06308 3.73792 4.03345 3.66333 4.03345C3.58874 4.03345 3.5172 4.06308 3.46446 4.11582C3.41171 4.16857 3.38208 4.24011 3.38208 4.3147V6.5647C3.2329 6.5647 3.08982 6.62396 2.98433 6.72945C2.87884 6.83494 2.81958 6.97801 2.81958 7.1272V8.8147C2.81958 8.88929 2.84921 8.96083 2.90196 9.01357C2.9547 9.06632 3.02624 9.09595 3.10083 9.09595C3.17542 9.09595 3.24696 9.06632 3.2997 9.01357C3.35245 8.96083 3.38208 8.88929 3.38208 8.8147V8.2522H7.88208V8.8147C7.88208 8.88929 7.91171 8.96083 7.96446 9.01357C8.0172 9.06632 8.08874 9.09595 8.16333 9.09595C8.23792 9.09595 8.30946 9.06632 8.3622 9.01357C8.41495 8.96083 8.44458 8.88929 8.44458 8.8147V7.1272C8.44458 6.97801 8.38532 6.83494 8.27983 6.72945C8.17434 6.62396 8.03126 6.5647 7.88208 6.5647ZM5.35083 6.5647H3.94458V6.28345C3.94458 6.20886 3.97421 6.13732 4.02696 6.08457C4.0797 6.03183 4.15124 6.0022 4.22583 6.0022H5.06958C5.14417 6.0022 5.21571 6.03183 5.26845 6.08457C5.3212 6.13732 5.35083 6.20886 5.35083 6.28345V6.5647ZM7.31958 6.5647H5.91333V6.28345C5.91333 6.20886 5.94296 6.13732 5.99571 6.08457C6.04845 6.03183 6.11999 6.0022 6.19458 6.0022H7.03833C7.11292 6.0022 7.18446 6.03183 7.2372 6.08457C7.28995 6.13732 7.31958 6.20886 7.31958 6.28345V6.5647Z"
        [attr.fill]="color"
      />
      <path
        d="M7.88208 13.3147V11.0647C7.88208 10.9901 7.85245 10.9186 7.7997 10.8658C7.74696 10.8131 7.67542 10.7834 7.60083 10.7834C7.52624 10.7834 7.4547 10.8131 7.40196 10.8658C7.34921 10.9186 7.31958 10.9901 7.31958 11.0647V11.6272H3.94458V11.0647C3.94458 10.9901 3.91495 10.9186 3.8622 10.8658C3.80946 10.8131 3.73792 10.7834 3.66333 10.7834C3.58874 10.7834 3.5172 10.8131 3.46446 10.8658C3.41171 10.9186 3.38208 10.9901 3.38208 11.0647V13.3147C3.2329 13.3147 3.08982 13.374 2.98433 13.4794C2.87884 13.5849 2.81958 13.728 2.81958 13.8772V15.5647C2.81958 15.6393 2.84921 15.7108 2.90196 15.7636C2.9547 15.8163 3.02624 15.8459 3.10083 15.8459C3.17542 15.8459 3.24696 15.8163 3.2997 15.7636C3.35245 15.7108 3.38208 15.6393 3.38208 15.5647V15.0022H7.88208V15.5647C7.88208 15.6393 7.91171 15.7108 7.96446 15.7636C8.0172 15.8163 8.08874 15.8459 8.16333 15.8459C8.23792 15.8459 8.30946 15.8163 8.3622 15.7636C8.41495 15.7108 8.44458 15.6393 8.44458 15.5647V13.8772C8.44458 13.728 8.38532 13.5849 8.27983 13.4794C8.17434 13.374 8.03126 13.3147 7.88208 13.3147ZM5.35083 13.3147H3.94458V13.0334C3.94458 12.9589 3.97421 12.8873 4.02696 12.8346C4.0797 12.7818 4.15124 12.7522 4.22583 12.7522H5.06958C5.14417 12.7522 5.21571 12.7818 5.26845 12.8346C5.3212 12.8873 5.35083 12.9589 5.35083 13.0334V13.3147ZM7.31958 13.3147H5.91333V13.0334C5.91333 12.9589 5.94296 12.8873 5.99571 12.8346C6.04845 12.7818 6.11999 12.7522 6.19458 12.7522H7.03833C7.11292 12.7522 7.18446 12.7818 7.2372 12.8346C7.28995 12.8873 7.31958 12.9589 7.31958 13.0334V13.3147Z"
        [attr.fill]="color"
      />
      <path
        d="M16.1321 6.5647V4.3147C16.1321 4.24011 16.1024 4.16857 16.0497 4.11582C15.997 4.06308 15.9254 4.03345 15.8508 4.03345C15.7762 4.03345 15.7047 4.06308 15.652 4.11582C15.5992 4.16857 15.5696 4.24011 15.5696 4.3147V4.8772H12.1946V4.3147C12.1946 4.24011 12.1649 4.16857 12.1122 4.11582C12.0595 4.06308 11.9879 4.03345 11.9133 4.03345C11.8387 4.03345 11.7672 4.06308 11.7145 4.11582C11.6617 4.16857 11.6321 4.24011 11.6321 4.3147V6.5647C11.4829 6.5647 11.3398 6.62396 11.2343 6.72945C11.1288 6.83494 11.0696 6.97801 11.0696 7.1272V8.8147C11.0696 8.88929 11.0992 8.96083 11.152 9.01357C11.2047 9.06632 11.2762 9.09595 11.3508 9.09595C11.4254 9.09595 11.497 9.06632 11.5497 9.01357C11.6024 8.96083 11.6321 8.88929 11.6321 8.8147V8.2522H16.1321V8.8147C16.1321 8.88929 16.1617 8.96083 16.2145 9.01357C16.2672 9.06632 16.3387 9.09595 16.4133 9.09595C16.4879 9.09595 16.5595 9.06632 16.6122 9.01357C16.6649 8.96083 16.6946 8.88929 16.6946 8.8147V7.1272C16.6946 6.97801 16.6353 6.83494 16.5298 6.72945C16.4243 6.62396 16.2813 6.5647 16.1321 6.5647ZM13.6008 6.5647H12.1946V6.28345C12.1946 6.20886 12.2242 6.13732 12.277 6.08457C12.3297 6.03183 12.4012 6.0022 12.4758 6.0022H13.3196C13.3942 6.0022 13.4657 6.03183 13.5185 6.08457C13.5712 6.13732 13.6008 6.20886 13.6008 6.28345V6.5647ZM15.5696 6.5647H14.1633V6.28345C14.1633 6.20886 14.193 6.13732 14.2457 6.08457C14.2985 6.03183 14.37 6.0022 14.4446 6.0022H15.2883C15.3629 6.0022 15.4345 6.03183 15.4872 6.08457C15.5399 6.13732 15.5696 6.20886 15.5696 6.28345V6.5647Z"
        [attr.fill]="color"
      />
      <path
        d="M16.1321 13.3147V11.0647C16.1321 10.9901 16.1024 10.9186 16.0497 10.8658C15.997 10.8131 15.9254 10.7834 15.8508 10.7834C15.7762 10.7834 15.7047 10.8131 15.652 10.8658C15.5992 10.9186 15.5696 10.9901 15.5696 11.0647V11.6272H12.1946V11.0647C12.1946 10.9901 12.1649 10.9186 12.1122 10.8658C12.0595 10.8131 11.9879 10.7834 11.9133 10.7834C11.8387 10.7834 11.7672 10.8131 11.7145 10.8658C11.6617 10.9186 11.6321 10.9901 11.6321 11.0647V13.3147C11.4829 13.3147 11.3398 13.374 11.2343 13.4794C11.1288 13.5849 11.0696 13.728 11.0696 13.8772V15.5647C11.0696 15.6393 11.0992 15.7108 11.152 15.7636C11.2047 15.8163 11.2762 15.8459 11.3508 15.8459C11.4254 15.8459 11.497 15.8163 11.5497 15.7636C11.6024 15.7108 11.6321 15.6393 11.6321 15.5647V15.0022H16.1321V15.5647C16.1321 15.6393 16.1617 15.7108 16.2145 15.7636C16.2672 15.8163 16.3387 15.8459 16.4133 15.8459C16.4879 15.8459 16.5595 15.8163 16.6122 15.7636C16.6649 15.7108 16.6946 15.6393 16.6946 15.5647V13.8772C16.6946 13.728 16.6353 13.5849 16.5298 13.4794C16.4243 13.374 16.2813 13.3147 16.1321 13.3147ZM13.6008 13.3147H12.1946V13.0334C12.1946 12.9589 12.2242 12.8873 12.277 12.8346C12.3297 12.7818 12.4012 12.7522 12.4758 12.7522H13.3196C13.3942 12.7522 13.4657 12.7818 13.5185 12.8346C13.5712 12.8873 13.6008 12.9589 13.6008 13.0334V13.3147ZM15.5696 13.3147H14.1633V13.0334C14.1633 12.9589 14.193 12.8873 14.2457 12.8346C14.2985 12.7818 14.37 12.7522 14.4446 12.7522H15.2883C15.3629 12.7522 15.4345 12.7818 15.4872 12.8346C15.5399 12.8873 15.5696 12.9589 15.5696 13.0334V13.3147Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class BedsFourIconComponent extends BaseIconComponent {}
