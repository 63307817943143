export enum ThirdPillarSituationEnum {
  EMPLOYEE = 'EMPLOYEE',
  INDEPENDENT = 'INDEPENDENT',
  OTHER = 'OTHER',
}

export const getThirdPillarSituationLabel = (value: ThirdPillarSituationEnum): string => {
  switch (value) {
    case ThirdPillarSituationEnum.EMPLOYEE:
      return $localize`Employé`;
    case ThirdPillarSituationEnum.INDEPENDENT:
      return $localize`Indépendant`;
    case ThirdPillarSituationEnum.OTHER:
      return $localize`Autres situations`;
    default:
      return '';
  }
};

export enum SavingReasonEnum {
  TAX_SAVING = 'TAX_SAVING',
  RETIREMENT_CALCULATION = 'RETIREMENT_CALCULATION',
  PROPERTY_PURCHASE = 'PROPERTY_PURCHASE',
  FAMILY_PROTECTION = 'FAMILY_PROTECTION',
  OTHER = 'OTHER',
}

export const getSavingReasonEnumLabel = (value: SavingReasonEnum): string => {
  switch (value) {
    case SavingReasonEnum.TAX_SAVING:
      return $localize`Économie d'impôts`;
    case SavingReasonEnum.RETIREMENT_CALCULATION:
      return $localize`Prévoyance retraite`;
    case SavingReasonEnum.PROPERTY_PURCHASE:
      return $localize`Achat immobilier`;
    case SavingReasonEnum.FAMILY_PROTECTION:
      return $localize`Protéger mes proches`;
    case SavingReasonEnum.OTHER:
      return $localize`Autre raison`;
    default:
      return '';
  }
};

export enum OwnDescriptionEnum {
  CAUTIOUS = 'CAUTIOUS',
  MODERATE = 'MODERATE',
  GAMBLER = 'GAMBLER',
  SPECULATOR = 'SPECULATOR',
  SOCIALLY_RESPONSIBLE = 'SOCIALLY_RESPONSIBLE',
}

export const getOwnDescriptionEnum = (value: OwnDescriptionEnum): string => {
  switch (value) {
    case OwnDescriptionEnum.CAUTIOUS:
      return $localize`Prudent`;
    case OwnDescriptionEnum.MODERATE:
      return $localize`Modéré`;
    case OwnDescriptionEnum.GAMBLER:
      return $localize`Joueur`;
    case OwnDescriptionEnum.SPECULATOR:
      return $localize`Spéculateur`;
    case OwnDescriptionEnum.SOCIALLY_RESPONSIBLE:
      return $localize`Socialement responsable`;
    default:
      return '';
  }
};
